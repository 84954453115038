import React, { useEffect, useState } from 'react';
import { IonHeader, IonTitle, IonIcon, IonGrid, IonRow, IonCol, IonModal, IonContent, IonRefresher, IonRefresherContent, IonAlert } from '@ionic/react';
import './MopHeaderMenusModal.scss';
import { closeOutline, warningOutline, documentTextOutline, personCircle, chevronBack } from 'ionicons/icons';
import { useSelector, useDispatch } from 'react-redux';
import '../theme/common.scss';
import * as headerFooterStore from '../store/HeaderFooterStore';
import TermsCondModal from '../modals/TermsCondModal';
import AllergenModal from '../modals/AllergenModal';
import LoginModal from '../modals/LoginModal';
import { ApplicationState } from '../store';
import { bindActionCreators } from 'redux';
import { baseUrl } from '../helpers/configHelper';
import moment from 'moment';
import * as currencyHelpers from '../helpers/currencyHelpers';
import * as ColleagueStore from '../store/ColleagueStore';

interface AppHeaderMenuProps { }

interface MopOrderStatus {
    mopOrderUid: string;
    customerOrderNumber: string;
    status: string;
    orderPlaced: string | Date;
    completed: boolean;
    orderTotal: number;
    orderStoreLocation: string;
}

const MopHeaderMenusModal: React.FC<{ isModalOpen: boolean, closeModalClick: any }> = ({ isModalOpen, closeModalClick }) => {

    const headerFooter = useSelector((state: ApplicationState) => state.headerFooter);
    const boundSetShowModal = bindActionCreators(headerFooterStore.actionCreators.setShowModal, useDispatch());
    const [orders, setOrders] = useState<MopOrderStatus[]>([]);
    const logout = bindActionCreators(ColleagueStore.actionCreators.logout, useDispatch());
    const [showLogoutAlert, setShowLogoutAlert] = useState(false);

    const getOrderStatus = (event?: CustomEvent) => {
        let test = false;
        let orderGUIDs: string[] = test ? ["049767f4-fa3c-4462-b3be-7f28a6603d24", "049767f4-fa3c-4462-b3be-7f28a6603d24", "049767f4-fa3c-4462-b3be-7f28a6603d24"] : JSON.parse(localStorage.getItem("mopOrders") || '[]');
        if (orderGUIDs.length > 0) {
            console.log("getOrderStatus called");
            fetch(`${baseUrl()}/orders/GetOrderStatus`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(orderGUIDs)
            })
                .then(res => res.json() as Promise<MopOrderStatus[]>)
                .then(res => {
                    console.log("got back orders, count: " + orders.length);
                    setOrders(res.map(order => ({ ...order, orderPlaced: moment(order.orderPlaced).format("Do MMMM YYYY") })));
                })
                .finally(() => {
                    if (event != undefined) {
                        event.detail.complete();
                    }
                })
        } else {
            if (event != undefined) {
                event.detail.complete();
            }
        }
    }

    const handleLoginModalClicked = () => {
        if (localStorage.getItem('isLoggedIn') === 'true') {
            handleLogout();
        } else {
            boundSetShowModal("LOGIN", true);
        }
    };

    const handleLogout = () => {
        setShowLogoutAlert(true);
    };

    const handleLogoutConfirmation = (confirmed: boolean) => {
        if (confirmed) {
            logout();
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('pfIdentifier');
            window.location.reload();
        }

        setShowLogoutAlert(false);
    };

    useEffect(() => {
        getOrderStatus();
    }, []);

    return (
        <>
            <IonModal isOpen={isModalOpen}
                onWillPresent={(e: any) => {
                    getOrderStatus();
                }}
                onDidDismiss={closeModalClick}
                cssClass='AppHeaderBurgerMenu'
                swipeToClose={true}
                id="MainModal">
                <IonHeader className="ion-no-border modal-header" mode="md" onClick={closeModalClick}>
                    <div className='BackArrowWrapper'>
                        <IonIcon icon={chevronBack} slot={"start"} className="BackArrow" />
                    </div>
                </IonHeader>
                <IonContent>
                    <div className="AppHeaderMenuTitle">Recent Orders</div>
                    <IonRefresher slot="fixed" onIonRefresh={getOrderStatus}>
                        <IonRefresherContent />
                    </IonRefresher>
                    <div className="YourOrdersInner">

                        <IonGrid>
                            {
                                orders.length > 0
                                    ?
                                    <>
                                        <div>
                                            {
                                                orders.map(order => {
                                                    return <IonRow key={order.mopOrderUid} className="yourOrdersItem">
                                                        <IonCol size="9" className="yourOrdersLeft">
                                                            <div className="yourOrdersItemTitle">{order.orderPlaced}</div>
                                                            <div className="yourOrdersItemDetail">{order.orderStoreLocation}</div>
                                                            {
                                                                !order.completed &&
                                                                <>
                                                                    <div className="yourOrdersItemDetail"><span style={{ color: 'var(--ion-color-primary)' }}>Status:</span> {order.status}</div>
                                                                    <div className="yourOrdersItemDetail"><span style={{ color: 'var(--ion-color-primary)' }}>Order number:</span> {order.customerOrderNumber}</div>
                                                                </>
                                                            }
                                                        </IonCol>
                                                        <IonCol size="3" className="yourOrdersRight">
                                                            <div className="yourOrdersItemTitle" style={{ color: 'var(--ion-color-primary)' }}>{currencyHelpers.formatCurrency(order.orderTotal)}</div>
                                                        </IonCol>
                                                    </IonRow>
                                                })
                                            }
                                        </div>
                                    </>
                                    :
                                    <IonRow className="yourOrdersItem">
                                        <IonCol size="12">
                                            <h4 style={{ color: 'var(--ion-color-dark)' }}>We can't find any recent orders that have been placed on this device</h4>
                                        </IonCol>
                                    </IonRow>
                            }
                        </IonGrid>

                    </div>

                    <div className="AppHeaderMenuTitle">Product Information</div>
                    <div className="AppHeaderMenuLink" onClick={() => boundSetShowModal("ALLERGEN", true)}>
                        <IonIcon icon={warningOutline} />
                        <div className="AppHeaderMenuLinkInner">Allergen Information</div>
                    </div>

                    <div className="AppHeaderMenuTitle">Legal</div>
                    <div className="AppHeaderMenuLink" onClick={() => boundSetShowModal("TC", true)}>
                        <IonIcon icon={documentTextOutline} />
                        <div className="AppHeaderMenuLinkInner">Terms & Conditions</div>
                    </div>

                    <div className="AppHeaderMenuTitle">Colleague Area Access</div>
                    <div className="AppHeaderMenuLink" onClick={handleLoginModalClicked}>
                        <IonIcon icon={personCircle} />
                        <div className="AppHeaderMenuLinkInner">
                            {localStorage.getItem('isLoggedIn') === "true" ? "Log out" : "Log in"}
                        </div>
                    </div>

                </IonContent>

                <TermsCondModal
                    isModalOpen={headerFooter?.modals.selectedModal == "TC"}
                    closeModalClick={() => { boundSetShowModal("TC", false); }} />

                <AllergenModal
                    isModalOpen={headerFooter?.modals.selectedModal == "ALLERGEN"}
                    closeModalClick={() => boundSetShowModal("ALLERGEN", false)} />

                <LoginModal
                    isModalOpen={headerFooter?.modals.selectedModal == "LOGIN"}
                    parentModalClick={closeModalClick}
                    closeModalClick={() => boundSetShowModal("LOGIN", false)} />

            </IonModal>

            <IonAlert
                isOpen={showLogoutAlert}
                header="Are you sure you want to log out of Colleague Area Access?"
                buttons={[
                    {
                        text: 'No',
                        role: 'cancel',
                        handler: () => handleLogoutConfirmation(false),
                    },
                    {
                        text: 'Yes',
                        handler: () => handleLogoutConfirmation(true),
                    },
                ]} />
        </>

    );
};

export default MopHeaderMenusModal;
