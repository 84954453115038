import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { baseUrl } from '../helpers/configHelper';
import update from 'immutability-helper';
import { Dispatch } from 'react';
import { calculateMenuItemTotalPrice } from '../helpers/menuHelpers';
import { addItemToGtagBasket, removeItemFromGtagBasket } from '../helpers/googleHelpers';
import { resetMessage, ResetMessageAction } from './ColleagueStore';
import { ILoginError, loginHasExpired } from '../helpers/apiHelpers';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export enum PaymentStatus {
    UNSET,
    REQUESTED,
    COMPLETE
}

export interface BasketState {
    isLoading: boolean;
    totalQuantity: number;
    basket: IMopBasket ;
    tmpBasket: IMopBasket;
    upsells: IMopMenuItemDetail[];
    email: string;
    orderNumber: string;
    shouldOfferUpsellToCustomer: boolean;
    continueClicked: boolean;
    tableFormErrors: string | undefined;
    tableNumber: number | undefined;
    nicknameFormErrors: string | undefined;
    nickname: string | undefined;
    paymentStatus: PaymentStatus;
    upsellDone: boolean;
    showAllergenPopup: boolean;
    toastNotificationOpen: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface UpdateBasketAction {
    type: 'BASKET_UPDATE';
    basket: IMopBasket ;
    totalQuantity: number;
}

interface UpdateBasketItemQuantity {
    type: 'UPDATE_BASKET_ITEM_QUANTITY';
    basketItemIndex: number;
    quantity: number;
    price: number;
}

interface RemovebasketItem {
    type: "REMOVE_BASKET_ITEM";
    basketItemIndex: number;
}

interface UpdateBasketDetailsAction {
    type: 'BASKET_DETAILS_UPDATE';
    email: string;
    staffCardNumber: string;
    consent: boolean;
}

interface ClearBasket {
    type: "CLEAR_BASKET";
}

interface OpenToast {
    type: "OPEN_TOAST";
}

interface CloseToast {
    type: "CLOSE_TOAST";
}

interface UpdateOrderNumber {
    type: "UPDATE_ORDER_NUMBER";
    orderNumber: string;
}

interface SetUpsells {
    type: "SET_UPSELLS";
    upsells: IMopMenuItemDetail[]
}


interface SetShouldOfferUpsellToCustomer {
    type: "SET_SHOULD_OFFER_UPSELL",
    value: boolean
}

interface SetContinueClicked {
    type: "SET_CONTINUE_CLICKED",
    value: boolean,
}

interface setTableFormErrors {
    type: "SET_TABLE_FORM_ERRORS",
    value: string | undefined
}

interface SetTableNumber {
    type: "SET_TABLE_NUMBER",
    value: number
}

interface setNicknameFormErrors {
    type: "SET_NICKNAME_FORM_ERRORS",
    value: string | undefined,
}

interface SetNickname {
    type: "SET_NICKNAME",
    value: string,
}

interface UpdateTempBasket {
    type: "UPDATE_TEMP_BASKET"
}

interface ClearBasketItems {
    type: "CLEAR_BASKET_ITEMS"
}

interface UpsellDone {
    type: "UPSELL_DONE"
}

interface ShowAllergenPopup {
    type: "SHOW_ALLERGEN_POPUP"
    value: boolean
}

interface UpdatePaymentStatus {
    type: 'UPDATE_PAYMENT_STATUS',
    value: PaymentStatus
}

interface AddCouponCodeAction {
    type: "ADD_COUPON_CODE";
    couponCode: string;
}

interface DeleteCouponCodeAction {
    type: "DELETE_COUPON_CODE";
    couponCode: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = UpdateBasketAction | UpdateBasketDetailsAction | UpdateBasketItemQuantity | RemovebasketItem |
    UpdateOrderNumber | ClearBasket | SetShouldOfferUpsellToCustomer | SetTableNumber |
    UpdateTempBasket | ClearBasketItems | UpdatePaymentStatus |
    AddCouponCodeAction | DeleteCouponCodeAction | SetUpsells | UpsellDone | OpenToast | CloseToast |
    setTableFormErrors | setNicknameFormErrors | SetNickname |
    SetContinueClicked | ResetMessageAction | ShowAllergenPopup;

const unloadedState: BasketState = {
    isLoading: false,
    upsellDone: false,
    showAllergenPopup: false,
    basket: {
        menuItems: [],
        totalPrice: 0,
        staffCardNumber: "",
        moreCardNumber: "",
        mopUpsells: [],
        mopCoupons: [] = [],
        calculateExternalDiscounts: false,
        isColleagueDiscountBasket: false,
        externalPromotionsApplied: false,
        calculateExternalDiscountsFailed: false,
        externalCalculationMessages: []
    },
    tmpBasket: {
        menuItems: [],
        totalPrice: 0,
        staffCardNumber: "",
        moreCardNumber: "",
        mopUpsells: [],
        mopCoupons: [] = [],
        calculateExternalDiscounts: false,
        isColleagueDiscountBasket: false,
        externalPromotionsApplied: false,
        calculateExternalDiscountsFailed: false,
        externalCalculationMessages: []
    },
    upsells: [],
    email: "",
    orderNumber: "",
    totalQuantity: 0,
    shouldOfferUpsellToCustomer: true,
    continueClicked: false,
    tableFormErrors: undefined,
    tableNumber: undefined,
    nicknameFormErrors: undefined,
    nickname: undefined,
    paymentStatus: PaymentStatus.UNSET,
    toastNotificationOpen: false
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {

    updateBasketItem: (): AppThunkAction<KnownAction> => (dispatch: Dispatch<any>, getState) => {
        const appState = getState();

        let currentBasketItem = appState.menuItemData?.menuItem;    //get current menu item

        console.log("Current Basket item: ", currentBasketItem, "Basket: ", appState.basket);   
        if(currentBasketItem) {

            currentBasketItem.menuItemTotalPrice = currentBasketItem.menuItemSinglePrice;   //Setting total price to single price for basket view

            let basketItemIndex = appState.basket?.basket.menuItems.findIndex(item => item.basketMenuItemId === currentBasketItem?.basketMenuItemId);   //Find index of menu item in basket
            if(basketItemIndex != undefined && basketItemIndex > -1) {

                if(appState.basket){
                    let basket: IMopBasket = { ...appState.basket.basket, menuItems: [] }   //create new basket to map updated items to

                    console.log("Menu Items: ", appState.basket.basket.menuItems);
                    if(appState.basket.basket.menuItems){
                        let basketItems: IMopMenuItemDetail[] = appState.basket.basket.menuItems.map((item, index) => { //map over basket items
                            if(index == basketItemIndex){                                                               //if we're on the basket item to update, return updated item
                                return currentBasketItem;
                            }
                            return item;
                        }) as IMopMenuItemDetail[];
                        
                        basket.menuItems = basketItems;
                        console.log(basketItems, basket);

                        dispatch(actionCreators.calculateBasket(basket));
                    }
                }
            }
        }
        
    },

    addMenuItemToBasket: (): AppThunkAction<KnownAction> => (dispatch: Dispatch<any>, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        let currentMenuItem = appState.menuItemData?.menuItem;

        let newBasket: IMopBasket = appState.basket?.basket ? appState.basket?.basket : JSON.parse(JSON.stringify(unloadedState.basket));

        if (currentMenuItem) {
            let basketId = Math.floor(Math.random() * 10000);
            let conflictIndex = appState.basket?.basket.menuItems.findIndex(item => item.basketMenuItemId === basketId);
            currentMenuItem.menuItemTotalPrice = currentMenuItem.menuItemSinglePrice; //Change total price back to single price
            currentMenuItem.basketMenuItemId = (conflictIndex && conflictIndex > -1) ? Math.floor(Math.random() * 1000) : basketId;
            newBasket.menuItems.push(currentMenuItem);
            console.log("Adding: ", currentMenuItem, "To basket");
        }

        if(currentMenuItem){
            let affiliation = `${appState.location?.selectedStore?.storeName || ""} ${appState.location?.selectedStore?.storeNumber || ""}`;
            addItemToGtagBasket(currentMenuItem, affiliation, currentMenuItem.menuItemTotalPrice);
            dispatch(actionCreators.calculateBasket(newBasket));
        }  

        dispatch({ type: "OPEN_TOAST", value: false });
        setTimeout(() => dispatch({ type: "CLOSE_TOAST", value: false }), 6000);
    },

    updateBasketDetails: (values: any, callback?: any): AppThunkAction<KnownAction> => (dispatch: Dispatch<any>) => {
        dispatch({ type: 'BASKET_DETAILS_UPDATE', email: values.email, staffCardNumber: values.staffCardNumber, consent: values.consent });
        console.log(values);
        let saveValues = values.consent;

        if(values.staffCardNumber != ""){
            dispatch(actionCreators.calculateBasket(undefined, () => {
                callback && callback();
            }));            
        }
        else{
            localStorage.removeItem("staffCardNumber");
            callback && callback();
        }

        if(saveValues){
            localStorage.setItem("consent", values.consent);
            localStorage.setItem("staffCardNumber", values.staffCardNumber);
            localStorage.setItem("email", values.email);
        }
        else{
            localStorage.removeItem("email");
            localStorage.removeItem("consent");
            localStorage.removeItem("staffCardNumber");
        }
        
        
        
    },
    getUpsells: (basket?: IMopBasket): AppThunkAction<KnownAction> => (dispatch: Dispatch<any>, getState) => {
        const appState = getState();

        let channelIdentifier = appState.location?.channelIdentifier
        let pfIdentifier = localStorage.getItem("pfIdentifier")

        if (appState && appState.basket) {
           // dispatch({ type: "SET_SHOULD_OFFER_UPSELL", value: false });

            fetch(`${baseUrl()}/basket/${channelIdentifier}/recommendations${pfIdentifier ? `?pfIdentifier=${pfIdentifier}` : ""}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(appState.basket.basket)
                })
                .then(response => response.json() as Promise<IMopBasket>)
                .then((data: IMopBasket | ILoginError) => {
                    if (loginHasExpired(data)) {
                        dispatch(resetMessage("showExpireModal"))
                    } else {
                        console.log("Recommendations", data);
                        dispatch({ type: 'SET_UPSELLS', upsells: data });
                    }
                });


        }
    },
    addCouponCode: (couponCode: string, callback?: any): AppThunkAction<KnownAction> => (dispatch: Dispatch<any>, getState) => {
        console.log("addCouponCode: " + couponCode);
        dispatch({ type: "ADD_COUPON_CODE", couponCode: couponCode });
        dispatch(actionCreators.calculateBasket(undefined, (basket: any) => {
            console.log("DONE CALCULATE BASKET");
            callback && callback(true, basket);
        }));
    },
    deleteCouponCode: (couponCode: string, callback?: any): AppThunkAction<KnownAction> => (dispatch: Dispatch<any>, getState) => {
        console.log("deleteCouponCode: " + couponCode);
        dispatch({ type: "DELETE_COUPON_CODE", couponCode: couponCode });
        dispatch(actionCreators.calculateBasket(undefined, (basket: any) => {
            console.log("DONE CALCULATE BASKET");
            callback && callback(true, basket);
        }));
    },
    updateBasketItemQuantity: (basketItemId: number, quantity: number): AppThunkAction<KnownAction> => (dispatch: Dispatch<any>, getState) => {
        
        const appState = getState();

        if(appState.basket){
            let basketItemIndex: number = appState.basket.basket.menuItems.findIndex((item: IMopMenuItemDetail) => item.basketMenuItemId === basketItemId);
            console.log("Updating basket quantity to ", quantity, "at index", basketItemIndex);

            let currentMenuItem = appState.basket.basket.menuItems[basketItemIndex];
            let affiliation = `${appState.location?.selectedStore?.storeName || ""} ${appState.location?.selectedStore?.storeNumber || ""}`;
            if(quantity > currentMenuItem.quantity){    //Increase quantity
                addItemToGtagBasket({...currentMenuItem, quantity: 1}, affiliation, currentMenuItem.menuItemSinglePrice);
            }
            else{ //Decrease
                removeItemFromGtagBasket({...currentMenuItem, quantity: 1}, affiliation);
            }

            if(quantity > 0){

                let price: number = calculateMenuItemTotalPrice(appState.basket.basket.menuItems[basketItemIndex], false);

                dispatch({ type: "UPDATE_BASKET_ITEM_QUANTITY", basketItemIndex: basketItemIndex, quantity: quantity, price: price });
            }
            else{
                dispatch({ type: "REMOVE_BASKET_ITEM", basketItemIndex: basketItemIndex });
            }
            dispatch(actionCreators.calculateBasket());     //update basket price with new quantity
        }
        
    },

    calculateBasket: (basket?: IMopBasket, callback?: any) : AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();        

        let channelIdentifier = appState.location?.channelIdentifier
        let pfIdentifier = localStorage.getItem("pfIdentifier")

        basket = basket != undefined ? basket : appState.basket?.basket;    //If parameter undefined, just update current basket.

        fetch(`${baseUrl()}/basket/${channelIdentifier}/calculatebasket${pfIdentifier ? `?pfIdentifier=${pfIdentifier}` : ""}`,
            {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(basket)
            })
            .then(response => response.json() as Promise<IMopBasket>)
            .then((data: IMopBasket | ILoginError) => {
                if (loginHasExpired(data)) {
                    dispatch(resetMessage("showExpireModal"))
                }
                else {
                    console.log("Setting basket", data);
                    let actualItems = data.menuItems.filter((x) => x.isDiscount == null || x.isDiscount == false);
                    let totalQuantity = actualItems.reduce((prev, curr) => {
                        return prev + (curr.quantity || 0);
                    }, 0);
                    dispatch({ type: 'BASKET_UPDATE', basket: data, totalQuantity: totalQuantity });
                    callback && callback(data);
                }
            });
    },

    clearBasket: () : AppThunkAction<KnownAction> => (dispatch) => {

        dispatch({ type: "CLEAR_BASKET" });
    },

    updateOrderNumber: (orderNumber: string) : AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "UPDATE_ORDER_NUMBER", orderNumber: orderNumber });
    },

    setShouldOfferUpsell: (value: boolean) : AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "SET_SHOULD_OFFER_UPSELL", value: value });
    },

    upsellDone: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "UPSELL_DONE" });
    },

    showAllergenPopup: (value: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "SHOW_ALLERGEN_POPUP", value });
    },

    setContinueClicked: (value: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "SET_CONTINUE_CLICKED", value: value });
    },

    setTableFormErrors: (value: string | undefined): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "SET_TABLE_FORM_ERRORS", value: value });
    },

    setTableNumber: (value: number): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "SET_TABLE_NUMBER", value: value});
    },

    setNicknameFormErrors: (value: string | undefined): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "SET_NICKNAME_FORM_ERRORS", value: value });
    },

    setNickname: (value: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "SET_NICKNAME", value: value });
    },

    updateTempBasket: (callback?: () => void) : AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "UPDATE_TEMP_BASKET"});
        callback && callback();
    },

    clearBasketItems: () : AppThunkAction<KnownAction> => (dispatchEvent) => {
        dispatchEvent({ type: 'CLEAR_BASKET_ITEMS' })
    },

    updatePaymentStatus: (value: PaymentStatus) : AppThunkAction<KnownAction> => (dispatchEvent) =>{
        dispatchEvent({ type: "UPDATE_PAYMENT_STATUS", value: value });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.



export const reducer: Reducer<BasketState> = (state: BasketState = JSON.parse(JSON.stringify(unloadedState)), incomingAction: Action): BasketState => {
    const action = incomingAction as KnownAction;
    
    const _unloadedState = JSON.parse(JSON.stringify(unloadedState));
    //console.log("UNLOADED STATE: ", _unloadedState.basket.menuItems);
    console.log("Action Type: ", action.type);
    switch (action.type) {
        case 'BASKET_UPDATE':
            return {
                ...state,
                totalQuantity: action.totalQuantity,
                basket: action.basket
            };
        case 'BASKET_DETAILS_UPDATE':
            return {
                ...state,
                email: action.email,
                basket: {
                    ...state.basket,
                    staffCardNumber: action.staffCardNumber,
                    calculateExternalDiscounts: true
                }
            };
        case 'REMOVE_BASKET_ITEM':
            if(state.basket.menuItems.length > 1){  //If there will still be items left
                return {
                    ...state,
                    basket: {
                        ...state.basket,
                        menuItems: [
                            ...state.basket.menuItems.slice(0, action.basketItemIndex),
                            ...state.basket.menuItems.slice(action.basketItemIndex + 1)
                        ]
                    }
                }
            }else{  //Else just use unloadedState
                console.log("Loading default basket state", _unloadedState.basket);
                return {
                    ..._unloadedState,
                    email: state.email,
                    basket: {
                        ..._unloadedState.basket,
                        staffCardNumber: state.basket.staffCardNumber,
                    }
                }
            }
            break;
        case 'UPDATE_BASKET_ITEM_QUANTITY':
            console.log("Updating quantity of ", state.basket.menuItems[action.basketItemIndex], "to ", action.quantity);
            return update(state, {
                basket: {
                    menuItems: {
                        [action.basketItemIndex]: {
                            $merge: {
                                quantity: action.quantity,
                                menuItemTotalPrice: action.price
                            }                            
                        }
                    },
                    calculateExternalDiscounts: { $set: true }
                }                
            });
        case 'CLEAR_BASKET':
            console.log("Clearing basket", {..._unloadedState});
            return {
                ..._unloadedState,
                email: state.email,
                basket: {
                    ..._unloadedState.basket,
                    staffCardNumber: state.basket.staffCardNumber,
                }
            }
        case 'UPDATE_ORDER_NUMBER':
            console.log("Updating order number");
            return {
                ...state,
                orderNumber: action.orderNumber
            }
        case 'SET_SHOULD_OFFER_UPSELL':
           // debugger;
            return {
                ...state,
                shouldOfferUpsellToCustomer: action.value
            }

        case 'UPSELL_DONE':
           // debugger;
            return {
                ...state,
                upsellDone: true,
                shouldOfferUpsellToCustomer: false
            }

        case "SHOW_ALLERGEN_POPUP":
            return {
                ...state,
                showAllergenPopup: action.value,
            }

        case 'SET_UPSELLS':
            var upsells = action.upsells;

            if (upsells.length > 0) {
                return {
                    ...state,
                    upsells: upsells,
                    shouldOfferUpsellToCustomer: true,

                }
            } else {
                return {
                    ...state
                }
            }

        case 'SET_CONTINUE_CLICKED':
            return {
                ...state,
                continueClicked: action.value,
            }

        case 'SET_TABLE_FORM_ERRORS':
            return {
                ...state,
                tableFormErrors: action.value
            }

        case 'SET_TABLE_NUMBER':
            return {
                ...state,
                tableNumber: action.value
            }

        case 'SET_NICKNAME_FORM_ERRORS':
            return {
                ...state,
                nicknameFormErrors: action.value
            }

        case 'SET_NICKNAME':
            return {
                ...state,
                nickname: action.value
            }

        case 'OPEN_TOAST':
            return {
                ...state,
                toastNotificationOpen:true
            }

        case 'CLOSE_TOAST':
            return {
                ...state,
                toastNotificationOpen: false
            }
        case 'UPDATE_TEMP_BASKET':
            return {
                ...state,
                tmpBasket: state.basket
            }
        case 'CLEAR_BASKET_ITEMS':
            return {
                ...state,
                basket: {...unloadedState.basket}
            }
        case 'UPDATE_PAYMENT_STATUS':
            return {
                ...state,
                paymentStatus: action.value
            }

        case 'ADD_COUPON_CODE':
            console.log(`ADD_COUPON_CODE: code=${action.couponCode}, pre-calculateExternalDiscounts=${state.basket.calculateExternalDiscounts}`);
            let p: IMopCoupon[] = [];
            if (state.basket.mopCoupons) {
                p = state.basket.mopCoupons;
            }
            p = [...p, { couponCode: action.couponCode, reason: "", errorCode: 0, rejected: false }];
            return {
                ...state,
                basket: {
                    ...state.basket,
                    mopCoupons: p,
                    calculateExternalDiscounts: true
                }
            }
        case 'DELETE_COUPON_CODE':
            const _coupons = state.basket.mopCoupons.filter((c: IMopCoupon) => c.couponCode != action.couponCode);
            state.basket.menuItems = state.basket.menuItems.filter((mi:IMopMenuItemDetail) => !mi.sku.includes(action.couponCode));
            const _calculateExternalDiscounts = _coupons.length > 0 || (state.basket.staffCardNumber != "" && state.basket.staffCardNumber != null);
            console.log(`DELETE_COUPON_CODE: code=${action.couponCode}, calculateExternalDiscounts=${_calculateExternalDiscounts}`);
            return {
                ...state,
                basket: {
                    ...state.basket,
                    mopCoupons: _coupons,
                    calculateExternalDiscounts: _calculateExternalDiscounts
                }
            }
        default:
            return state;
    }
    return state;
};
