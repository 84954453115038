import React, { useEffect, useState } from 'react';
import { IonHeader, IonTitle, IonToolbar, IonIcon, IonGrid, IonRow, IonCol, IonModal, IonContent } from '@ionic/react';
import './MopHeaderMenusModal.scss';
import { closeOutline } from 'ionicons/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import '../theme/common.scss';
import * as headerFooterStore from '../store/HeaderFooterStore';
import { bindActionCreators } from 'redux';
import { getConfig } from '../helpers/configHelper';


interface TermsCondModalProps {

} 

const TermsCondModal: React.FC<{ isModalOpen: boolean, closeModalClick: any }> = ({ isModalOpen, closeModalClick }) => {

    let history = useHistory();
    const boundSetShowModal = bindActionCreators(headerFooterStore.actionCreators.setShowModal, useDispatch());

    return (
        <IonModal isOpen={isModalOpen}
            onDidDismiss={closeModalClick}
            cssClass='AppHeaderBurgerMenu fix-that-header'
            
            swipeToClose={true}>
            <IonHeader className="ion-no-border modal-header" onClick={closeModalClick}>
                <IonIcon className="CloseButton" color={"primary"} icon={closeOutline} ></IonIcon>
                <IonTitle color={"primary"}>Close</IonTitle>
            </IonHeader>
            <IonContent>
                {true &&
                     <div className="TermsCondInner" style={{ paddingBottom: "100px" }}>
                     <h4> {`${getConfig().isMarket ? "Market Kitchen" : 'Caf\u00E9'}`} Click and Collect terms and conditions of sale</h4>
                     <p>Welcome to {getConfig().isMarket ? "Market Kitchen" : "the Morrisons Café"} Click and Collect terms and conditions of sale.</p>
                     <h4>What these terms cover</h4>
                     <p>These  terms  and  conditions  together  with  our <a onClick={() => { boundSetShowModal("FAQ", true, false) } }>Privacy  Policy</a>  apply  to  any  products  that  you may purchase from our website {getConfig().isMarket ? <a href="/">marketkitchen.menu</a> : <a href="/cafe">morrisons.com/cafe</a>} (the "Website").</p>
                     <p>Please read all of these terms and conditions carefully before you order any products because by ordering, you agree to be bound by them. Each time you place an order you will be asked to agree to these terms and conditions. We revise our terms and conditions from time to time (the "terms") - you and your order will be subject to the terms and conditions in force at the time that you submit your order</p>
                     <p>If you do not accept these terms and conditions, you should not order any products from us.</p>
                     <p>You should print and save a copy of these terms and conditions for your future reference.</p>
                     <ol>
                         <li>
                             <h5>About Us</h5>
                             <ol>
                                 <li><p>Our full legal name is WM Morrison Supermarkets Limited. We are a public limited company incorporated in England and Wales. Our registered company number is  358949  and  our  registered  office  address  is  Hilmore  House,  Gain  Lane, Bradford, West Yorkshire BD37DL. Our VAT number is 343 4753 55.</p></li>
                             </ol>
                         </li>
                         <li>
                             <h5>UK Consumers Only</h5>
                             <ol>
                                 <li><p>Our Website is only intended for use by people resident on mainland England, Scotland and Wales. We are unable to accept orders from people resident in any other countries.</p></li>
                                 <li><p>Our Website is also only intended for use by consumers; that means people who  want  to  buy  our  products  for  personal  use  and  not  for  any  business purposes. We reserve the right to reject or cancel your order if we reasonably think that you are buying our products for business purposes.</p></li>
                             </ol>
                         </li>
                         <li>
                             <h5>{getConfig().isMarket ? "Market Kitchen" : "Morrisons Café"} Click and Collect Service </h5>
                             <ol>
                                 <li><p>{getConfig().isMarket ? "Market Kitchen" : "The Café"} click and collect service allows customers to pre-order and pay for selected food and drink items featuring on the takeaway menu, via the Website, for same day collection from selected Morrisons storesor for customers  choosing  the  eat-in  option  to  order  items  to  be  delivered  to  your table  at  selected  {getConfig().isMarket ? "Market Kitchen" : "Morrisons"}  stores  ("eat  in")  subject  to  any  local  store restrictions.</p></li>
                                 <li><p>{getConfig().isMarket ? "Market Kitchen" : "The Café"} click and collect service will only be available in participating Morrisons stores. In order to place an order, you must access the Website and we will ask you for permission to access your data location so that we can ensure that you are provided with the details of the closest participating store. If you do not accept our request then you may manually check whether your local Morrisons store offers the {getConfig().isMarket ? "Market Kitchen" : "The Café"} click and collect service by using the city, town or postcode finder. </p></li>
                                 <li><p>You may order items from the listed {getConfig().isMarket ? "Market Kitchen" : "Café"} takeaway menu and use our Website without registering with us. You will need to enter certain details during the "checkout" process. </p></li>
                                 <li><p>In order to purchase items from our Website, you must:</p>
                                     <ul>
                                         <li><p>confirm that you are over the age of 18 and provide your name, email address and any other details that we may require to process your order;</p></li>
                                         <li><p>follow the instructions on the Website to choose the item(s) that you wish to purchase from the participating Morrisons store;</p></li>
                                         <li><p>follow the instructions on the "order" and "checkout" sections and finalise the order by making payment of the items ordered. </p></li>
                                     </ul>
                                 </li>
                                 <li><p>All items offered on our Website are subject to availability and will differ per store. We will not be liable to you if we are unable to provide any item(s) for any reason and if any item is unavailable or out of stock, you may be prevented from purchasing the item. If an order is finalised and payment is processed but the item becomes unavailable, we will notify you of this as soon as possible and issue a full refund of the unavailable item(s) to you on to the same card as used for payment.</p></li>
                                 <li><p>During the order process you will be able to view the order in your basket prior to submitting payment. You will be able to see the items that you have ordered and the total price for the order. Once an order is placed, an order confirmation will be sent to the email address provided during the checkout process. Please ensure that the email address is correct as this is necessary to complete your order. </p></li>
                                 <li><p>The order confirmation will provide an order number and confirm the payment method used to process the order. You must provide the order number when you visit the designated collection point at the participating Morrisons store to collect your order.</p></li>

                                 <li><p>Morrisons reserves the right at any time without notice or liability to: </p>
                                     <ul>
                                         <li><p>decline a new customer at any time and at our sole discretion;</p></li>
                                         <li><p>terminate your access to all or part of the Website and/or the {getConfig().isMarket ? "Market Kitchen" : "Café"} click and collect service with or without notice;</p></li>
                                         <li><p>change or withdraw the Website and/or the {getConfig().isMarket ? "Market Kitchen" : "Café"} click and collect service or any part of it at any time with or without any notice at our sole discretion;</p></li>
                                         <li><p>cancel orders due to unforeseeable circumstances or shortages;</p></li>
                                         <li><p>restrict access to the Website to allow for maintenance repairs or renewals;</p></li>
                                         <li><p>block your use of the Website and/or the {getConfig().isMarket ? "Market Kitchen" : "Café"} click and collect Service at any time if you do not comply with these terms and conditions. Your statutory rights are not affected;</p></li>
                                         <li><p>report any suspected abuse of the Website and/or the {getConfig().isMarket ? "Market Kitchen" : "Café"} click and collect service to the police and/or to instruct a debt recovery agent to seek payment from you on Morrisons behalf.</p></li>
                                     </ul>
                                 </li>
                                 <li><p>Morrisons cannot guarantee that the Website will be error free or that it will meet your requirements. The Website is provide on an 'as-is' basis however if you experience any technical issues you may report them to a member of staff at the participating store or to the Morrisons Customer Services Team.</p></li>
                             </ol>
                         </li>
                         <li>
                             <h5>Price and Payment</h5>
                             <ol>
                                 <li><p>We require immediate payment when you order from our Website and all items purchased must be paid for in full at the time that the order is placed.</p></li>
                                 <li><p>All of the prices for our items will be shown in pounds sterling and are inclusive of VAT. The price of the items are as quoted on our Website at the time that you use it and will only apply to the items listed on the Website. We accept payments by Visa, Mastercard, Apple and Google Pay.</p></li>
                                 <li><p>When you place an order, payment will be taken for an amount of money equal to the value of the order against the card that you have used for payment when you place your order with us by clicking the 'Confirm Payment' button at the end of the checkout process.</p></li>
                                 <li><p>To ensure that your credit, debit or charge card is not being used without your consent and to verify that you are over 18 years of age (see our "Age Restrictions" section below) your payment provider may carry out certain fraud checks, to validate the payment details that you have provided during the "checkout" process. </p></li>
                                 <li><p>We reserve the right to reject or cancel an order at our discretion at any time if we are notified that the fraud checks have not been satisfied in full. </p></li>
                                 <li><p>Your order, your credit, debit or charge card will be processed by a reputable third party banking and distribution institution. These organisations are under strict legal and contractual obligations to keep your personal information private and comply with the relevant Data Protection Laws. </p></li>
                                 <li><p>If connectivity is lost or any other technical issue occurs which prevents a transaction from completing, you will need to restart your order. </p></li>
                                 <li><p>Once an order is placed, you may access the details of your order by reviewing your order acknowledgment email.</p></li>
                             </ol>
                         </li>
                         <li>
                             <h5>Age Restrictions</h5>
                             <ol>
                                 <li><p>We will not sell products to people under the age of 18.</p></li>
                                 <li><p>We are an ethical retailer and are committed to upholding both our legal and social obligations as a retailer of products. To achieve this position, we have a number of control checks throughout our purchase and delivery process, for example we may ask you to confirm your date of birth when you place an order with us, verify who you say you are through personal identity checks and we may, where appropriate, take steps to ensure that we do not provide the order to somebody who is not, in our reasonable opinion, aged 18 or over.  Accordingly, upon collection, if the person collecting the order appears to be under the age of 25, we may request photo ID (such as a driving licence or passport) to prove that they are over the age of 18.</p></li>
                                 <li><p>It is your responsibility to ensure that the person collecting the order is over 18 and can demonstrate this to us if challenged. If we are not satisfied that the person collecting the order is over the age of 18, then we may not hand over your order.</p></li>
                             </ol>
                         </li>
                         <li>
                             <h5>Your Status</h5>
                             <ol>
                                 <li><p>By placing an order with us, you are confirming that:</p>
                                     <ul>
                                         <li><p>you are a consumer and are not purchasing our products for any business purposes;</p></li>
                                         <li><p>you are at least 18 years old;</p></li>
                                         <li><p>you are able to collect your order from a participating store;</p></li>
                                         <li><p>you (and the individual who will collect the item where applicable) are resident on mainland England, Scotland or Wales.</p></li>
                                     </ul>
                                 </li>
                             </ol>
                         </li>
                         <li>
                             <h5>How is the Contract Formed Between You and Us?</h5>
                             <h5>Forming the contract</h5>
                             <ol>
                                 <li>
                                     <p>The technical steps required to create a contract between you and us for the {getConfig().isMarket ? "Market Kitchen" : "Café"} click and collect service and use of the Website are as follows:</p>
                                     <ul>
                                         <li><p>You must select the items that you wish to order and place an order on the Website through the "checkout" process.</p></li>
                                         <li><p>You will be guided through the "checkout" process by a series of instructions.</p></li>
                                         <li><p>You place an order on our Website by clicking the "Confirm Payment" button at the end of the "checkout" process.</p></li>
                                         <li><p>We will send you an automated message which will be shown to you on our Website immediately after you have placed your order.  We will also send you an order confirmation email to confirm that the details of your order. This is not acceptance of your order.</p></li>
                                         <li><p>A binding contract is formed between you and us when you collect your goods from the designated collection point.</p></li>
                                     </ul>
                                 </li>
                                 <h5>Please note the following</h5>
                                 <li><p>From time to time, we may not be able to accept an order or we may cancel an order. This may be due to circumstances beyond our control and if we cannot accept your order, we will inform you of this by email as soon as we can and we will not charge you for the order.</p></li>
                                 <li><p>If we cancel an order or issue you with a refund for any reason, we shall refund the amount paid by you.</p></li>
                                 <h5>Product Information</h5>
                                 <li><p>The product information contained on our Website has been published in good faith and we will do our best to ensure that it is accurate. However, occasionally, it may be incorrect, incomplete or out of date due to human error or circumstances beyond our control. Where information is incorrect due to human error or circumstances beyond our control, we reserve the right not to accept your order for the affected products, or if your order has already been accepted, not to supply the affected products to you.  We will notify you if this is the case and will not charge you for the product.</p></li>
                                 <li><p>The images of products on our Website are for illustrative purposes only.  Although we have made every effort to display colours and appearances of products accurately, we cannot guarantee that a computer, tablet or mobile device screen will accurately reflect actual products delivered as devices may vary slightly.  If you think that the product you have received does not match its description, then you can reject it in accordance with section 'Cancellation, Refunds, and Returns Policy' below.</p></li>
                             </ol>
                         </li>
                         <li>
                             <h5>Collection of your Order</h5>
                             <ol>
                                 <li><p>Items ordered from our Website may only be collected from your selected Morrisons store and cannot be delivered to any other store or any other address. Orders cannot be added or delivered with any online grocery order that have been placed with us. </p></li>
                                 <li><p>You must not consume an item that is damaged or does not match the order that you placed on the Website. If you use such items you will be deemed to have accepted them and you may be unable return them because they are different to your order or are damaged. </p>
                                     <p><u>Same day collection orders</u></p></li>

                                 <li><p>Collection times may vary depending on the participating store and items ordered on the Website must be collected from your nominated Morrisons store at the allotted or selected collection time which will be stated on the order confirmation. </p></li>
                                 <li><p>The order will be collected from our {getConfig().isMarket ? "Market Kitchen" : "Café"} counters or at any other collection point notified to you at the store. Upon arrival at the designated collection point, you must make yourself known to a member of staff and provide the order number stated in the order confirmation email to collect your order. A Morrisons member of staff will use this order number to identify your order. </p></li>
                                 <li><p>We reserve the right to dispose of your order if it is not collected within one (1) hour of the selected or allotted collection time and you will not receive a refund or replacement items.</p>
                                     <p><u>Eat in orders</u></p></li>
                                 <li><p>For  customers  choosing  the  eat-in  option  you  must  have  a  designated  table and a table number before you place an order on the Website and before you make payment for the items ordered. A Morrisons member of staff will use the table number and order number to identify you.</p></li>
                                 <li><p>You are required to collect drinks orders from the {getConfig().isMarket ? "Market Kitchen" : "Café"} counter or at any other collection point notified to you at the store in accordance with Clause 8.4. Food orders will be brought to your table by a Morrisons member of staff.</p></li>
                                 <li><p>We reserve the right to dispose of your order if you are not at your table within a reasonable time of a Morrisons member of staff bringing your food order to your table and you will not receive a refund or replacement items.</p></li>
                             </ol>
                         </li>
                         <li>
                             <h5>More Card Points and Promotions</h5>
                             <ol>
                                 <li><p>More Card points will not apply to orders placed on the Website.</p></li>
                                 <li><p>Morrisons vouchers or coupons cannot be redeemed when placing an order on the Website. You cannot redeem any other discounts against purchases made on the Website.</p></li>
                                 <li><p>Morrisons colleague discounts will apply to orders placed on the Website. </p></li>
                             </ol>
                         </li>

                         <li>
                             <h5>Cancellation, Refunds, and Returns Policy</h5>
                             <ol>
                                 <li><p>Once payment is made, you do not have a right to cancel any order made through the Website.</p></li>
                                 <li><p>Our refund policy will not apply to items ordered from the Website and items may not be returned unless they are faulty or are of poor quality. In the event that your order is faulty or of poor quality, you should notify one of our {getConfig().isMarket ? "Market Kitchen" : "Café"} colleagues in the Morrisons participating store or by contact our Customer Service Team on 0345 611 6111 and selecting option 6 to report the issues with your order. </p></li>
                                 <li><p>On collection of your order, please check that the items collected match the order that you placed. Please refer to the "best by" or "use by" dates on the packaging and store the items as instructed on the packaging. </p></li>
                             </ol>
                         </li>
                         <li>
                             <h5>Our lIability</h5>
                             <ol>
                                 <li><p>The Website is provided without any warranties, either express or implied, including any implied warranties of satisfactory quality, or fitness for particular purpose.</p></li>
                                 <li><p>If we fail to comply with these terms and conditions, we shall only be liable to you for the purchase price of the products and other losses that you suffer as a result of our failure to comply which are a foreseeable consequence of such failure.</p></li>
                                 <li><p>Nothing in these terms and conditions excludes or limits our liability for:</p>
                                     <ul>
                                         <li><p>death or personal injury caused by our negligence;</p></li>
                                         <li><p>fraud or fraudulent misrepresentation;</p></li>
                                         <li><p>liability which may not be limited or excluded by section 31 (1) Consumer Rights Act 2015;</p></li>
                                         <li><p>defective products under the Consumer Protection Act 1987; or</p></li>
                                         <li><p>any other matter for which it would be illegal for us to exclude or attempt to exclude our liability.</p></li>

                                     </ul>
                                 </li>
                                 <li><p>Subject to the above, we will not be liable for losses suffered or costs incurred arising out of your use of, or the availability or lack of availability of the {getConfig().isMarket ? "Market Kitchen" : "Café"} click and collect service.</p></li>
                                 <li><p>We only supply the products for domestic and private use. If you use the products for any commercial, business or re-sale purpose we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.	</p></li>
                             </ol>
                         </li>
                         <li>
                             <h5>General</h5>
                             <ol>
                                 <li><p>Nothing under the contract shall give rights to any person who is not a party to it (whether under the Contracts (Rights of Third Parties) Act 1999) or otherwise.</p></li>
                                 <li><p>We may revise our terms and conditions from time to time. You will be subject to the terms and conditions in force at the time that a contract is formed between you and us (as set out above).</p></li>
                                 <li><p>You are not permitted to transfer or assign any of the rights granted under these terms and conditions. Should we decide not to pursue a breach of these conditions immediately, we may decide to do so at a later date. We are not responsible for any breach of these terms and conditions caused by unforeseen circumstances over which we have no control. Should any part of these terms and conditions be found to be invalid, this shall not affect the validity of the remaining provisions which will remain in full force. </p></li>
                                 <li><p>These terms are governed by English law and you can bring legal proceedings in respect of the Website and the services offered under these terms and conditions in the English and Welsh courts. If you live in Scotland you can bring legal proceedings in respect of the products in either the Scottish or the English courts. The European Commission provides for an online dispute resolution platform which you can access <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" target="_blank">here</a> . If you have a complaint, or would like to bring a matter to our attention please contact our Customer Services team in the first instance.</p></li>
                             </ol>
                         </li>
                         <li>
                             <h5>Customer Services</h5>
                             <ol>
                                 <li><p>If you have any questions or complaints, please contact our Customer Services team on 0345 611 6111 between the hours of 8.00am to 11.00pm every day of the week.</p>
                                     <p>Alternatively, please write to us at:</p>
                                     <p>Customer Services Department <br />
                                 Wm Morrisons Supermarkets Limited<br />
                                 Hilmore House<br />
                                 Gain Lane<br />
                                 Bradford<br />
                                 BD3 7DL</p>
                                     <p>We are always interested to hear your feedback in relation to the {getConfig().isMarket ? "Market Kitchen" : "Café"} click and collect service.</p>
                                 </li>
                             </ol>
                         </li>
                         <li>
                             <h5>Alternative Dispute Resolution </h5>
                             <ol>
                                 <li><p>Alternative dispute resolution is a process where an independent body considers the facts of a dispute and seeks to resolve it, without you having to go to court.  If you are not happy with how we have handled any complaint, you may want to contact the alternative dispute resolution provider we use.  You can submit a complaint to The Retail Ombudsman via their Website at <a href="http://www.theretailombudsman.org.uk/" target="_blank">http://www.theretailombudsman.org.uk/</a>. The Retail Ombudsman will not charge you for making a complaint and if you are not satisfied with the outcome you can still bring legal proceedings.  In addition, please note that disputes may be submitted for online resolution to the European Commission Online Dispute Resolution platform.</p></li>
                             </ol>
                         </li>
                     </ol>

                 </div>}
            
                
            </IonContent>
        </IonModal>
    );
};

export default TermsCondModal;
