import React, { useEffect, useState } from 'react';
import { ApplicationState } from "../store";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as ColleagueStore from '../store/ColleagueStore';
import * as BasketStore from '../store/BasketStore';
import { useHistory } from "react-router-dom";
import { IonModal, IonIcon, IonHeader, IonContent, IonText, IonItem, IonInput, IonRow, IonCheckbox, IonFooter, IonButton } from "@ionic/react";
import { chevronBack } from 'ionicons/icons';
import './MopHeaderMenusModal.scss';
import '../theme/common.scss';
import { InputChangeEventDetail } from '@ionic/core';

interface LoginModalProps {
    isModalOpen: boolean;
    parentModalClick: () => void;
    closeModalClick: () => void;
    error: string | null | undefined;
    isLoggedIn: boolean;
};

const LoginModal: React.FC<LoginModalProps> = ({
    isModalOpen,
    parentModalClick,
    closeModalClick,
    error,
    isLoggedIn,
}) => {
    const history = useHistory();

    const validatePin = bindActionCreators(ColleagueStore.actionCreators.validatePin, useDispatch());
    const clearBasket = bindActionCreators(BasketStore.actionCreators.clearBasketItems, useDispatch());

    const [pin, setPin] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [pinConfirmed, setPinConfirmed] = useState<boolean>(false);
    const [confirmPinErrorMessage, setConfirmPinErrorMessage] = useState<string>('');

    const handlePinChange = (event: CustomEvent<InputChangeEventDetail>) => {
        const input = event.target as HTMLInputElement;
        const inputValue = input.value;

        setPin(inputValue);
    };

    const handleCheckboxChange = (event: CustomEvent) => {
        const checkbox = event.target as HTMLIonCheckboxElement;

        setPinConfirmed(checkbox.checked);
    };

    const handleTextClick = () => {
        setPinConfirmed(!pinConfirmed);
    };

    const handleSubmit = () => {
        if (pin.length === 0) {
            setErrorMessage("Enter a valid code.");
            return;
        }

        if (pin.length < 4) {
            setErrorMessage("Please enter at least a 4 digit code.");
            return;
        }

        setErrorMessage("");

        if (!pinConfirmed) {
            setConfirmPinErrorMessage("Please confirm that you agree with the statement above.");
            return;
        }

        setConfirmPinErrorMessage("");
        validatePin(pin);
    };

    useEffect(() => {
        if (isLoggedIn && isModalOpen) {
            closeModalClick()
            parentModalClick()
            clearBasket()
            history.push('/')
        }
    }, [isLoggedIn]);

    return (
        <>
            <IonModal
                isOpen={isModalOpen}
                cssClass='AppHeaderBurgerMenu login-modal'
                swipeToClose={true}
                onDidDismiss={closeModalClick}>

                <IonHeader
                    className='ion-no-border modal-header'
                    mode='md'
                    onClick={closeModalClick}>

                    <div className='BackArrowWrapper'>
                        <IonIcon icon={chevronBack} slot={"start"} className="BackArrow" />
                    </div>
                </IonHeader>

                <IonContent>
                    <IonText>
                        <h2>Log in to Colleague Area Access</h2>
                    </IonText>

                    <IonText>
                        <h4>Colleague Area Access code*</h4>
                    </IonText>

                    <IonItem>
                        <IonInput
                            type="password"
                            inputmode="text"
                            maxlength={10}
                            minlength={4}
                            value={pin}
                            onIonChange={handlePinChange} />
                    </IonItem>

                    {(errorMessage || error) &&
                        <IonText>
                            <p className="login-modal-error">
                                {
                                    errorMessage
                                    || (!pinConfirmed && !error?.startsWith("I") && error)
                                    || (pinConfirmed && error)
                                }
                            </p>
                        </IonText>
                    }

                    <IonRow className="ion-align-items-center ion-nowrap">
                        <IonCheckbox
                            className={pinConfirmed ? 'checked-checkbox' : 'unchecked-checkbox'}
                            checked={pinConfirmed}
                            onIonChange={handleCheckboxChange} />

                        <IonText onClick={() => handleTextClick()}>
                            <p className="login-modal-confirm-message">
                                I confirm that I am a Morrisons employee and currently working a shift in store
							</p>
                        </IonText>
                    </IonRow>

                    {confirmPinErrorMessage && !pinConfirmed &&
                        <IonText>
                            <p className="login-modal-error">
                                {confirmPinErrorMessage}
                            </p>
                        </IonText>
                    }
                </IonContent>

                <IonFooter>
                    <IonButton expand="block" onClick={handleSubmit}>
                        Confirm
					</IonButton>
                </IonFooter>

            </IonModal>
        </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loading: state.colleague?.loading,
    error: state.colleague?.error,
    message: state.colleague?.message,
    isLoggedIn: state.colleague?.isLoggedIn ?? false,
});

export default connect(mapStateToProps)(LoginModal);
