import React, { useEffect, useState } from 'react';
import { IonGrid, IonFooter, IonRow, IonModal, IonCol, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon, IonText, IonImg } from '@ionic/react';
import './MopFooter.scss';
import { closeCircle } from 'ionicons/icons';
import AppHeader from './AppHeader';
import { formatCurrency } from '../helpers/currencyHelpers';
import BasketItemRow from './BasketItemRow';
import * as HeaderFooterStore from '../store/HeaderFooterStore';
import { ApplicationState } from '../store';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import * as currencyHelpers from '../helpers/currencyHelpers';

import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { simpleChannelType } from '../store/LocationStore';
import { getConfig } from '../helpers/configHelper';



const MopFooterList: React.FC = () => {

    const [showModal, setShowModal] = useState(false);
    const locationState = useSelector((state: ApplicationState) => state.location);
    const headerFooterStore = useSelector((state: ApplicationState) => state.headerFooter);
    const boundSetShowBasketModal = bindActionCreators(HeaderFooterStore.actionCreators.setBasketShowModal, useDispatch());
    const basketStore = useSelector((state: ApplicationState) => state.basket);

    let price: string = currencyHelpers.formatCurrency(basketStore?.basket.totalPrice);
    let basketEnabled = true;
    let basketCount = 0;

    const history = useHistory();
    const location = useLocation();
    if (!basketStore?.basket.totalPrice) {
        price = String.fromCharCode(163) + `0.00`;
        basketEnabled = false;
    }

    const goToBasket = () => {
        history.push(`/basketpage`);
    }

    useEffect(() => {
        if(headerFooterStore?.showBasketModal != undefined){
            setShowModal(headerFooterStore.showBasketModal);
        }        
    }, [headerFooterStore?.showBasketModal]);

    return (
        <div className="MopFooterInner">
             <IonRow className="ion-align-items-center">
                <IonCol size="4">
                    <div className="FooterStoreWrapper" onClick={() => history.push("/storesearch") }>
                        <span className="CollectFrom">{locationState?.selectedChannel === undefined ? "Store" : locationState.selectedChannel.channelType === simpleChannelType.ClickAndCollect ? "Take away" : "Eat in" }</span><br/>
                        <span className="Location">{locationState?.selectedStore === undefined ? "Select a store" : locationState?.selectedStore.storeName}</span>
                        {locationState?.selectedStore?.takingMopOrders === false ? <span style={{ color: "#ff0" }}> - Order ahead not available</span> : null}
                    </div>
                </IonCol>
                <IonCol size="8" className={`${!basketEnabled && "button-group-disabled"}`}>
                    <IonButton className="view-order" expand="full" color="light" onClick={() => { goToBasket() }} disabled={!basketEnabled}>View Order - &nbsp; {price}</IonButton>
                    <div className="nubbin">{basketStore?.totalQuantity}</div>
                </IonCol>
            </IonRow>

        </div>
    );
};

export default MopFooterList;
