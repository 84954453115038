import React, { useState } from 'react';
import { IonItem, IonLabel, IonRadio, IonGrid, IonCol, IonRow, IonIcon } from '@ionic/react';
import './LocationItem.scss';
import { warning } from 'ionicons/icons';
import { getConfig } from '../helpers/configHelper';

interface LocationItem {
	locationName: string;
	locationAddress: string;
	locationPostcode: string;
	locationDistance: string;
	locationIsMarketKitchen: boolean;
	locationIsCafe: boolean;
	locationMessage?: string;
	storeNumber: string;
	takingMopOrders: boolean;
	selectedStoreNumber: string;
	openingTimes: string;
	storeOpen: boolean;
	isFirstItem?: boolean,
	isLastItem?: boolean
	onClick?: (categoryId: number) => void;
}

const LocationItemObject: React.FC<LocationItem> = ({ storeNumber,
	locationName,
	locationAddress,
	locationPostcode,
	locationDistance,
	locationIsMarketKitchen,
	locationIsCafe,
	locationMessage,
	takingMopOrders,
	onClick,
	selectedStoreNumber,
	openingTimes,
	storeOpen,
	isFirstItem,
	isLastItem }) => {
	return (
		<IonItem lines="none" className={`location-item ${!takingMopOrders && "closed"} ${isFirstItem && "first-item"} ${isLastItem && "last-item"} ${selectedStoreNumber === storeNumber && "selected"}`}>
			<IonRadio disabled={!takingMopOrders} className="location-item-radio" value={storeNumber} />
			<IonGrid>
				<IonRow>
					<IonCol size="9">
						<IonRow>
							<IonCol size="12" className="name">{locationName}</IonCol>
							<IonCol size="12" className="address">{locationAddress}<br />{locationPostcode}</IonCol>
							<IonCol size="12" className="opening-times">{openingTimes}</IonCol> {/* MTR: Include closing time */}
							{(locationMessage !== '' && locationMessage !== undefined) && <IonCol size="12" className="LocationMessage">{locationMessage}</IonCol>}
							{!takingMopOrders && <IonCol size="12" className="closed-warning"><IonIcon color="secondary" icon={warning} class="closed-warning-icon" />This {getConfig().isMarket ? "Market Kitchen" : "café"} is not taking online orders</IonCol>}
						</IonRow>
					</IonCol>
					<IonCol size="3" className="distance">{locationDistance} mi</IonCol>
				</IonRow>
			</IonGrid>
		</IonItem>
	);
};

export default LocationItemObject;



/*


		<IonItem lines="none" className={`LocationItem ${takingMopOrders ? "" : "closed"}`}>
			<IonGrid>
				<IonRow>
					<IonCol size="8">
						<IonLabel className="LocationName">{locationName}</IonLabel>
						<div className="LocationAddress">{locationAddress}</div>

						<div className="OpeningTimes">{openingTimes}</div>
						{locationMessage !== '' &&
							<div className="LocationMessage">{locationMessage}</div>
						}
						{
							!takingMopOrders &&
							<div className="closedWarning"><IonIcon color="secondary" icon={warning} class="ClosedWarningIcon"></IonIcon>This {getConfig().isMarket ? "Market Kitchen" : "café"} is not taking online orders</div>
						}
						<div className="LocationDistance">{locationDistance} miles</div>
					</IonCol>
					<IonCol size="4">
						{locationIsMarketKitchen &&
							<img src="/images/assets/MK_logo_on_Black.svg" className="MarketKitchenLogo" />
						}
						{locationIsCafe &&
							<img src="/images/assets/cafe_logo.png" className="CafeLogo" />
						}

					</IonCol>
				</IonRow>
			</IonGrid>

			<IonRadio disabled={!takingMopOrders} name="LocationRadioGroup" className="LocationItemRadio" item-left slot="start" value={storeNumber}></IonRadio>

		</IonItem>



*/
