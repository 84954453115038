import React from 'react';
import { IonContent, IonHeader, IonButton, IonPage, IonTitle, IonToolbar, IonImg } from '@ionic/react';
import { useHistory } from "react-router-dom";
import './TCPage.scss';
import './InfoPage.scss';

const TCPage: React.FC = () => {
    
    return (
        <IonPage>

            <IonContent fullscreen>
                <div className="InfoPage">

                    

                </div>
                    
                    </IonContent>
        </IonPage>
    );
};

export default TCPage;
