import { GeolocationPosition } from '@capacitor/core/dist/esm/core-plugin-definitions';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { baseUrl } from '../helpers/configHelper';
import { ResetFirstCallAction } from './MenuStore'
import { Dispatch } from 'react';

// ----------------------------------------------------------------------------

const componentRestrictionsCountry = "uk";

const maxRequestStoresLimit = 15;

let autocompleteService:google.maps.places.AutocompleteService;
let placesService:google.maps.places.PlacesService;

type AutocompletePrediction = google.maps.places.AutocompletePrediction;

// ----------------------------------------------------------------------------

// Peterborough
const testLatitude = 52.56909370755608;
const testLongitude = -0.24083238030238144;

// ----------------------------------------------------------------------------

export enum simpleChannelType {
    ClickAndCollect = "ClickAndCollect",
    KerbsideOrder = "KerbsideOrder",
    TableOrder = "TableOrder"
}

export interface SimpleChannel {
    enabled: boolean,
    channelIdentifier:string,
    channelType: simpleChannelType;
}

export interface SimpleStore {
    auxStoreData?: AuxStoreData,
    city: string,
    firstLineAddress: string,
    enableTimeSlots: boolean;
    serverTime: Date;
    storeNumber: string,
    storeName: string,
    openingTimes: string,
    takingMopOrders: boolean,
    storeOpen: boolean,
    postcode: string,
    distance: number,
    latitude: number,
    longitude: number,
    supportedChannels: SimpleChannel[]
}

export interface AuxStoreData {
    tableNumbers?: string[]
}

// ----------------------------------------------------------------------------

export interface LocationState {
    isBusy: boolean;
    clientLatitude: number;
    clientLongitude: number;
    clientAccuracy: number;
    clientTimeStamp: number;
    isGeoLocationSupported: boolean;
    predictions: AutocompletePrediction[];
    place: google.maps.places.PlaceResult | undefined;
    placeLatitude: number;
    placeLongitude: number;
    stores: SimpleStore[];
    channelIdentifier: string | undefined;
    selectedStore: SimpleStore | undefined;
    selectedChannel: SimpleChannel | undefined;
    selectedTimeSlot: TimeSlot;
}

// ----------------------------------------------------------------------------

interface InitialiseGeoLocationAction {
    type: 'INITIALISE_GEO_LOCATION';
}

// ----------------------------------------------------------------------------

interface RequestClientGeoLocationAction {
    type: 'REQUEST_CLIENT_GEO_LOCATION';
}

interface ReceiveClientGeoLocationAction {
    type: 'RECEIVE_CLIENT_GEO_LOCATION';
    latitude: number;
    longitude: number;
    accuracy: number;
    timeStamp: number;
}

// ----------------------------------------------------------------------------

interface SelectTimeSlot {
    type: 'SELECT_TIMESLOT';
    timeSlot: TimeSlot;
}

// ----------------------------------------------------------------------------

interface SelectStoreAction {
    type: 'SELECT_STORE';
    store: SimpleStore;
    channelIdentifier: string;
}

interface ClearStoresAction {
    type: 'CLEAR_STORES';
}

interface ClearSelectedStoreAction {
    type: 'CLEAR_SELECTED_STORE';
}

// ----------------------------------------------------------------------------

interface RequestServerGeoAutoCompleteAction {
    type: 'REQUEST_SERVER_GEO_AUTOCOMPLETE';
}

interface ReceiveServerGeoAutoCompleteAction {
    type: 'RECEIVE_SERVER_GEO_AUTOCOMPLETE';
    predictions: AutocompletePrediction[];
}

// ----------------------------------------------------------------------------

interface RequestServerGeoPlaceAction {
    type: 'REQUEST_SERVER_GEO_PLACE';
}

interface ReceiveServerGeoPlaceAction {
    type: 'RECEIVE_SERVER_GEO_PLACE';
    place?: google.maps.places.PlaceResult;
    latitude: number;
    longitude: number;
}

// ----------------------------------------------------------------------------

interface RequestStoresAction {
    type: 'REQUEST_STORES';
}

interface ReceiveStoresAction {
    type: 'RECEIVE_STORES';
    stores: SimpleStore[];
}

// ----------------------------------------------------------------------------

interface RequestStoreAction {
    type: 'REQUEST_STORE';
}

interface ReceiveStoreAction {
    type: 'RECEIVE_STORE';
    store: SimpleStore | undefined;
}

// ----------------------------------------------------------------------------

interface SetChannel {
    type: "SET_CHANNEL",
    value: SimpleChannel
}

type KnownAction =
    RequestClientGeoLocationAction |
    ReceiveClientGeoLocationAction |
    InitialiseGeoLocationAction |
    RequestServerGeoAutoCompleteAction |
    ReceiveServerGeoAutoCompleteAction |
    RequestServerGeoPlaceAction |
    ReceiveServerGeoPlaceAction |
    RequestStoresAction |
    ReceiveStoresAction |
    SelectStoreAction |
    RequestStoreAction |
    ReceiveStoreAction |
    ClearStoresAction |
    ClearSelectedStoreAction | ResetFirstCallAction |
    SelectTimeSlot |
    SetChannel;

const unloadedState: LocationState = {
    isBusy: false,
    isGeoLocationSupported: false,
    clientLatitude: Number.NaN,
    clientLongitude: Number.NaN,
    clientAccuracy: Number.NaN,
    clientTimeStamp: -1,
    predictions: [],
    place: undefined,
    placeLatitude: Number.NaN,
    placeLongitude: Number.NaN,
    stores: [],
    channelIdentifier: undefined,
    selectedStore: undefined,
    selectedChannel: undefined,
    selectedTimeSlot: {label: "ASAP", startTime: "ASAP", endTime: "ASAP", startDateTime: "ASAP", endDateTime: "ASAP", takingOrders: false}
};

function ensurePlaceService() {
    if (placesService == null)
        placesService = new google.maps.places.PlacesService(document.createElement('div')); // div for whatever here...
}

function ensureAutocompleteService() {
    if (autocompleteService == null)
        autocompleteService = new google.maps.places.AutocompleteService();
}

type geoLocationCallback = (latitude: number, longitude: number, accuracy: number, isOk: boolean) => any;

export type requestServerGeoAutoCompleteCallback = () => any;
export type requestServerGeoPlaceCallback = () => any;
export type requestStoresCallback = () => any;
export type requestStoreCallback = () => any;
export type requestStoreErrorCallback = () => any;

export const actionCreators = {
    requestClientGeoLocation: (callback:geoLocationCallback): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        navigator.geolocation.getCurrentPosition((p:any) => {
            const geolocationPosition = p as GeolocationPosition;
            dispatch({
                type: 'RECEIVE_CLIENT_GEO_LOCATION',
                latitude: geolocationPosition.coords.latitude,
                longitude: geolocationPosition.coords.longitude,
                accuracy: geolocationPosition.coords.accuracy,
                timeStamp: geolocationPosition.timestamp
            });
           if(callback)
               callback(geolocationPosition.coords.latitude, geolocationPosition.coords.longitude, geolocationPosition.coords.accuracy,true);
        }, (e: any) => {
            dispatch({
                type: 'RECEIVE_CLIENT_GEO_LOCATION',
                latitude: Number.NaN,
                longitude: Number.NaN,
                accuracy: Number.NaN,
                timeStamp: -1
            });
            if (callback)
                callback(Number.NaN, Number.NaN, Number.NaN, false);
        });
        dispatch({ type: 'REQUEST_CLIENT_GEO_LOCATION' });
    },
    requestServerGeoAutoComplete: (input: string, callback:requestServerGeoAutoCompleteCallback): AppThunkAction<KnownAction> => (dispatch, getState) => {
        ensureAutocompleteService();
        autocompleteService.getPlacePredictions({ input: input, componentRestrictions: { country: componentRestrictionsCountry } }, (predictions: AutocompletePrediction[]) => {
            dispatch({ type: 'RECEIVE_SERVER_GEO_AUTOCOMPLETE', predictions: predictions });
            if (callback)
                callback();
        });
        dispatch({ type: 'REQUEST_SERVER_GEO_AUTOCOMPLETE' });
    },
    requestServerGeoPlace: (place_id: string, callback: requestServerGeoPlaceCallback): AppThunkAction<KnownAction> => (dispatch: Dispatch<any>, getState) => {
        ensurePlaceService();
        placesService.getDetails({ placeId: place_id }, (place: google.maps.places.PlaceResult, status) => {
            console.log("---Places Status---:", status);
            if(status == google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT){
                //Query limit reached, wait a second to try again.
                setTimeout(() => {
                    dispatch(actionCreators.requestServerGeoPlace(place_id, callback));
                }, 1000);                
            }   
            else{
                if (place?.geometry?.location) {
                    const noidea: any = JSON.parse(JSON.stringify(place.geometry?.location)); // bad typing from the package?
                    dispatch({ type: 'RECEIVE_SERVER_GEO_PLACE', place: place, latitude: Number(noidea.lat), longitude: Number(noidea.lng) });
                }
                else {
                    dispatch({ type: 'RECEIVE_SERVER_GEO_PLACE', place: undefined, latitude: Number.NaN, longitude: Number.NaN });
                }
                if (callback)
                    callback();
            }
        });
        dispatch({ type: 'REQUEST_SERVER_GEO_PLACE' });
    },
    requestStores: (latitude: number, longitude: number, radius: number, callback: requestStoresCallback): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const pfIdentifier = localStorage.getItem('pfIdentifier');
        let url = `${baseUrl()}/stores/getstoresatlocation?latitude=${latitude}&longitude=${longitude}&limit=${maxRequestStoresLimit}${pfIdentifier ? "&pfIdentifier=" + pfIdentifier : ""}`;
        if (Number.isNaN(radius) == false)
            url += `&radius=${radius}`;
        fetch(url)
            .then(response => response.json() as Promise<SimpleStore[]>)
            .then(data => {
                
                //let itabIndex = data.findIndex(loc => loc.storeNumber == "9994"); // Test unavailable time slots
                //data[itabIndex].enableTimeSlots = false;
                console.log(data);
                dispatch({ type: 'RECEIVE_STORES', stores: data });
                if (callback)
                    callback();
            }).catch(error => { console.error(error); })
        dispatch({ type: 'REQUEST_STORES' });
    },   
    selectStore: (selectedStore: SimpleStore, selectedChannelIdentifier: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SELECT_STORE', store: selectedStore, channelIdentifier: selectedChannelIdentifier });
        dispatch({ type: 'RESET_FIRST_CALL' });
    },
    clearStores: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLEAR_STORES'});
    },
    clearSelectedStore: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLEAR_SELECTED_STORE' });
    },
    requestStore: (storeReference: string, callback: requestStoreCallback, errorCallback:requestStoreErrorCallback):AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`${baseUrl()}/stores/getstore?storeNumber=${storeReference}`)
            .then(response => response.json() as Promise<SimpleStore>)
            .then(data => {
                dispatch({ type: 'RECEIVE_STORE', store:data});
                if (callback)
                    callback();
            }).catch(error => {
                console.error("requestStore.error: " + error);
                dispatch({ type: 'RECEIVE_STORE', store: undefined });
                if (errorCallback)
                    errorCallback();
            })
        dispatch({ type: 'REQUEST_STORE'});
    },
    selectTimeslot: (timeslot: TimeSlot, callback?: any) : AppThunkAction<KnownAction> => (dispatch) => {
        //console.debug("selectTimeslot: " + JSON.stringify(timeslot));
        dispatch({ type: 'SELECT_TIMESLOT', timeSlot: timeslot});
        callback && callback();
    },
    setChannel: (value: SimpleChannel): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "SET_CHANNEL", value: value})
    }
};

export const reducer: Reducer<LocationState> = (state: LocationState | undefined, incomingAction: Action): LocationState => {
    if (state === undefined) 
        return unloadedState;
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'INITIALISE_GEO_LOCATION':
            return {
                ...state,
                isGeoLocationSupported: navigator.geolocation != null
            };
        case 'REQUEST_SERVER_GEO_PLACE':
            return {
                ...state,
                isBusy:true
            };
        case 'RECEIVE_SERVER_GEO_PLACE':
            return {
                ...state,
                isBusy: false,
                place: action.place,
                placeLatitude: action.latitude,
                placeLongitude: action.longitude
            };
        case 'REQUEST_SERVER_GEO_AUTOCOMPLETE':
            return {
                ...state,
                isBusy: true
            };
        case 'RECEIVE_SERVER_GEO_AUTOCOMPLETE':
            return {
                ...state,
                isBusy: false,
                predictions: action.predictions
            };
        case 'REQUEST_STORES':
            return {
                ...state,
                isBusy: true
            };
        case 'RECEIVE_STORES':
            return {
                ...state,
                isBusy: false,
                stores: action.stores
            };
        case 'REQUEST_CLIENT_GEO_LOCATION': {
            return {
                ...state,
                isBusy: true,
            };
        };
        case 'RECEIVE_CLIENT_GEO_LOCATION': {
            return {
                ...state,
                isBusy: false,
                clientLatitude: action.latitude,
                clientLongitude: action.longitude,
                clientAccuracy: action.accuracy,
                clientTimeStamp: action.timeStamp
            };
        }
        case 'SELECT_STORE': {
            return {
                ...state,
                selectedStore: action.store,
                channelIdentifier:action.channelIdentifier
            };
        };
        case 'REQUEST_STORE': {
            return {
                ...state,
                isBusy: true,
                stores:[]
            };
        };
        case 'RECEIVE_STORE': {
            return {
                ...state,
                isBusy: false,
                stores: action.store == undefined ? [] : [action.store]
            };
        };
        case 'CLEAR_STORES': {
            return {
                ...state,
                stores: [],
            };
        }; case 'CLEAR_SELECTED_STORE': {
            return {
                ...state,
                selectedStore: undefined
            };
        }; case 'SELECT_TIMESLOT': {
            return {
                ...state,
                selectedTimeSlot: action.timeSlot
            };
        }; case 'SET_CHANNEL':
            return {
                ...state,
                selectedChannel: action.value,
                channelIdentifier: action.value.channelIdentifier
            }
        default:
            return state;
    }
};