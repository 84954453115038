import React, { useState, useEffect } from 'react';
import { IonGrid, IonFooter, IonRow, IonModal, IonCol, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon, IonText, IonImg } from '@ionic/react';
import './MopFooterPaymentSuccessPage.scss';

import { baseUrl } from '../helpers/configHelper';

const MopFooterPaymentSuccessPage: React.FC = ({ }) => {
	return (
		<div className="MopFooterInner PaymentSuccessPage">
			<IonRow className="ion-align-items-center">
				<IonCol size="12">
					<IonButton expand="full" color="light" onClick={() => { window.location.href = baseUrl() + "/splash"; }}>Start another order</IonButton>
				</IonCol>
			</IonRow>
		</div>
	);
};

export default MopFooterPaymentSuccessPage;