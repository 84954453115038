import React, { useState, useEffect } from 'react';
import { IonGrid, IonFooter, IonRow, IonModal, IonCol, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon, IonText, IonImg } from '@ionic/react';
import './MopFooterMenuItem.scss';
import { closeCircle } from 'ionicons/icons';
import AppHeader from '../components/AppHeader';
import { formatCurrency } from '../helpers/currencyHelpers';
import BasketItemRow from '../components/BasketItemRow';
import * as headerFooterStore from '../store/HeaderFooterStore';
import { ApplicationState } from '../store';
import * as currencyHelpers from '../helpers/currencyHelpers';
import { useHistory, useLocation } from "react-router-dom";
import QuantityInput from './QuantityInput';

import { useSelector, useDispatch } from 'react-redux';
import * as basketStore from '../store/BasketStore';
import * as MenuItemStore from '../store/MenuItemStore';
import { bindActionCreators } from 'redux';

import { simpleChannelType } from '../store/LocationStore';


const MopFooterMenuItem: React.FC = ({}) => {

    const location = useLocation();
    const locationState = useSelector((state: ApplicationState) => state.location);
    const menuItemData = useSelector((state: ApplicationState) => state.menuItemData);
    const boundAddToBasket = bindActionCreators(basketStore.actionCreators.addMenuItemToBasket, useDispatch());
    const boundUpdateBasket = bindActionCreators(basketStore.actionCreators.updateBasketItem, useDispatch());
    const boundUpdateMenuItemQuantity = bindActionCreators(MenuItemStore.actionCreators.updateMenuItemQuantity, useDispatch());

    let history = useHistory();
    //let price: string = currencyHelpers.formatCurrency(menuItemData?.itemPrice);
    const [price, setPrice] = useState("");
    const [enableButton, setEnableButton] = useState(false);

    useEffect(() => {
        //console.log("MenuItem Modifiers updated state to: ", menuItemData?.menuItem?.modifierGroups);
        setPrice(currencyHelpers.formatCurrency(menuItemData?.menuItem?.menuItemTotalPrice));
    }, [menuItemData?.menuItem?.menuItemTotalPrice]);

    useEffect(() => {
        let canAddOrUpdate = menuItemData?.menuItem?.modifierGroups.every((mod: IMopModifierGroup) => mod.groupType == 10 ? mod.modifierGroupItems.some((item: IMopModifierGroupItem) =>  item.quantitySelected > 0) : true) ?? false;  
        let hasPriceSet = (menuItemData?.menuItem?.menuItemTotalPrice ?? 0) > 0;
        setEnableButton(canAddOrUpdate && hasPriceSet);
        //setEnableButton(canAddOrUpdate ?? false);
    }, [menuItemData?.menuItem?.modifierGroups]);
    
    return (
        <div className="MopFooterInner">
            { menuItemData?.menuItem && 
                <IonRow className="ion-align-items-center">
                    <IonCol size="4" className="FooterStoreWrapper">
                        <span className="CollectFrom">{locationState?.selectedChannel === undefined ? "Store" : locationState.selectedChannel.channelType === simpleChannelType.ClickAndCollect ? "Take away" : "Eat in" }</span><br/>
                        <span className="Location">{locationState?.selectedStore === undefined ? "Select a store" : locationState?.selectedStore.storeName}</span>
                        {locationState?.selectedStore?.takingMopOrders === false ? <span style={{ color: "#ff0" }}> - Order ahead not available</span> : null}
                    </IonCol>
                    <IonCol size="8">
                        <IonButton disabled={!enableButton} expand="block" size="small" color="secondary" 
                            onClick={() => { if(!menuItemData?.isBasket){ boundAddToBasket();} else { boundUpdateBasket(); } history.goBack() }}>
                            {menuItemData?.isBasket ? "Update Item" : "Add to Order"} - <span>&nbsp;{price}</span> 
                        </IonButton>
                    </IonCol>
                </IonRow>
            }
        </div>
    );
};

export default MopFooterMenuItem;