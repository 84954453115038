import React, { useState } from 'react';
import { IonRow, IonCol,  IonText, IonImg,IonTitle } from '@ionic/react';
import './MenuItemRow.scss';
import { formatCurrency } from '../helpers/currencyHelpers';
import { baseUrl, cacheBust, getConfig } from '../helpers/configHelper';
import { MenuDisplayType } from '../store/MenuStoreTypes';

interface MenuItemRowProps {
    menuItemId: number;
    menuItemImage: string;
    menuItemName: string;
    sku: string;
    menuItemBasePrice: number | null;
    menuItemFromPrice: number | null; 
    itemTags: string;
    inStock: boolean;
    onClick?: (menuItemId: number) => void;
}

const MenuItemRow: React.FC<MenuItemRowProps> = ({ menuItemId,menuItemImage,menuItemName,menuItemBasePrice,onClick,sku,itemTags,inStock, menuItemFromPrice}) => {
    return (
        <>
            <div className={"MenuItemRowOuter " + (!inStock ? "OutOfStock" : "")}>
                <IonRow key={menuItemId} className="menuItemRow" onClick={() => inStock  && onClick && onClick(menuItemId)}>
                    {false && <IonCol size="2" className="MenuItemImageWrapper" style={{ backgroundImage: getConfig().isMarket ? "url(" + `${baseUrl()}/images/assets/Logo_MK_Default.png` + ")" : "url(" + `${baseUrl()}/images/assets/Logo_Cafe_Default.png` + ")" }}><IonImg onIonError={(e) => { console.log(e) } } className="MenuItemImage" src={(!menuItemImage || menuItemImage == 'null' ? `${baseUrl()}/images/ProductShots/null.jpg` : `${baseUrl()}/images/ProductShots/` + menuItemImage + '.jpg') + `?v=${cacheBust()}`} /></IonCol>}
                    <IonCol size="2" className="MenuItemImageWrapper" ><IonImg onIonError={(e) => { console.log(e) } } className="MenuItemImage" src={(`${baseUrl()}/images/ProductShots/${sku}_PLP.jpg`) + `?v=${cacheBust()}`} /></IonCol>

                    <IonCol size="10" className="MenuItemTitleWrapper">
                        <IonText className="MenuItemTitle" >
                            {itemTags?.includes("[VG]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/vegan-icon.png`} alt="Vegan" />}
                            {itemTags?.includes("[VE]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/v-icon.png`} alt="Vegetarian" />}
                            {itemTags?.includes("[U400]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/under400.png`} alt="Under 400 calories" />}
                            {itemTags?.includes("[U600]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/under600.png`} alt="Under 600 calories" />}
                            {itemTags?.includes("[MMI]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/MorrisonsMakesIt.png`} alt="Morrisons makes it" />}
                            {itemTags?.includes("[MF]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/icon_Meat%20Free.png`} alt="Meat free" />}
                            {itemTags?.includes("[NEW]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/new-icon.png`} alt="New Icon" />}
                            <span dangerouslySetInnerHTML={{ __html: menuItemName ? menuItemName : '' }}></span>
                        </IonText><br />
                        <IonText className="CatDescription"></IonText>
                            <IonText className="MenuItemPrice">
                                {
                                    menuItemFromPrice 
                                    ?
                                    <>
                                        {"From "}{formatCurrency(menuItemFromPrice)}
                                    </>
                                    :
                                        formatCurrency(menuItemBasePrice)
                                    }
                                
                            </IonText><div className="OutOfStockWarning">Out of stock</div>
                    </IonCol>
                
                </IonRow>
            </div>
        </>
  );
};

export default MenuItemRow;
