import React, { useEffect, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonContent, IonText,IonHeader, IonButton, IonFooter, IonPage, IonTitle, IonToolbar, IonInput, IonItem, IonLabel, IonList, IonItemDivider, IonThumbnail, IonImg } from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import './PaymentSuccess.scss';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../store';
import { formatOrderNumber } from '../helpers/checkoutHelpers';
import * as headerFooterStore from '../store/HeaderFooterStore';
import * as basketStore from '../store/BasketStore';
import * as locationStore from '../store/LocationStore';
import { bindActionCreators } from 'redux';
import { getConfig, baseUrl } from '../helpers/configHelper';
import { GetRewardDetails, IGetRewardDetailsResponse } from '../helpers/checkoutHelper';


interface ICounterItems {
	number: number;
	menuItems: IMopMenuItemDetail[];
}

const PaymentSuccess: React.FC = () => {
	let history = useHistory();
	const orderNumber = useSelector((state: ApplicationState) => state.basket?.orderNumber);
	const basketState = useSelector((state: ApplicationState) => state.basket);
	const locationState = useSelector((state: ApplicationState) => state.location);
	const boundSetShowModal = bindActionCreators(headerFooterStore.actionCreators.setShowModal, useDispatch());

	const boundClearBasket = bindActionCreators(basketStore.actionCreators.clearBasket, useDispatch());
	const boundClearLocation = bindActionCreators(locationStore.actionCreators.clearSelectedStore, useDispatch());

	const resetState = () => {
		boundClearLocation();
		boundClearBasket();
	}

	const [counterItems, setCounterItems] = useState<ICounterItems>({ number: 0, menuItems: [] });
	const [isKitchenItemPresent, setIsKitchenItemPresent] = useState(false);
	const [rewardDetails, setRewardDetails] = useState<IGetRewardDetailsResponse>();

	useEffect(() => {
		GetRewardDetails(basketState?.tmpBasket?.sessionId).then((data) => {
			setRewardDetails(data);
		});
	}, [basketState?.tmpBasket?.sessionId]);

	useEffect(() => {
		if (basketState?.tmpBasket) {
			let count = basketState.tmpBasket.menuItems.reduce((acc, cur) => !cur.isKitchenItem ? acc + cur.quantity : acc, 0);
			let _menuItems = basketState.tmpBasket.menuItems.filter((m) => !m.isKitchenItem);

			setCounterItems({
				number: count,
				menuItems: _menuItems
			});

			let _isKitchenItemsPresent = basketState.tmpBasket.menuItems.some((item) => item.isKitchenItem == true);
			setIsKitchenItemPresent(_isKitchenItemsPresent);
		}
		console.log(orderNumber);
	}, [basketState?.tmpBasket]);

	return (
		<IonPage>
			<IonContent fullscreen>
				<div className="paymentSuccessWrapper">
					<div className="paymentSuccessOrderInfoWrapper">
						<div className="paymentSuccessOrderInfo">
							<IonImg className="paymentSuccessHeaderImage" src="/images/assets/green_tick.png" />
							<div className="paymentSuccessTitle">We've got your order!</div>
							<div className="paymentSuccessSubtitle">Your order reference</div>
							<div className="paymentSuccessOrderData" dangerouslySetInnerHTML={{ __html: orderNumber as string }}></div>
							{
								locationState?.selectedChannel?.channelType == locationStore.simpleChannelType.TableOrder
									?
									<>
										{
											basketState?.tableNumber &&
											<>
												<div className="paymentSuccessTitleSmall">We'll bring your order to:</div>
												<div className="paymentSuccessOrderData" dangerouslySetInnerHTML={{ __html: 'Table M' + basketState.tableNumber.toString() }}></div>
											</>
										}
									</>
									:
									<>
										{
											locationState?.selectedTimeSlot.label == "ASAP" ?
												<>
													<div className="paymentSuccessTitleSmall">Your order is being prepared and will be ready for you to collect shortly</div>
												</>
												:
												<>
													{
														locationState?.selectedTimeSlot.startTime &&
														<>
															<div className="paymentSuccessTitleSmall">Your order is being prepared for</div>
															<div className="paymentSuccessOrderData" dangerouslySetInnerHTML={{ __html: locationState.selectedTimeSlot.startTime }}></div>
														</>
													}
												</>
										}
									</>
							}
						</div>
					</div>

					{
						rewardDetails?.showMorePoints &&
						<div className="rewardDetails">
							<img src={`${baseUrl()}/images/assets/more-card.png`} alt="More Points Image" />
							<IonText className="ion-text">{rewardDetails.morePointsRewarded} More points earned this shop</IonText>
						</div>
					}
					{
						locationState?.selectedChannel?.channelType == locationStore.simpleChannelType.TableOrder
							?
							<>
								<div className="paymentSuccessTitleSmall">
									We'll deliver your order as soon as it's ready.<br />
									A receipt has been sent to your email.
								</div>
							</>
							:
							<>
								{
									locationState?.selectedTimeSlot.label == "ASAP" ?
										<>
											<div className="paymentSuccessTitleSmall">
												Please head over to the collection point in store.<br />
												A receipt has been sent to your email.
											</div>
										</>
										:
										<>
											<div className="paymentSuccessTitleSmall">
												We'll let you know when it's ready to collect.<br />
												A receipt has been sent to your email.
											</div>
										</>
								}
							</>
					}

					<div className="paymentSuccessLink" onClick={() => boundSetShowModal("MAIN", true, true)}>View my order</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default PaymentSuccess;
