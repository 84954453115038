import React, { useEffect, useState } from 'react';
import { IonRow, IonCol, IonText, IonAlert, IonGrid,IonButton } from '@ionic/react';
import './BasketItemRow.scss';
import { formatCurrency } from '../helpers/currencyHelpers';
import { getConfig } from '../helpers/configHelper';
import * as MenuItemDataStore from '../store/MenuItemStore';
import * as BasketStore from '../store/BasketStore';
import { useHistory } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import AltQuantityInput from './AltQuantityInput';

interface BasketItemRowProps {
    basketItem: IMopMenuItemDetail;
    readOnly: boolean;
    //onClick?: (basketItemId: number) => void;
}

type BasketItemRowMergeProps =
    typeof MenuItemDataStore.actionCreators
    & BasketItemRowProps;

const BasketItemRow: React.FC<BasketItemRowProps> = ({ basketItem, readOnly }) => {

    let history = useHistory();
    const boundRequestBasketItem = bindActionCreators(MenuItemDataStore.actionCreators.requestBasketMenuItem, useDispatch());
    const boundUpdateBasketItemQuantity = bindActionCreators(BasketStore.actionCreators.updateBasketItemQuantity, useDispatch());
    const [removeAlertIsOpen, setRemoveAlertIsOpen] = useState(false);
    const [basePrice, setBasePrice] = useState(basketItem.menuItemTotalPrice);

    // put in back pocket
    const isCoupon = basketItem.sku.split('_').length == 2 && basketItem.isDiscount;

    const getCouponCode = () => {
        return basketItem.sku.split("_")[1];
    }

    useEffect(() => {
        setBasePrice(basketItem.menuItemTotalPrice);
    }, [basketItem.menuItemTotalPrice])

    return (
        <div className="BasketItemRowOuter Promotion">
            <IonAlert
                isOpen={removeAlertIsOpen}
                onDidDismiss={() => { setRemoveAlertIsOpen(false) }}
                header={"Remove Product"}
                buttons={[
                    {
                        text: "No",
                        role: "cancel"
                    },
                    {
                        text: "Yes, remove it",
                        handler: () => {
                            boundUpdateBasketItemQuantity(basketItem.basketMenuItemId, 0);
                        }
                    }
                ]}
                message={"Are you sure you want to remove this product from your basket?"}
            />
            {basketItem &&
                <IonRow key={basketItem.menuItemId} className="basketItemRow" >
                    <IonCol className="ion-no-padding"> 
                        <IonGrid>
                            <IonRow className="basket-item-title-and-price-row">
                                <IonCol size="8">
                                    <IonText className="BasketItemTitle">{isCoupon && <span>{getCouponCode()} - </span>}<span dangerouslySetInnerHTML={{ __html: basketItem.menuItemName ? basketItem.menuItemName : '' }}></span></IonText>
                                </IonCol>
                                <IonCol size="4" className="ion-text-end">
                                    <IonText className="BasketItemPrice">{`${formatCurrency(basePrice * basketItem.quantity)}${basketItem.quantity > 1 ? ` (${formatCurrency(basePrice)} each)` : ""}`}</IonText>
                                </IonCol>
                            </IonRow>
                            <IonRow className="basket-item-description-row">
                                <IonCol size="8"> 
                                    <ul className="BasketItemDescription">
                                        <>
                                            {
                                                basketItem.optionsText && basketItem.optionsText.replace(/\./g, ',').split(/[,]/).map((el, i, { length }) => {
                                                    if (length - 1 === i) {
                                                        return
                                                    }
                                                    return <li key={i} className="text-xl">{el.trim()}</li>
                                                })
                                            }
                                        </>
                                    </ul>
                                </IonCol>
                            </IonRow>
                            <IonRow className="basket-item-edit-and-quantity-row">
                                <IonCol size="6">
                                    {(!readOnly && basketItem.editable) && <div className="basketItemEditButton" onClick={() => {
                                    boundRequestBasketItem(basketItem.basketMenuItemId, () => {
                                        history.push(`/menuitem/${basketItem.menuItemId}`);
                                    })
                                    }}>Edit</div>}
                                </IonCol>
                                <IonCol size="6" className="basket-item-quantity-col">

                                    {(!readOnly && !basketItem.isDiscount) && <AltQuantityInput
                                        value={basketItem.quantity}
                                        subtractButtonColour={getConfig().isMarket ? 'lightgreen' : 'grey'}
                                        addButtonColour={getConfig().isMarket ? 'lightgreen' : 'grey'}
                                        onChange={(quantity) => {

                                            let proceed = true;
                                            if (quantity < 1) { 
                                                proceed = false;
                                                setRemoveAlertIsOpen(true);
                                            }
                                            if (proceed) {
                                                boundUpdateBasketItemQuantity(basketItem.basketMenuItemId, quantity);
                                            }
                                        }}
                                    />}

                                </IonCol>
                            </IonRow>  
                        </IonGrid>
                    </IonCol>
                </IonRow>
            }
        </div>
    );
};

export default BasketItemRow;

/*

<IonCol size="4">
    <IonRow>
        <IonCol size="4">
            <div className="ProductOptionButton">-</div>

        </IonCol>
        <IonCol size="4">
            <div className="ProductOptionCount"><div className="ProductOptionNumber">5</div></div>
        </IonCol>
        <IonCol size="4">
            <div className="ProductOptionButton">+</div>
        </IonCol>
    </IonRow>
</IonCol>
*/