import React, { useState } from 'react';
import { IonRow, IonCol, IonButton } from '@ionic/react';
import './MopFooterBasketPage.scss';
import { ApplicationState } from '../store';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { simpleChannelType } from '../store/LocationStore';
import AllergenPopup from '../modals/AllergenPopup';

const MopFooterBasketPage: React.FC = () => {
    const history = useHistory();

    const locationState = useSelector((state: ApplicationState) => state.location);
    const menuItemData = useSelector((state: ApplicationState) => state.menuItemData);

    const upsells = useSelector((state: ApplicationState) => state.basket?.upsells);
    const upsellDone = useSelector((state: ApplicationState) => state.basket?.upsellDone);

    const isLoggedIn = localStorage.getItem('isLoggedIn') === "true";

    const [showAllergenPopup, setShowAllergenPopup] = useState(false);

    function showUpsellPage() {
        return upsells && upsells.length > 0 && !upsellDone
    }

    function handleCheckout() {
        if (showUpsellPage()) {
            history.push("/temptyou");
        } else {
            setShowAllergenPopup(true);
        }
    }

    function handleCloseAllergen() {
        switch (locationState?.selectedChannel?.channelType) {
            case simpleChannelType.ClickAndCollect:
                if (isLoggedIn) {
                    history.push("/name-or-nickname");
                } else {
                    history.push("/enteremail");
                }
                break;
            case simpleChannelType.TableOrder:
                if (isLoggedIn) {
                    history.push("/name-or-nickname");
                } else {
                    history.push("/tablenumber");
                }
                break;
        }
    }

    return (
        <div className="MopFooterInner">
            { menuItemData?.menuItem &&
                <IonRow className="ion-align-items-center">
                    <IonCol size="6" className="FooterStoreWrapper">
                        <span className="CollectFrom">{locationState?.selectedChannel === undefined ? "Store" : locationState.selectedChannel.channelType === simpleChannelType.ClickAndCollect ? "Take away" : "Eat in" }</span><br/>
                        <span className="Location">{locationState?.selectedStore === undefined ? "Select a store" : locationState?.selectedStore.storeName}</span>
                        {locationState?.selectedStore?.takingMopOrders === false ? <span style={{ color: "#ff0" }}> - Order ahead not available</span> : null}
                    </IonCol>
                    <IonCol size="6">
                        <IonButton expand="block" size="small" color="secondary" onClick={handleCheckout}>Checkout</IonButton>
                    </IonCol>
                </IonRow>
            }
            <AllergenPopup
                show={showAllergenPopup}
                close={() => setShowAllergenPopup(false)}
                accept={handleCloseAllergen}
            />
        </div>
    );
};

export default MopFooterBasketPage;
