import React, { useState } from 'react';
import { IonGrid, IonRow, IonCol, IonButton, IonInput, IonIcon } from '@ionic/react';
import * as BasketStore from '../store/BasketStore';
import './MopCoupons.scss';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { SlideDown } from 'react-slidedown'
import { closeCircle, chevronForward, menu } from 'ionicons/icons'
import GenericInput from '../inputs/GenericInput';
import 'react-slidedown/lib/slidedown.css'

interface KeyedErrorMappings {
    [index: number]: string;
}

const couponErrorMappings: KeyedErrorMappings = {
    1: "This coupon has expired.",
    2: "The qualifying spend for this coupon has not been met.",
    3: "Item required for coupon is not in basket.",
    4: "Not enough qualifying products in basket. Coupon not valid.",
    5: "Invalid Coupon.",
    6: "This coupon has already been used and is no longer valid.",
    7: "This coupon is worth more than the total spend.",
    8: "This coupon cannot be used on this till type.",
    9: "This coupon has already been used in this transaction.",
    10: "The customer is not eligible for this coupon.",
    11: "Please use More Card card.",
    12: "Service is unavailable, coupon cannot be redeemed.",
    989: "Digital money off coupons cannot be redeemed at this time.",
    20: "Colleage Discount limit reached.",
    21: "No items qualify for Colleague Discount.",
    50: "We are really sorry some promotions and discounts are unavailable at this time.",
    1001: "Your coupon is not supported here. Place your order at the till."
};

interface MopCouponsProps {
    mopCoupons: any[];
    menuItems: IMopMenuItemDetail[];
}

type MopCouponsPropsMergeProps =
    typeof BasketStore.actionCreators
    & MopCouponsProps;

const MopCoupons: React.FC<MopCouponsPropsMergeProps> = (props: MopCouponsPropsMergeProps) => {

    //const [CanAddCouponCode, SetCanAddCouponCode] = useState(false);
    const [CouponCode, SetCouponCode] = useState("");
    const [showCoupons, setShowCoupons] = useState(false);
    const [Error, SetError] = useState("");
    const [IsBusy, SetIsBusy] = useState(false);

    const getCouponDescription = (couponCode: string) => {
        let couponMenuItem = props.menuItems.filter((c: IMopMenuItemDetail) => c.sku.includes(couponCode));
        return couponMenuItem.length == 1 ? "<span>" + couponCode + "</span> - " + couponMenuItem[0].menuItemName : "<span>" + couponCode + "</span>";
    }

    return (<div className="MopCouponsWrapper">

        <div className={`cardInputWrapper ${showCoupons || props.mopCoupons?.length > 0 ? "isOpen" : "isClosed"}`}>
            <div className="cardInputToggle" style={{ 'backgroundColor': 'var(--ion-color-verylightgreen)' }} onClick={() => { setShowCoupons(show => !show) }}>I have a coupon<IonIcon color="light" icon={closeCircle} className="cardInputToggleClose"></IonIcon></div>
            <SlideDown className={'my-dropdown-slidedown'} closed={!(showCoupons || props.mopCoupons?.length > 0)}>
                <div className="cardInputInner">
                    <h1>Enter your Coupon Barcode Number</h1>
                    <IonInput disabled={IsBusy}
                        class="CouponCodeField"
                        clearInput
                        value={CouponCode}
                        onIonChange={(s) => {
                            if (s.detail.value != undefined) {
                                SetCouponCode(s.detail.value);
                                SetError("");
                            }
                        }}
                        type="number" placeholder="Coupon Barcode Number" className="couponInput" />
                    {Error.length > 0 && <div className="fieldErrorMessage">{Error}</div>}

                </div>
            </SlideDown>
        </div>

        <SlideDown className={'my-dropdown-slidedown'} closed={!(showCoupons || props.mopCoupons?.length > 0)}>

            {props.mopCoupons != null && props.mopCoupons.length > 0 && <IonGrid fixed className="ion-no-padding ion-no-margin CouponsContainer">
                <IonRow className="">
                    {props.mopCoupons != null && props.mopCoupons.filter((b: IMopCoupon) => b.rejected == false).map((c: IMopCoupon, idx: number) => (<IonCol
                        className="CouponContainer ion-no-padding ion-no-margin"
                        size="12" key={idx}>
                        <IonButton
                            disabled={IsBusy}
                            fill="clear" className="RemoveCoupon" onClick={(e) => {
                                SetIsBusy(true);
                                props.deleteCouponCode(c.couponCode, (r: boolean) => {
                                    SetIsBusy(false);
                                });
                            }}><IonIcon slot="icon-only" color="coupon" name="close-circle" size="large" />
                        </IonButton>
                        <span className="CouponCode" dangerouslySetInnerHTML={{ __html: getCouponDescription(c.couponCode) }}></span>
                    </IonCol>))}
                </IonRow>
            </IonGrid>}

            <div className="SubmitButtonWrapper">
                <IonButton disabled={IsBusy}
                    color="primary" className="SubmitCoupon" type="button" onClick={() => {

                        const canAdd = CouponCode?.length == 13 && CouponCode.substring(0, 3) == "992";

                        if (canAdd == false) {
                            SetError("Please enter a valid Coupon Barcode Number");
                            return;
                        }

                        SetError("");

                        let duplicatCoupon: any = [];

                        if (props.mopCoupons != null) {
                            duplicatCoupon = props.mopCoupons.filter((c: any) => c.couponCode == CouponCode);
                        }

                        if (duplicatCoupon.length == 0) {
                            SetError("");
                            SetIsBusy(true);
                            props.addCouponCode(CouponCode, (r: boolean, basket: any) => {
                                if (basket) {
                                    let couponsAdded = basket.mopCoupons.filter((c: any) => c.couponCode == CouponCode);
                                    if (couponsAdded.length > 0) {
                                        const thatCoupon = couponsAdded[0] as IMopCoupon;
                                        if (thatCoupon.rejected == true) {
                                            const mappedError = couponErrorMappings[thatCoupon.errorCode];
                                            if (mappedError) {
                                                SetError(mappedError);
                                            }
                                            else {
                                                SetError("An unknown error has occurred");
                                            }
                                            props.deleteCouponCode(thatCoupon.couponCode, (r: boolean) => {
                                                SetIsBusy(false);
                                            });
                                        }
                                        else {
                                            SetCouponCode("");
                                        }
                                    }
                                }
                                SetIsBusy(false);
                            });
                        }
                        else {
                            SetError("This coupon has already been added");
                        }

                    }}>Add coupon</IonButton>
            </div>



        </SlideDown>



    </div>)
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
    mopCoupons: state.basket?.basket.mopCoupons,
    menuItems: state.basket?.basket.menuItems
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        addCouponCode: (couponCode: string, callback: any) => dispatch(BasketStore.actionCreators.addCouponCode(couponCode, callback)),
        deleteCouponCode: (couponCode: string, callback: any) => dispatch(BasketStore.actionCreators.deleteCouponCode(couponCode, callback))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MopCoupons as any)



//    < IonInput disabled = { IsBusy }
//class="CouponCodeField"
//clearInput
//value = { CouponCode }
//onIonChange = {(s) => {
//    if (s.detail.value != undefined) {
//        SetCouponCode(s.detail.value);
//        SetCanAddCouponCode(s.detail.value?.length == 13 && s.detail.value.substring(0, 5) == "99273");
//        SetError("");
//    }
//}}
//type = "number" placeholder = "Enter coupon..." className = "couponInput" />
//    { Error.length > 0 && <div className="fieldErrorMessage">{Error}</div> }
//{
//    props.mopCoupons != null && props.mopCoupons.length > 0 && <IonGrid fixed className="ion-no-padding ion-no-margin CouponsContainer">
//        <IonRow className="">
//            {props.mopCoupons != null && props.mopCoupons.map((c: IMopCoupon, idx: number) => (<IonCol
//                className="CouponContainer ion-no-padding ion-no-margin"
//                size="6" key={idx}>
//                <IonButton
//                    disabled={IsBusy}
//                    fill="clear" className="RemoveCoupon" onClick={(e) => {
//                        SetIsBusy(true);
//                        props.deleteCouponCode(c.couponCode, (r: boolean) => {
//                            console.log("deleteCouponCode.success: " + r);
//                            SetIsBusy(false);
//                        });
//                    }}><IonIcon slot="icon-only" color="primary" name="close-circle" size="large" />
//                </IonButton>
//                <span className="CouponCode">{c.couponCode}</span>
//            </IonCol>))}
//        </IonRow>
//    </IonGrid>
//}