import React, { createRef, useState } from 'react';
import { IonPage } from '@ionic/react';
import CheckoutPayCard from '../components/CheckoutPayCard';
import './CheckoutTestPage.scss';
import { withRouter, RouteComponentProps } from 'react-router';


interface CheckoutProps {
}

type CheckoutMergeProps =
    RouteComponentProps & CheckoutProps;

class CheckoutTestPage extends React.Component<CheckoutMergeProps> {

    constructor(props: CheckoutMergeProps){
        super(props);
        
    }
    render() {
        return (
            <IonPage className="CheckoutPage__wrapper">
                
                <CheckoutPayCard/>
            </IonPage>
        )
    }
}

export default withRouter(CheckoutTestPage as any);