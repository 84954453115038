import { IonContent, IonGrid, IonLabel, IonPage, IonRow } from '@ionic/react';
import { Field, FieldProps, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { nicknameSchema } from '../helpers/formValidationHelpers';
import GenericInput from '../inputs/GenericInput';
import './NameOrNickname.scss';
import * as BasketStore from '../store/BasketStore';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import { useLocation } from "react-router-dom";

const NameOrNickname: React.FC = () => {
    const boundSetNickname = bindActionCreators(BasketStore.actionCreators.setNickname, useDispatch());

    const BasketState = useSelector((state: ApplicationState) => state.basket);
    const LocationState = useSelector((state: ApplicationState) => state.location);
    const NicknameFormErrors = useSelector((state: ApplicationState) => state.basket?.nicknameFormErrors);
    const ContinueClicked = useSelector((state: ApplicationState) => state.basket?.continueClicked);

    const location = useLocation();

    const [initialValues, setInitialValues] = useState<any>({});

    useEffect(() => {
        setInitialValues({ Nickname: localStorage.getItem("nickname") || BasketState?.nickname || "" });
    }, [location.pathname]);

    useEffect(() => {
    }, [ContinueClicked])

    return (
        <IonPage>
            <IonContent fullscreen class="NameOrNickname">
                <IonGrid className="NameOrNickname__grid-wrapper">
                    <div className="NameOrNickname__content-container">
                        <IonRow className="NameOrNickname__title">
                            How can we find you?
                        </IonRow>
                        <IonRow className="NameOrNickname__text">
                            Provide your name or nickname so we can find you in the Cafe when your order is ready
                        </IonRow>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={nicknameSchema}
                            enableReinitialize={true}
                            onSubmit={(values) => {
                                boundSetNickname(values.Nickname ?? "");
                            }}
                        >
                            {(props) => (
                                <form onSubmit={props.handleSubmit} className="NameOrNickname__form-container">
                                    <IonLabel className="inputLabel">Name or nickname<span>*</span></IonLabel>
                                    {(LocationState && LocationState.selectedStore != undefined) &&
                                        <Field name="Nickname">
                                            {({ field, form }: FieldProps) => (
                                                <GenericInput
                                                    name="Nickname"
                                                    IonInputProps={{ type: "string", autofocus: true, placeholder: "Name (maximum 20 characters" }}
                                                    className="NameOrNickname__input"
                                                    onIonChange={(e) => {
                                                        let max = 20;
                                                        let value = e.detail.value;
                                                        if (value.length > max) {
                                                            value = value.slice(0, max);
                                                        }

                                                        form.setFieldValue(field.name, value);

                                                        if (value.trim() === '') {
                                                            localStorage.removeItem('nickname');
                                                        } else {
                                                            localStorage.setItem('nickname', value);
                                                        }

                                                        props.handleSubmit();
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    }
                                    {ContinueClicked && NicknameFormErrors && <div className="fieldErrorMessage">{NicknameFormErrors}</div>}
                                </form>
                            )}
                        </Formik>
                    </div>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default NameOrNickname;
