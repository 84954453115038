import { ActionSheetOptionStyle } from '@capacitor/core/dist/esm/core-plugin-definitions';
import { State } from 'ionicons/dist/types/stencil-public-runtime';
import { fastFood } from 'ionicons/icons';
import { Dispatch } from 'react';
import { act } from 'react-dom/test-utils';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { simpleChannelType } from './LocationStore';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface HeaderFooterState {
    title: string;
    showBack: boolean;
    headerMode: HeaderMode;
    footerMode: FooterMode;
    showBasketModal: boolean;
    modals: {
        selectedModal: string;
        singleInstance: boolean;
    }
    hasStartedApp: boolean;
    showDebugLabel: boolean;
}

export enum HeaderMode {
    Hidden = 1,
    Normal,

    PDP

}

export enum FooterMode {
    Hidden = 1,
    Basket,
    PDP,
    ConfirmStore,
    BasketPage,
    UpsellPage,
    TablePage,
    PaymentSuccessPage
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetTitleAction { type: 'SET_TITLE', title:string,showBack:boolean }
export interface SetShowBack { type: 'SET_SHOW_BACK', showBack: boolean }
export interface SetPageAction { type: 'SET_PAGE', path: string,menuTitle:string | undefined,channelType:simpleChannelType | undefined }
export interface SetBasketShowModal { type: 'SET_SHOW_BASKET_MODAL', value: boolean }
export interface setShowModal { type: 'SET_SHOW_MODAL', selectedModal: string, singleInstance: boolean}
export interface setHasStartedApp { type: 'SET_HAS_STARTED_APP' }
export interface ShowDebugLabels { type: 'SHOW_DEBUG_LABELS', show: boolean }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetTitleAction | SetPageAction | SetBasketShowModal | setShowModal | setHasStartedApp | SetShowBack | ShowDebugLabels;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {

    setTitle: (title: string,showBack:boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_TITLE', title:title,showBack:showBack });
        //  }
    },
    setPage: (path: string,selectedChannel?:simpleChannelType): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let menuTitle = undefined;
        if (path.includes('categories') ) {
            menuTitle = appState.menuData?.categoryCurrent?.categoryTitle;
        }
        dispatch({ type: 'SET_PAGE', path: path, menuTitle: menuTitle, channelType:appState.location?.selectedChannel?.channelType });
        //  }
    },
    setBasketShowModal: (value: boolean) : AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_SHOW_BASKET_MODAL', value });
    },
    setShowModal: (name: "MAIN" | "TC" | "FAQ" | "ALLERGEN" | string, value: boolean, singleInstance?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        //console.log("Current state = ", state.headerFooter?.modals);
        //console.log("Setting modal", name, "to ", value, " singleInstance: ", singleInstance);
        let _singleInstance: boolean = singleInstance || false;
        let _selectedModal: string = "";

        if(!value && (state.headerFooter?.modals.selectedModal != name)){
            return;
        }

        if( value == false ){
            if( state.headerFooter?.modals.singleInstance || name == "MAIN" || state.headerFooter?.modals.selectedModal == ""){

            }
            else{
                _selectedModal = "MAIN"
            }
        }
        else{
            _selectedModal = name;
        }

        dispatch({ type: "SET_SHOW_MODAL", selectedModal: _selectedModal, singleInstance: _singleInstance });
    },
    setShowBack: (showBack: boolean) : AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_SHOW_BACK', showBack: showBack });
    },
    setHasStartedApp: (callback: () => void) : AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SET_HAS_STARTED_APP' });
        callback();
    },
    setShowDebugLabels: (show: boolean) : AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'SHOW_DEBUG_LABELS', show: show });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<HeaderFooterState> = (state: HeaderFooterState | undefined, incomingAction: Action): HeaderFooterState => {
    if (state === undefined) {
        return { hasStartedApp: false, title: "No title", showBack: false, footerMode: FooterMode.Hidden,headerMode: HeaderMode.Hidden, showBasketModal: false,
        modals: {
            selectedModal: "",
            singleInstance: false
        }, showDebugLabel: false }
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_TITLE':
            console.log("TITLE:" + action.title);
            return {
                ...state,
                title: action.title,
                showBack: action.showBack
            };
        case 'SET_SHOW_BACK':
            return {
                ...state,
                showBack: action.showBack
            }
        // Called automatically when router changes page
        case 'SET_PAGE':
            console.log("SET_PAGE: " + action.path );
            let newTitle = state.title;
            let newShowBack = state.showBack;

            let newFooterMode: FooterMode = FooterMode.Hidden;
            let newHeaderMode: HeaderMode = HeaderMode.Hidden;

            if (action.path === '/splash') {
                newTitle = "Splash";
                newHeaderMode = HeaderMode.Hidden;
                newFooterMode = FooterMode.Hidden;
            }

            if (action.path === '/categories') {
                newHeaderMode = HeaderMode.Normal;
                newFooterMode = FooterMode.Basket;
                newShowBack = true;
                newTitle = "Menu";
            } else if (action.path.includes('categories')) {
                newHeaderMode = HeaderMode.Normal;
                newFooterMode = FooterMode.Basket;
                if (action.menuTitle) {
                    //console.debug("Setting CATEGORY title to: ", action.menuTitle);
                    newTitle = action.menuTitle;
                }
                newShowBack = true;
            }

            if (action.path.includes('menuitem')) {
                newHeaderMode = HeaderMode.PDP;
                newFooterMode = FooterMode.PDP;
            }

            if (action.path.includes('location')) {
                newTitle = "Order & Collect";
                newHeaderMode = HeaderMode.Normal;
                newFooterMode = FooterMode.Hidden;
                newShowBack = false;
            }

            if (action.path.includes('/storesearch')) {
                newTitle = "Order & Collect";
                newHeaderMode = HeaderMode.Normal;
                newFooterMode = FooterMode.ConfirmStore;
                newShowBack = true;
            }

            if (action.path.includes('/choosetimeslot')) {
                switch (action.channelType) {
                    case simpleChannelType.ClickAndCollect:
                        newTitle = "Collection Time";
                        break;

                    case simpleChannelType.TableOrder:
                        newTitle = "Order Time";
                        break;
                }
                newHeaderMode = HeaderMode.Normal;
                newFooterMode = FooterMode.ConfirmStore;
                newShowBack = true;
            }
            if (action.path.includes('qr')) {
                newTitle = "Order & Collect";
                newHeaderMode = HeaderMode.Normal;
                newFooterMode = FooterMode.Hidden;
                newShowBack = false;
            }

            if (action.path.includes('enteremail')) {

                newTitle = "Your Details";
                newHeaderMode = HeaderMode.Normal;
                newFooterMode = FooterMode.Hidden;
                newShowBack = true;
            }

            if (action.path.includes('orderconfirmation')) {
                newTitle = "Checkout";
                newHeaderMode = HeaderMode.Normal;
                newFooterMode = FooterMode.Hidden;
                newShowBack = true;
            }

            if (action.path.includes('basketpage')) {
                if(action.channelType === simpleChannelType.ClickAndCollect) {
                    newTitle = "Your Take Away Order";
                }
                else if(action.channelType === simpleChannelType.TableOrder) {
                    newTitle = "Your Eat In Order";
                }
                console.log("EGGS: " + action.channelType);
                newHeaderMode = HeaderMode.Normal;
                newFooterMode = FooterMode.BasketPage;
            }

            if (action.path.includes('temptyou')) {
                newTitle = "Can we tempt you?";
                newHeaderMode = HeaderMode.Normal;
                newFooterMode = FooterMode.UpsellPage;
            }

            if (action.path.includes('qrlanding')) {
                newTitle = "Morrisons - Order & Collect";
                newHeaderMode = HeaderMode.Normal;
                newFooterMode = FooterMode.Hidden;
                newShowBack = false;
            }

            if (action.path.includes('payment')) {
                newTitle = "Payment";
                newHeaderMode = HeaderMode.Normal;
                newFooterMode = FooterMode.Hidden;
                newShowBack = true;
            }

            if(action.path.includes("payment/success")){
                newTitle = "Order Confirmation";
                newHeaderMode = HeaderMode.Normal;
                newFooterMode = FooterMode.PaymentSuccessPage;
                newShowBack = false;
            }

            if(action.path.includes("/ordertype")){
                newTitle = "Order type";
                newHeaderMode = HeaderMode.Normal;
                newFooterMode = FooterMode.ConfirmStore;
                newShowBack = true;
            }

            if(action.path.includes("/tablenumber")){
                newTitle = "Table Number";
                newHeaderMode = HeaderMode.Normal;
                newFooterMode = FooterMode.TablePage;
                newShowBack = true;
            }

            if (action.path.includes("/name-or-nickname")) {
                newTitle = "Name or Nickname";
                newHeaderMode = HeaderMode.Normal;
                newFooterMode = FooterMode.TablePage;
                newShowBack = true;
            }

            console.debug("Setting title to:" + newTitle);
            return {
                ...state,
                title:  newTitle ,
                headerMode: newHeaderMode,
                footerMode: newFooterMode,
                showBack: newShowBack,

            };
        case 'SET_SHOW_BASKET_MODAL':
            return {
                ...state,
                showBasketModal: action.value
            }
        case 'SET_SHOW_MODAL': 
            return {
                ...state,
                modals: {
                    selectedModal: action.selectedModal,
                    singleInstance: action.singleInstance
                }
            }
        case 'SET_HAS_STARTED_APP':
            return {
                ...state,
                hasStartedApp: true
            }
        case 'SHOW_DEBUG_LABELS':
            return {
                ...state,
                showDebugLabel: action.show
            }
        default:
            return state;
    }
};
