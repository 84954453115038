import { IonButton, IonContent, IonGrid, IonItem, IonLabel, IonPage, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import { Field, FieldProps, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import AppHeaderImage from '../components/AppHeaderImage';
import { tableNumberSchema } from '../helpers/formValidationHelpers';
import GenericInput from '../inputs/GenericInput';
import './TableNumber.scss';
import * as BasketStore from '../store/BasketStore';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import { useHistory } from 'react-router';
import { getConfig } from '../helpers/configHelper';

const TableNumber: React.FC = () => {

	const boundSetTableNumber = bindActionCreators(BasketStore.actionCreators.setTableNumber, useDispatch());
	const BasketState = useSelector((state: ApplicationState) => state.basket);
    const TableFormErrors = useSelector((state: ApplicationState) => state.basket?.tableFormErrors);
    const ContinueClicked = useSelector((state: ApplicationState) => state.basket?.continueClicked);
	const LocationState = useSelector((state: ApplicationState) => state.location);
	const history = useHistory();
	const maxTables = 47;

    useEffect(() => {
    }, [ContinueClicked])

	return (
		<IonPage>
			<IonContent fullscreen class="TableNumber">
				<IonGrid className="TableNumber__grid-wrapper">
					<div className="TableNumber__content-container">
						<IonRow className="TableNumber__title">
							Where are you sitting?
						</IonRow>
						<IonRow className="TableNumber__text">
							Look for the number on the sticker on your table
						</IonRow>
						<Formik
							initialValues={{ TableNumber: BasketState?.tableNumber || "" }}
							validationSchema={tableNumberSchema}
							enableReinitialize={true}
							onSubmit={(values) => {
								//console.log("Submitting table number", values);
								boundSetTableNumber(values.TableNumber as number);
							}}
						>
							{(props) => (
								<form onSubmit={props.handleSubmit} className="TableNumber__form-container">
									<div className="TableNumber__inputWrapper">
										<div className="TableNumber__inputTitle">Table number</div>
										{
											(LocationState && LocationState.selectedStore != undefined) &&
											<Field name="TableNumber">
												{({ field, meta, form }: FieldProps) => {
													var error = (meta.error && meta.touched) ? meta.error : false;
													return (getConfig().isMarket && LocationState.selectedStore?.auxStoreData && LocationState.selectedStore.auxStoreData.tableNumbers)
														?
														<>
															<IonItem className="TableNumber__select-container">
																<IonSelect id="TableNumber__Select" interface="action-sheet" value={field.value} placeholder="Select Table Number" onIonChange={e => { form.setFieldValue(field.name, e.detail.value); props.handleSubmit(); }}>
																	{
																		LocationState.selectedStore.auxStoreData.tableNumbers.map((table) => {
																			return <IonSelectOption  key={"TableNumber__" + table} value={table}>Table M{table}</IonSelectOption>
																		})
																	}
																</IonSelect>
															</IonItem>
															{error && <div className="fieldErrorMessage">{error}</div>}
														</>
														:
														<>
															<GenericInput
																name="TableNumber"
																IonInputProps={{ type: "number", autofocus: true }}
																className="TableNumber__input"
																onIonChange={(e, field, form) => {
																	console.log(e, field);
																	let max = 3;
																	let value = e.detail.value;
																	if (e.detail.value.length > max) {
																		value = value.slice(0, max);
																	}

																	form.setFieldValue(field.name, value);
																	props.handleSubmit();
																}}
															/>
														</>
												}}
											</Field>
										}
                                        {ContinueClicked && TableFormErrors &&
                                            <div className="fieldErrorMessage">{TableFormErrors}</div>
                                        }
									</div>
									{/* 

                                    (() => {
                                        let tables = [];
                                        for(let i = 0; i < maxTables; i++){
                                            tables.push(<IonSelectOption value={i}>Table {i}</IonSelectOption>);
                                        }
                                        return tables;
                                    })()

                                    <GenericInput
                                        name="TableNumber"
                                        IonInputProps={{type: "number", autofocus: true}}
                                        className="TableNumber__input"
                                        onIonChange={(e, field, form) => {
                                            console.log(e, field);
                                            let max = 3;
                                            let value = e.detail.value;
                                            if(e.detail.value.length > max){
                                                value = value.slice(0, max);
                                            }

                                            form.setFieldValue(field.name, value);
                                        }}
                                    />
                                    */}
								</form>
							)
							}
						</Formik>
					</div>
				</IonGrid>
			</IonContent>
		</IonPage>
	)
};

export default TableNumber;
