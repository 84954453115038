import React, { useState } from 'react';
import { IonCheckbox, IonItem, IonLabel, IonList, IonRadio } from '@ionic/react';
import './ProductSingleSelect.scss';
import { formatCurrency } from '../helpers/currencyHelpers';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';

interface ProductSingleSelect {
    productSingleSelectName: string;
    disabled?: boolean
    onClick?: (categoryId: number) => void;
    price?: number | null
    isFromPrice?: boolean
    checked?: boolean
    debugLabel?: string
    onChange?: (v: any) => void
    kcal?: number
}

const ProductExtra: React.FC<ProductSingleSelect> = ({ productSingleSelectName, onClick, disabled, price, isFromPrice, checked, debugLabel, onChange, kcal}) => {

    const headerState = useSelector((state: ApplicationState) => state.headerFooter);
    //kcal = 1234;

    return (
        
        <IonItem lines="none" className="ProductSingleSelectItem">
            {
                price
                ?
                    <IonList className="SingleSelect__List">
                        <div>                        
                            <span className="SingleSelect__name" dangerouslySetInnerHTML={{__html: productSingleSelectName}}></span>
                            {
                                (kcal != undefined && kcal >= 0) &&
                                <span className='modifierGroupItemKcal'>
                                    ({kcal}kcal)
                                </span>
                            }
                        </div>
                        <div className="SingleSelect__price">{isFromPrice && "From "}{formatCurrency(price)}</div>
                    </IonList>
                :
                    <IonLabel item-right>
                        <div>
                            <span dangerouslySetInnerHTML={{__html: productSingleSelectName}}></span>
                            {
                                (kcal != undefined && kcal >= 0) &&
                                <span className='modifierGroupItemKcal'>
                                    ({kcal}kcal)
                                </span>
                            }
                        </div>
                    </IonLabel>
            }
            {
                checked != undefined 
                ?
                    <IonCheckbox onIonChange={(e) => onChange && onChange(e.detail.value)} disabled={disabled} item-left slot="start" value={productSingleSelectName} checked={checked}></IonCheckbox>
                :
                    <IonRadio disabled={disabled} item-left slot="start" value={productSingleSelectName}></IonRadio>
            }
            {
                headerState?.showDebugLabel &&
                <div className="modifierItemDebug">
                    {debugLabel}
                </div>
            }

        </IonItem>
  );
};

export default ProductExtra;
