import React from 'react';
import { IonContent, IonRow, IonPage } from '@ionic/react';
import { useHistory } from "react-router-dom";
import './UpsellPage.scss';

import { formatCurrency } from '../helpers/currencyHelpers';

import { ApplicationState } from '../store';
import { useSelector } from 'react-redux';

import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';

import * as BasketStore from '../store/BasketStore';
import * as MenuItemStore from '../store/MenuItemStore';
import MenuItemTile from '../components/MenuItemTile';
import { simpleChannelType } from '../store/LocationStore';
import AllergenPopup from '../modals/AllergenPopup';

const InfoPage: React.FC = () => {
    let history = useHistory();

    const pfIdentifier = localStorage.getItem("pfIdentifier");
    const isLoggedIn = localStorage.getItem('isLoggedIn') === "true";

    const basketStore = useSelector((state: ApplicationState) => state.basket);
    const locationStore = useSelector((state: ApplicationState) => state.location);

    const boundUpsellDone = bindActionCreators(BasketStore.actionCreators.upsellDone, useDispatch());
    const boundRequestMenuItem = bindActionCreators(MenuItemStore.actionCreators.requestMenuItem, useDispatch());

    const showAllergenPopup = useSelector((state: ApplicationState) => state.basket?.showAllergenPopup);
    const setShowAllergenPopup = bindActionCreators(BasketStore.actionCreators.showAllergenPopup, useDispatch());

    const goToNextPage = () => {
        setShowAllergenPopup(false);

        switch (locationStore?.selectedChannel?.channelType) {
            case simpleChannelType.ClickAndCollect:
                if (isLoggedIn) {
                    history.push("/name-or-nickname");
                } else {
                    history.push("/enteremail");
                }
                break;
            case simpleChannelType.TableOrder:
                if (isLoggedIn) {
                    history.push("/name-or-nickname");
                } else {
                    history.push("/tablenumber");
                }
                break;
        }
    };

    const navigateToMenuItem = (menuItemId: number, referrer: string | null,) => {
        boundUpsellDone();

        boundRequestMenuItem(menuItemId, pfIdentifier ?? "", referrer, () => {
            history.push(`/menuitem/${menuItemId}`);
        });
    }

    return (
        <IonPage >
            <IonContent fullscreen className="UpsellPage">

                    <div className="BasicGrid__wrapper">

                        <IonRow className="categoryGrid">
                            {basketStore && basketStore.upsells && basketStore.upsells.map((a: IMopMenuItemDetail, i) => {

                                return (

                                    <MenuItemTile
                                        fullWidth={false}
                                        kcal={a.kcal}
                                        name={a.menuItemName}
                                        key={"ef" + a.menuItemId}
                                        menuItemId={a.menuItemId}
                                        inStock={a.inStock}
                                        fromPrice={a.menuItemFromPrice != null}
                                        price={a.menuItemFromPrice ? formatCurrency(a.menuItemFromPrice) : formatCurrency(a.menuItemBasePrice)}
                                        onAddClick={() => navigateToMenuItem(a.menuItemId, a.referrer)} sku={a.sku} itemTags={a.itemTags}
                                        plpRoundels={a.plpRoundels}
                                    />
                                )
                            })}
                        </IonRow>
                    </div>
                    <AllergenPopup
                        show={showAllergenPopup}
                        close={() => setShowAllergenPopup(false)}
                        accept={goToNextPage}
                    />
            </IonContent>
        </IonPage>
    );
};

export default InfoPage;
