import { IonCol, IonGrid, IonImg, IonRow, IonTitle } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { baseUrl, cacheBust, getConfig } from '../helpers/configHelper';
import * as currencyHelpers from '../helpers/currencyHelpers';
import AllergenInfoModal from '../modals/AllergenInfoModal';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';

interface IHeaderDescription {
    headerImage: string | null;
    headerTitle?: string;
    itemTags?: string;
    allergenInfo?: string;
    description?: string;
    minHeight: string;
    hideText: boolean;
    fontColour?: string
    identifier: string | undefined;
    displayType: string;
    kcal?: number;
    altTitle?: string;
    price?:number
    //Appears to the aligned right of the main title
	pdpRoundels?: string[]
}

const HeaderDescription: React.FC<IHeaderDescription> = ({headerImage, headerTitle, itemTags, allergenInfo, description, minHeight, hideText, identifier,altTitle,displayType, fontColour, kcal, price, pdpRoundels }) => {

    const [allergenInfoOpen, setAllergenInfoOpen] = useState(false);
    const headerState = useSelector((state: ApplicationState) => state.headerFooter);
    const [productPromos, setProductPromos] = useState<string[]>()

    useEffect(() => {
        setProductPromos(pdpRoundels)
    }, [pdpRoundels])

    return (
        <>
        <IonGrid className="ImageHeader">
                <IonRow>
                {
                    headerState?.showDebugLabel &&
                    <div className="miDebugLabelHeader">
                        {identifier} img:{headerImage} {displayType}
                    </div>
                }
                <IonCol className="MenuItemTopImageWrapper" size="12" size-xs="12" style={{ minHeight: minHeight }}>
					{!hideText && headerImage && <IonImg onIonError={(e) => { console.log(e) }} className="MenuItemTopImage" src={headerImage +`?v=${cacheBust()}`} /> }
                    {productPromos && productPromos?.length > 0 && <IonImg className="MenuItemTopImageProductPromo" src={`${baseUrl()}/images/ProductPromos/${productPromos[0]}`} />}
                    {productPromos && productPromos?.length > 1 && <IonImg className="MenuItemTopImageProductPromo" src={`${baseUrl()}/images/ProductPromos/${productPromos[1]}`} />}
                </IonCol>
                { 
                    !hideText &&
                    <IonCol size-sm="12" className="ion-no-padding">
                        <div className="ProductInfoWrapper">
                            <IonTitle style={{color: fontColour ? fontColour : ""}} className="ProductTitle">
                                <div className="product-title-container">
                                    <div className="ion-text-wrap" dangerouslySetInnerHTML={{ __html: headerTitle ? headerTitle: '' }}></div>
                                    { (price !== undefined && price > 0 ) && <div className="base-price">{currencyHelpers.formatCurrency(price)}</div> }
                                </div>
                            </IonTitle>
                            { description && <div className="ProductDesciption"><span dangerouslySetInnerHTML={{ __html: description ? description : '' }}></span></div> }
                            <div className='MenuItemNutritionWrapper'>
                                { kcal && <div className='MenuItemCalories'>{kcal}kcal</div> }
                                { (allergenInfo !== null && allergenInfo !== undefined && allergenInfo.length !== 0) && <div className="AllergyInfoLink" onClick={() => { setAllergenInfoOpen(true) }}>View allergens</div> }
                            </div>                                                    
                            { (itemTags !== null && itemTags !== undefined && itemTags.length !== 0) && <div className="product-tags">                                
                                {itemTags?.includes("[VG]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/vegan-icon.png`} alt="Vegan"/>}
                                {itemTags?.includes("[VE]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/v-icon.png`} alt="Vegetarian"/>}
                                {itemTags?.includes("[U400]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/under400.png`} style={{ width: "56px" }} alt="Under 400 calories"/>}
                                {itemTags?.includes("[U600]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/under600.png`} style={{ width: "56px" }} alt="Under 600 calories"/>}
                                {itemTags?.includes("[MMI]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/MorrisonsMakesIt.png`} alt="Morrisons makes it"/>}
                                {itemTags?.includes("[MF]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/icon_Meat%20Free.png`} alt="Meat free"/>}
                                {itemTags?.includes("[NEW]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/new-icon.png`} style={{ width: "48px" }} alt="New Icon" />}
                            </div>}
                        </div>
                    </IonCol>
                }
            </IonRow>
        </IonGrid>
        <AllergenInfoModal isModalOpen={allergenInfoOpen} closeModalClick={ () => { setAllergenInfoOpen(false) }} allergenHtml={allergenInfo}></AllergenInfoModal>
        </>
    )
}

export default HeaderDescription;