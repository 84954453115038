import React, { useEffect, useState } from 'react';
import { IonHeader, IonTitle, IonToolbar, IonIcon, IonGrid, IonRow, IonCol, IonAlert } from '@ionic/react';
import './AppHeader.scss';
import { closeCircle, chevronBack, menu } from 'ionicons/icons';
import { ApplicationState } from '../store';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import '../theme/common.scss';
import * as headerFooterStore from '../store/HeaderFooterStore';
import { getConfig } from '../helpers/configHelper';
import MopHeaderMenusModal from '../modals/MopHeaderMenusModal'
import { bindActionCreators } from 'redux';
import { baseUrl } from '../helpers/configHelper';
import * as ColleagueStore from '../store/ColleagueStore';

const allowedStartingPages = [
	"/splash",
	"/faq",
	"/qr",
	"/couponz"
];

interface AppHeaderProps {

}

const AppHeader: React.FC<AppHeaderProps> = ({ }) => {

	let history = useHistory();
	const location = useLocation();
	const headerFooter = useSelector((state: ApplicationState) => state.headerFooter);

	const boundSetShowModal = bindActionCreators(headerFooterStore.actionCreators.setShowModal, useDispatch());
	const boundShowDebugLabels = bindActionCreators(headerFooterStore.actionCreators.setShowDebugLabels, useDispatch());
	const logout = bindActionCreators(ColleagueStore.actionCreators.logout, useDispatch());

	const [showModal, _setShowModal] = useState("");
	const [showLogoutAlert, setShowLogoutAlert] = useState(false);
	const [redirect, setRedirect] = useState("");
	const siteTitle = "Morrisons :: Order & Pay";

	//mtr debug, need the basket to test
	//document.addEventListener('keydown', function (event) {
	//    if(window.location.hostname === 'localhost') {
	//        if (event.keyCode === 37 && event.shiftKey === true) {
	//            history.push("/payment");
	//        }
	//    }
	//});
	//

	const handleLogout = () => {
		setShowLogoutAlert(true);
	};

	const handleLogoutConfirmation = (confirmed: boolean) => {
		if (confirmed) {
			logout();
			localStorage.removeItem("isLoggedIn");
			localStorage.removeItem("pfIdentifier");
			window.location.reload();
		}
		setShowLogoutAlert(false);
	};

	useEffect(() => {
		if (!isUserAllowedToLandHere()) {
			setTimeout(() => {
				//history.push("/splash");    //Setting timeout otherwise screen goes white
				window.location.href = baseUrl() + "/splash";
			}, 500);
		}

		var redirect: string = "";
		switch (location.pathname) {
			case "/payment": {
				var redirect = "/orderconfirmation"
			}
				break;
			case "/tablenumber": {
				var redirect = "/basketpage"
			}
				break;
			case "/faq": {
				batch(() => {
					history.push("/splash");
					setTimeout(() => {
						boundSetShowModal("FAQ", true, true); //Sets a timeout so this *should* be called when already on the splash page, instead of being on /faq still
					}, 500);
				})
			}
				break;
		}
		setRedirect(redirect);

		if (headerFooter?.modals.selectedModal != undefined && headerFooter?.modals.selectedModal != "") {
			boundSetShowModal("MAIN", false);   //Closes all modals if navigating back while one is open
		}

		setTimeout(() => {  //In the event that a page does not have a title, the pathname will be sent instead.
			let noPageTitle = headerFooter?.title == undefined || headerFooter?.title == "No title";
			if (noPageTitle) {
				sendPageView(location.pathname);
			}
		}, 500);

	}, [location.pathname]);

	useEffect(() => {

		let noPageTitle = headerFooter?.title == undefined || headerFooter?.title == "No title";
		if (!noPageTitle && headerFooter != undefined) {
			sendPageView(headerFooter.title);
			document.title = `${headerFooter.title} - ${siteTitle}`
		}

	}, [headerFooter?.title])

	useEffect(() => {

		fetch(baseUrl() + '/stores/GetShowDebugLabels').then((res) => res.json())
			.then((data) => {
				console.log(data);
				boundShowDebugLabels(data.showLabel);
			})

		window.addEventListener("popstate", (e) => { handleBrowserHistoryChange(e, headerFooter?.modals.selectedModal || "") }, false);

		return () => {
			window.removeEventListener("popstate", (e) => { handleBrowserHistoryChange(e, headerFooter?.modals.selectedModal || "") }, false);
		}
	}, [])

	const sendPageView = (page_title: string) => {
		console.log("GOOGLE ANALYTICS - Sending Page Title:", page_title, "headerFooter.title:", headerFooter?.title, "pathname:", location.pathname);
		(window as any).gtag('event', 'page_view', {//G-ZC8W1075C5
			'page_title': page_title
		});
	}

	const isUserAllowedToLandHere = () => {
		let pathname = location.pathname;
		let isAllowed = allowedStartingPages.some((page) => pathname.includes(page)) || headerFooter?.hasStartedApp;
		//isAllowed = true; // THIS MUST BE UNCOMMENTED (MTR)
		return isAllowed;
	}

	const handleBrowserHistoryChange = (e: PopStateEvent, selectedModal: string) => {
		if (redirect != "") {
			goBackRecursive(redirect);
		}
	}

	const goBackRecursive = (to: string) => {
		history.goBack();
		setTimeout(() => {
			if (history.location.pathname != to) {
				goBackRecursive(to);
			} else {
				return;
			}
		}, 100);
	}

	const basketStore = useSelector((state: ApplicationState) => state.basket);

	return (
		<>
			<IonHeader
				className={`appHeader ${headerFooter?.headerMode == headerFooterStore.HeaderMode.PDP ? "productAppHeader" : ""}`}
				color="transparent"
				hidden={headerFooter?.headerMode == headerFooterStore.HeaderMode.Hidden}>

				{headerFooter?.headerMode == headerFooterStore.HeaderMode.Normal &&
					<IonToolbar className={'AppHeaderInner'} color="light">

						{headerFooter?.showBack &&
							<div className="BackArrowWrapper" onClick={() => {
								if (redirect != "") {
									goBackRecursive(redirect);
								} else {
									history.goBack();
								}
							}}>
								<IonIcon icon={chevronBack} slot={"start"} class="BackArrow" />
							</div>
						}

						<div className="AppHeaderTitle">
							<IonTitle>{headerFooter?.title}</IonTitle>
						</div>

						<IonIcon
							icon={menu}
							className="MenuIcon"
							onClick={() => boundSetShowModal("MAIN", true)} />

					</IonToolbar>
				}

				{headerFooter?.headerMode === headerFooterStore.HeaderMode.PDP &&
					<IonToolbar
						color="transparent"
						className={'AppHeaderInner productAppHeader'} >

						<IonIcon
							icon={closeCircle}
							className="CloseButton"
							onClick={() => history.goBack()} />

					</IonToolbar>
				}

				<MopHeaderMenusModal
					isModalOpen={headerFooter?.modals.selectedModal !== ""}
					closeModalClick={() => boundSetShowModal("MAIN", false)} />

				{localStorage.getItem('isLoggedIn') === "true" && !location.pathname.includes("/menuitem") &&
					<div className="logged-in-banner">
						<div>
							<span />
							<p>You are logged in to Colleague Area Access</p>
						</div>
						<button onClick={handleLogout}>Log out</button>
					</div>
				}
			</IonHeader>

			<IonAlert
				isOpen={showLogoutAlert}
				header="Are you sure you want to log out of Colleague Area Access?"
				buttons={[
					{
						text: 'No',
						role: 'cancel',
						handler: () => handleLogoutConfirmation(false),
					},
					{
						text: 'Yes',
						handler: () => handleLogoutConfirmation(true),
					},
				]} />
		</>
	);
};

export default AppHeader;
