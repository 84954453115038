import { RecordWithTtl } from "dns";
import jwtDecode from "jwt-decode";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../store";
import { AuthenticatePaymentRequest, CreatePaymentRequest, IAuthenticatePaymentRequest, IMopCheckoutRequest, IMopCheckoutResponse } from "./checkoutHelper";
import { baseUrl } from "./configHelper";

type TransactionType = "Card" | "Google Pay" | "Apple Pay";

export interface CardSummary {
    transactionType: TransactionType
    cardDetails: Ptsv2PaymentInformationCard
}

export interface TransactionInformation {
    cardSummary: CardSummary
    authPayInfo: IAuthenticatePaymentRequest
    processingInformation: Ptsv2ProcessingInformation
}

interface IPayerAuthentication {
    onError: (message: string) => void
    onPaymentSuccess: (response: IMopCheckoutResponse) => void
}

export interface PayerAuthenticationHelpers {
    Authenticate: (initialTransactionInformation: TransactionInformation) => void
    CreatePayment: (authSetupRequest: IAuthenticatePaymentRequest, cardType: CardSummary, processingInformation: Ptsv2ProcessingInformation, consumerAuthenticationInformation?: Ptsv2ConsumerAuthenticationInformationRequest) => Promise<IMopCheckoutResponse>
}

/*
    Explanation: forwardRef and useImperativeHandle https://reactjs.org/docs/hooks-reference.html#useimperativehandle
        Because CheckoutPayCard is a class component, it cannot use custom hooks, 
        therefore we have to return PayerAuthentication as a functional component that allows a ref
        to be passed in props which can access the helper functions: Authenticate and CreatePayment

    const [Authenticate, CreatePayment] = usePayerAuthentication(props); <- Custom Hook

    const payerAuthRef = createRef<PayerAuthenticationHelpers>() <- forwardRef
    payerAuthRef.current.Authenticate()
    <PayerAuthentication ...props />
        
*/
const PayerAuthentication = forwardRef<PayerAuthenticationHelpers, IPayerAuthentication>(({ onError, onPaymentSuccess}, ref) => {

    /*
        General Overview of Payment/Authenticate flow
            0) PayerAuthentication is functional component that handles methods and required DOM elements for payer authentication
                - There are 2 main methods, Authenticate and CreatePayment
            1) Authenticate( cardTokenInformation ) method is used when Payer Authentication should be performed (Card payment, Non authenticated Google Pay token)
                - the card token information is supplied to this method, either a card flex object, or a digital wallet object
                - this returns values that are then set within payerAuthSetupResponse state, which is used for device data collection
                - we listen for a message event defined in index.html that calls window.listenToAuthentication defined here.
            2) CreatePayment() method called with event payload to check if enrolled into 3DS
                - CreatePayment is a general method that can either complete a payment, fail, or require further authentication
            3) Post returned stepUpUrl to iframe to show 3DS screen
            4) Redirect 3DS response to returnUrl which is then returned to use through window.hideStepUpScreen
            5) CreatePayment() method is called again with 3DS response data to validate authentication
            6) Handle final response

        Update custom hook
            TODO
                Check what data google and apple pay return and create an interface/use existing definitions
            - There are 2 ways of attempting payment, 
                1) Authenticate() - Checking authentication, validating, then capturing settlement
                2) CreatePayment() - Directly attemp payment request without authenticating because it's nto required
                    Apple Pay doesn't require authentication and Google Pay has a flag in it's response indicating if it should
    */

    

    const [initialTransactionInformation, setInitialTransactionInformation] = useState<TransactionInformation | undefined>();
    
    //Object returned when performing Payer Auth Setup against a token (Debit Card or Google Pay)
    // (1) - Values are used in payer auth setup iframe and form for Device Data collection.
    const [payerAuthSetupResponse, setPayerAuthSetupResponse] = useState<RiskV1ConsumerAuthenticationInformation | undefined>();

    //Object returned when performing a payment request (which can include settlement attemp, or payer auth enrollment check)
    //Values are used to submit form that checks if enrolled into 3DS.
    const [checkoutResponse, setCheckoutResponse] = useState<IMopCheckoutResponse | undefined>();
    const paymentResponse = checkoutResponse?.paymentResult;

    const appState = useSelector((state: ApplicationState) => state);
    const payerAuthSetupFormRef = useRef<HTMLFormElement>(null);
    const stepupFormRef = useRef<HTMLFormElement>(null);

    const handleError = (e: unknown) => {
        let result: string | undefined;
        if (typeof e === "string") {
            result = e;
        } else if (e instanceof Error) {
            result = e.message // works, `e` narrowed to Error
        }
        onError(result ?? "Payment request error, please try again or use a different payment method. ERR:CREPAY");
    }

    const Authenticate = async (_initialTransactionInformation: TransactionInformation) => {
        const { authPayInfo, processingInformation } = _initialTransactionInformation;
        try{
            if(authPayInfo && (authPayInfo.flexTokenInformation || authPayInfo.paymentInformation)){
                
                setInitialTransactionInformation(_initialTransactionInformation);
                try{
                    let tokenInformation: Riskv1TokenInformation | undefined;
                    if(authPayInfo.flexTokenInformation){
                        tokenInformation = {
                            transientToken: authPayInfo.flexTokenInformation.jti
                        };
                    }
                    const res = await AuthenticatePaymentRequest({
                        paymentInformation: authPayInfo.paymentInformation,
                        tokenInformation: tokenInformation,
                        processingInformation               
                    });
                    if(res.status == "COMPLETED"){
                        setPayerAuthSetupResponse(res.consumerAuthenticationInformation);
                    }
                    else{
                        throw new Error("Authenticate setup request failed. Please try again. ERR:APSF");
                    }
                }
                catch(err){
                    console.log("AuthenticatePaymentRequest error", err);
                    throw new Error("Authenticate setup request failed. Please try again. ERR:APRE");
                }
                
            }
            else{
                console.log("AuthSetupRequest undefined");
                throw new Error("Authenticate setup request failed. Please try again. ERR:ASRU");
            }
        }
        catch(err){
            handleError(err);
        }
    }

    const CreatePayment = async (authPayInfo: IAuthenticatePaymentRequest, cardSummary: CardSummary, processingInformation: Ptsv2ProcessingInformation, consumerAuthenticationInformation?: Ptsv2ConsumerAuthenticationInformationRequest) => {

        
        try{
            if(appState.basket != undefined && appState.location?.selectedTimeSlot != undefined){   
                console.log(`Creating payment request with authSetupRequest ${authPayInfo}`);
                
                let tokenInformation: Ptsv2TokenInformation | undefined;
                let paymentInformation: Ptsv2PaymentInformation | undefined;

                if(authPayInfo?.flexTokenInformation){
                    tokenInformation = {
                        jti: authPayInfo.flexTokenInformation.jti
                    };
                }
                else if(authPayInfo?.paymentInformation){
                    paymentInformation = authPayInfo.paymentInformation;
                }
                else{
                    throw new Error("Payment error. Please try again. ERR:CRPACT");
                }
                let request: IMopCheckoutRequest = {
                    basket: appState.basket.basket,
                    email: appState.basket.email,
                    tableNumber: appState.basket.tableNumber?.toString() || "",
                    nickname: appState.basket.nickname?.toString() || "",
                    timeslot: appState.location?.selectedTimeSlot,
                    transactionType: cardSummary.transactionType,
                    channelIdentifier: appState.location.channelIdentifier,
                    createPaymentRequest: {
                        processingInformation,
                        consumerAuthenticationInformation,
                        tokenInformation,
                        paymentInformation
                    },
                    cardInformation: cardSummary.cardDetails
                }

                let affiliation = `${appState.location?.selectedStore?.storeName || ""} ${appState.location?.selectedStore?.storeNumber || ""}`;
                try{
                    return new Promise<IMopCheckoutResponse>((resolve, reject) => {

                        CreatePaymentRequest(request, affiliation, appState, ((err, _checkoutResponse) => {
                            if(err){
                                reject("Payment request error, please try again or use a different payment method. ERR:CREPAYERR");
                                //throw new Error(err);
                            }
                            else if(_checkoutResponse){
                                setCheckoutResponse(_checkoutResponse);
                                resolve(_checkoutResponse);
                            }   
                            else{
                                reject("Payment request error, please try again or use a different payment method.. ERR:CREPAYE");
                            }
                        }));
                    })
                }
                catch(err){
                    throw err;
                }
            }
            else{
                console.log("Could not process payment request. Basket undefined?", appState.basket == undefined, "TimeSlot undefined?", appState.location?.selectedTimeSlot == undefined);
                throw new Error("Payment request error, please try again or use a different payment method. ERR:ASU");
            }
        }
        catch(e){
            let result: string | undefined;
            if (typeof e === "string") {
                result = e;
            } else if (e instanceof Error) {
                result = e.message // works, `e` narrowed to Error
            }
            throw new Error(result ?? "Payment request error, please try again or use a different payment method. ERR:CREPAY");
        }
    }

    useEffect(() => {

        window.hideStepUpScreen = function(transactionId: string){
            if(stepupFormRef.current){
                stepupFormRef.current.style.display = "none";
            }
            else{
                console.log("Cannot hide stepUpForm as ref is undefined.");
            }

            if(initialTransactionInformation){
                
                    CreatePayment(initialTransactionInformation.authPayInfo, initialTransactionInformation.cardSummary,
                    {
                        actionList: ["VALIDATE_CONSUMER_AUTHENTICATION"],
                        ...initialTransactionInformation.processingInformation
                    },
                    {
                        authenticationTransactionId: transactionId
                    }).catch((err) => {
                        handleError(err);    
                    })
            }
            else{
                console.log("No card token information, window.hideStepUpScreen, or cardType");
                onError("Payment request error, please try again or use a different payment method. ERR:CTPISU");
            }
        }

        return () => {
            window.hideStepUpScreen = undefined;
        }

    }, [initialTransactionInformation, stepupFormRef]);

    useEffect(() => {       

        window.listenToAuthentication = (event: MessageEvent) => {
            // {MessageType: "profile.completed", Session Id: "0_57f063fd-659a- 
            // 4779-b45b-9e456fdb7935", Status: true} 
            
            // console.log("EVENT:", event);
            if (event.origin === "https://centinelapistag.cardinalcommerce.com" || event.origin === "https://centinelapi.cardinalcommerce.com") { 
                let data = JSON.parse(event.data); 
                //console.log('-----Merchant received a message:', data); 
                if (data !== undefined && data.Status) { 
                    //console.log(event);
                    console.log('Songbird ran DF successfully'); 
                    if(initialTransactionInformation && payerAuthSetupResponse){
                        try{
                            CreatePayment(initialTransactionInformation.authPayInfo, initialTransactionInformation.cardSummary,
                            {
                                actionList: ["CONSUMER_AUTHENTICATION"],
                                ...initialTransactionInformation.processingInformation
                            },
                            {
                                referenceId: payerAuthSetupResponse.referenceId,
                                returnUrl: window.location.origin + baseUrl() + "/callback"
                            }).catch((err) => {
                                handleError(err);    
                            })
                        }
                        catch(err){
                            handleError(err);
                        }
                    }
                    else{
                        console.log("No card token information or payerAuthSetupResponse or cardType, songbird DF");
                        onError("Payment request error, please try again or use a different payment method. ERR:CTPISB");
                    }
                } 
            } 
        } 

        return () => {
            window.listenToAuthentication = undefined;
        }
    },[initialTransactionInformation, payerAuthSetupResponse]);

    useEffect(() => {
        if(payerAuthSetupResponse){
            if(payerAuthSetupFormRef.current){
                payerAuthSetupFormRef.current.submit();
            }
            else{
                console.log("payerAuthSetupFormRef.current is undefined.")
                onError("Payment request error, please try again or use a different payment method. ERR:PASFR");
            }            
        }
    }, [payerAuthSetupResponse]);

    useEffect(() => {
        if(checkoutResponse && paymentResponse){
            if(paymentResponse.status == "PENDING_AUTHENTICATION"){
                //Requires step up form
                if(stepupFormRef.current){
                    
                    stepupFormRef.current.submit();
                    stepupFormRef.current.style.display = "unset";
                }
                else{
                    console.log("stepUpFormRef.current undefined");
                    onError("Payment request error, please try again or use a different payment method. ERR:SUFU");
                }                
            }
            else if(paymentResponse.status == "AUTHENTICATION_SUCCESSFUL"){
                //Authentication was successful, validate result.
                if(initialTransactionInformation){     
                    try{
                        CreatePayment(initialTransactionInformation.authPayInfo, initialTransactionInformation.cardSummary, {
                            actionList: ["VALIDATE_CONSUMER_AUTHENTICATION"],
                            ...initialTransactionInformation.processingInformation
                        },
                        {
                            authenticationTransactionId: paymentResponse.consumerAuthenticationInformation?.authenticationTransactionId
                        }).catch((err) => {
                            handleError(err);    
                        })
                    }
                    catch(err){
                        handleError(err);
                    }
                } else{
                    console.log("No card token information", initialTransactionInformation == undefined, "or currentCardType");
                    onError("Payment request error, please try again or use a different payment method. ERR:CTPI");
                }
            }
            else if(paymentResponse.status == "AUTHORIZED"){
                onPaymentSuccess(checkoutResponse);
            }
            else{
                if(paymentResponse.errorInformation){
                    onError(paymentResponse.errorInformation.message)
                } else{
                    onError(`Encountered an unexpected payment error. Status: ${paymentResponse.status}`);
                }                
            }
        }
    },[checkoutResponse]);

    const payerAuthForm = useMemo(() => {
        if(payerAuthSetupResponse){
            return(
                <>
                    <iframe key={(Math.random() * 1000).toString() + payerAuthSetupResponse.deviceDataCollectionUrl} name="ddc-iframe" height="1" width="1" style={{display: "none"}}></iframe>  
                    <form ref={payerAuthSetupFormRef} id="ddc-form" target="ddc-iframe" method="POST" action={payerAuthSetupResponse.deviceDataCollectionUrl}>
                        <input type="hidden" name="JWT" value={payerAuthSetupResponse.accessToken}/>
                    </form> 
                </>
            )
        }
    }, [payerAuthSetupResponse])

    const stepUpForm = useMemo(() => {
        if(paymentResponse?.consumerAuthenticationInformation?.accessToken && paymentResponse.consumerAuthenticationInformation.stepUpUrl){
            return (
                <>
                    <iframe key={(Math.random() * 1000).toString() + paymentResponse.consumerAuthenticationInformation.stepUpUrl} className="stepup__frame" name="step-up-iframe"></iframe> 
                    <form ref={stepupFormRef} id="step-up-form" target="step-up-iframe" method="post" action={paymentResponse.consumerAuthenticationInformation.stepUpUrl}>  
                        <input type="hidden" name="JWT" value={paymentResponse.consumerAuthenticationInformation.accessToken} />  
                    </form> 
                </>
            )
        }
    }, [paymentResponse]);

    useImperativeHandle(ref, () => {
        return {
            Authenticate,
            CreatePayment
        }
    })

    return(
        <> {payerAuthForm} {stepUpForm} </>
    )
/*    
    return [
        <> {payerAuthForm} {stepUpForm} </>,
        Authenticate,
        CreatePayment
    ] as const
*/
})

export default PayerAuthentication;