import React from 'react';
import { IonHeader, IonTitle, IonIcon, IonModal, IonContent } from '@ionic/react';
import './MopHeaderMenusModal.scss';
import { closeOutline } from 'ionicons/icons';
import { useHistory } from "react-router-dom";
import '../theme/common.scss';

interface AllergenModalProps {
}
const AllergenModal: React.FC<{ isModalOpen: boolean, closeModalClick: any }> = ({ isModalOpen, closeModalClick }) => {
    return (
        <IonModal isOpen={isModalOpen}
            onDidDismiss={closeModalClick}
            cssClass='AppHeaderBurgerMenu fix-that-header'
            swipeToClose={true}>
            <IonHeader className="ion-no-border modal-header" onClick={closeModalClick}>
                <IonIcon className="CloseButton" color={"primary"} icon={closeOutline} ></IonIcon>
                <IonTitle color={"primary"}>Close</IonTitle>
            </IonHeader>
            <IonContent>
                <div className="TermsCondInner" style={{paddingBottom: "100px"}}>
                    <h4>Important Allergen &amp; Product  Information*</h4>
                    <p>For allergen information please click the link on each page. Please bear in mind that we work in a busy kitchen and it is possible that, due to the way we prepare food, other allergens may be present.</p>
                    <p>Vegan Recipe: Although the recipe for these products are suitable for vegans, they are prepared in an area where food that is not suitable for vegans is also handled.</p>
                    <p>Our fish may contain bones.</p>
                </div>
            </IonContent>
        </IonModal>
    );
};

export default AllergenModal;
