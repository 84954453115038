import React, { useState } from 'react';
import { IonItem, IonLabel, IonCheckbox, IonCol, IonGrid, IonRow, IonItemGroup, } from '@ionic/react';
import './ProductMultiSelect.scss';
import QuantityInput from './QuantityInput';

interface ProductMultiSelect {
    productMultiSelectName: string;
    isSelected: boolean;
    showQuantityComponent: boolean;
    menuItem: IMopModifierGroupItem;
    disableCheckbox?: boolean;
    disableAddQuantityButton?: boolean;
    onClick: (menuItem: IMopModifierGroupItem, quantity: number) => void;
}
//menuItem.max
const ProductExtra: React.FC<ProductMultiSelect> = ({ productMultiSelectName, onClick, isSelected, menuItem, showQuantityComponent, disableCheckbox, disableAddQuantityButton}) => {
    //menuItem.kcal = 1234;
    return (
        <IonGrid className="ProductMultiSelectItem ion-no-padding">
            <IonRow>            
                <IonCol size={showQuantityComponent ? "8" : "12"} className="ion-no-padding">
                    <IonItem lines="none" >        
                        <IonLabel item-right>{productMultiSelectName}{(menuItem.kcal != undefined && menuItem.kcal >= 0) && <span className='modifierGroupItemKcal'>({menuItem.kcal}kcal)</span>}</IonLabel>
                        <IonCheckbox disabled={disableCheckbox} item-left slot="start" value="val1" checked={isSelected} onIonChange={(e) => { onClick(menuItem, isSelected ? 0 : 1); } }/>
                    </IonItem>           
                </IonCol>
                {
                    showQuantityComponent &&
                    <IonCol size="4" className="ion-no-padding">
                        <QuantityInput
                            value={menuItem.quantitySelected}
                            disableAddQuantityButton={disableAddQuantityButton}
                            subtractButtonColour="grey"
                            addButtonColour="green"
                            onChange={(quantity) => { onClick(menuItem, quantity ); } }
                        />
                    </IonCol>
                }    
            </IonRow> 
        </IonGrid>
  );
};

export default ProductExtra;
