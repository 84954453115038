import React, { useState, useEffect } from 'react';
import { IonRow, IonCol,  IonText, IonImg,IonTitle, IonIcon } from '@ionic/react';
import './MenuItemRow.scss';
import { formatCurrency } from '../helpers/currencyHelpers';
import { baseUrl, cacheBust, getConfig } from '../helpers/configHelper';
import { MenuDisplayType, PriceLocations } from '../store/MenuStoreTypes';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import { FormikProvider } from 'formik';

interface MenuItemTileProps {
    menuItemId: number;
    sku: string;
    price?: string;
    fromPrice?: boolean;
    itemTags: string;
    inStock: boolean;
    name: string;
    priceLocation?: PriceLocations,
    fullWidth?: boolean
    onClick?: (menuItemId: number) => void;
    kcal?: number
    onAddClick?: (menuItemId: number) => void;
	plpRoundels?: string[];
}

const MenuItemTile: React.FC<MenuItemTileProps> = ({name, menuItemId, fromPrice,price,onClick,onAddClick,sku,itemTags,inStock, priceLocation, fullWidth, kcal, plpRoundels}) => {
    const headerState = useSelector((state: ApplicationState) => state.headerFooter);
    const [productPromos, setProductPromos] = useState<string[]>()

    useEffect(() => {
        setProductPromos(plpRoundels)
    }, [plpRoundels])

    return (
        <>
            <IonCol className={(!inStock ? "OutOfStock" : "")} size={fullWidth ? "12" : "6"} onClick={() => inStock  && onClick && onClick(menuItemId)}>
                <div className={"MenuItem-Tile__container " + (onAddClick ? "withAddButton" : "")} >
                    <div className="MenuItem-Tile__image_container">
                        {
                            (itemTags && itemTags.length > 0) &&
                            <div className="MenuItemTile-icon__container">
                                {itemTags?.includes("[VG]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/vegan-icon.png`} alt="Vegan" />}
                                {itemTags?.includes("[VE]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/v-icon.png`} alt="Vegetarian" />}
                                {itemTags?.includes("[U400]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/under400.png`} style={{ width: "49px" }}  alt="Under 400 calories" />}
                                {itemTags?.includes("[U600]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/under600.png`} style={{ width: "49px" }}  alt="Under 600 calories" />}
                                {itemTags?.includes("[MMI]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/MorrisonsMakesIt.png`} alt="Morrisons makes it" />}
                                {itemTags?.includes("[MF]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/icon_Meat%20Free.png`} alt="Meat free" />}
                                {itemTags?.includes("[NEW]") && <IonImg className="MenuItemIcon" src={`${baseUrl()}/images/assets/new-icon.png`} style={{ width: "44px" }} alt="New Icon" />}
                            </div> 
                        }
						<IonCol>
							<IonImg alt={name} className="MenuItemImage" src={(`${baseUrl()}/images/ProductShots/${sku}_PLP${fullWidth ? "_FW" : ""}.jpg`) + `?v=${cacheBust()}`} />
							{productPromos && productPromos?.length > 0 && <IonImg className="MenuItemImageProductPromo" src={`${baseUrl()}/images/ProductPromos/${productPromos[0]}`} /> }
							{productPromos && productPromos?.length > 1 && <IonImg className="MenuItemImageProductPromo" src={`${baseUrl()}/images/ProductPromos/${productPromos[1]}`} />}
						</IonCol>
                    </div>
                    <div className="detail-container">
                        <div className="item-name">{name}</div>

						<div className="detail-inner-container">
							{kcal!=null && <div className="item-kcal">{kcal}kcal</div>}
							<div className="item-price">{ fromPrice && <div className="item-from">from</div> }<div>{price}</div></div>
						</div>
                    </div>

                    <div className="addButton" onClick={() => { inStock && onAddClick && inStock && onAddClick(menuItemId) }}>Add</div>

                </div>
                {
                    headerState?.showDebugLabel &&
                    <div className="miDebugLabel">
                        {sku} {name}
                    </div>
                }
            </IonCol>        
        </>
  );
};

export default MenuItemTile;
