import { IonGrid, IonIcon, IonItem, IonList, IonRadioGroup } from "@ionic/react";
import { chevronDownOutline, chevronUpOutline, menu } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SlideDown from "react-slidedown";
import { bindActionCreators } from "redux";
import { ApplicationState } from "../store";
import ProductSingleSelect from "./ProductSingleSelect";
import * as menuItemStore from "../store/MenuItemStore";

interface AuxJsonModifierFiltersProps {
    auxFilterGroup: AuxJsonFilterGroup
    detailsPageSettings?: DetailsPageSettings
    modifierIndex: number
    auxFilterIndex: number
    isDropdownOpen: boolean;
    onToggleDropdown: (v: boolean, id: string) => void;
    onSelectedSingle: (id: string) => void;
    localId: string;
}

const AuxJsonModifierFilters: React.FC<AuxJsonModifierFiltersProps> = ({ auxFilterGroup, modifierIndex, detailsPageSettings, auxFilterIndex,  isDropdownOpen, onToggleDropdown, onSelectedSingle, localId }) => {

    /*


    */

    const isBasket = useSelector((state: ApplicationState) => state.menuItemData?.isBasket);
    const menuItem = useSelector((state: ApplicationState) => state.menuItemData?.menuItem);
    const updateAuxFilterItemQuantity = bindActionCreators(menuItemStore.actionCreators.updateAuxFilterItemQuantity, useDispatch());
    const calculateAuxFilters = bindActionCreators(menuItemStore.actionCreators.calculateAuxFilterItems, useDispatch());
    const [totalQuantitySelected, setTotalQuantitySelected] = useState<number>(0);
    const [chosenItems, setChosenItems] = useState("");
    const [selected, setSelected] = useState("");

    useEffect(() => {
        calculateAuxFilters(modifierIndex);
        if(auxFilterGroup && menuItem){
            //console.log("Modifier Groups:", menuItem.modifierGroups);
            let modGroup: IMopModifierGroup = menuItem.modifierGroups[modifierIndex];
            let selectedModItem: IMopModifierGroupItem | undefined = modGroup.modifierGroupItems.find((mod: IMopModifierGroupItem) => mod.quantitySelected > 0);

            //If no mod item selected, select the first one by default.
            if(!selectedModItem && modGroup.modifierGroupItems.length > 0 && modGroup.auxMenuItemData?.auxJsonFilters?.outcomes && modGroup.auxMenuItemData.auxJsonFilters.outcomes.length > 0){
                selectedModItem = modGroup.modifierGroupItems.find((m: IMopModifierGroupItem) => m.sku == modGroup.auxMenuItemData?.auxJsonFilters?.outcomes[0].sku);
            }
            else{
                console.log("No modifier group items OR No outcomes in this group.");
            }

            if(selectedModItem){                
                let outcome = modGroup.auxMenuItemData?.auxJsonFilters?.outcomes.find((o) => o.sku == selectedModItem?.sku);
                if(outcome){
                    let _selected = auxFilterGroup.selectors.find((s) => outcome?.filters.includes(s.tag ?? s.name));
                    if(_selected){
                        onSelected(_selected.name, true);
                    }
                    else{
                        console.log("Could not find a selector associated with the outcome");
                    }
                }
                else{
                    console.log("Could not find an outcome for the selected modifier item");
                }
            }
            else{
                console.log("Cannot select default modifier group item using first available outcome or group item");
            }
        }
    }, []);
    
    useEffect(() => {
        let total = 0;
        let chosen = "";
        for(let i = 0; i < auxFilterGroup.selectors.length; i++){
            let selector = auxFilterGroup.selectors[i];
            total += selector.quantitySelected;
            if(selector.quantitySelected > 0){
                if(!selector.enabled && selector.name == selected){
                    //If selected but disabled, it needs updating to the nearest available
                    //This occurs when a modifier group item is selected in different group which has no outcomes that match this selected item.
                    let targetSelector = auxFilterGroup.selectors.find((s) => s.enabled);
                    if(targetSelector){
                        setSelected(targetSelector.name);
                    }                    
                }
                else{
                    chosen += chosen == "" ? "" : ", ";
                    chosen += `${selector.quantitySelected > 1 ? selector.quantitySelected + " x " : ""}${selector.name}`;
                }
                
            }
        }

        setChosenItems(chosen);
        setTotalQuantitySelected(total);
    }, [auxFilterGroup])

    useEffect(() => {
        let selectedItem: AuxJsonFilterMenuItem | undefined = auxFilterGroup.selectors.find((item) => item.name == selected);
        let prevItem: AuxJsonFilterMenuItem | undefined = auxFilterGroup.selectors.find((item) => item.quantitySelected == 1);
        if(selectedItem && selectedItem.quantitySelected != 1){
            handleChange(selectedItem, 1);
        }
        if(prevItem){
            handleChange(prevItem, 0);
        }
        calculateAuxFilters(modifierIndex);
    }, [selected]);

    const onSelected = (name: string, ignoreSelectedEvent?: boolean) => {
        //onSelectedSingle will cause the next dropdown to open, We ignore when setting defaults.
        if(!ignoreSelectedEvent){
            onSelectedSingle(localId);
        }
        setSelected(name);
    }

    const handleChange = (item: AuxJsonFilterMenuItem, quantity: number) => {
        let selectorIndex = auxFilterGroup.selectors.findIndex((s) => s.name == item.name);

        console.log("Updated", item.name, "quantity to", quantity);
        updateAuxFilterItemQuantity(quantity, modifierIndex, auxFilterIndex, selectorIndex);
    }

    return(
        <IonGrid className="ion-no-padding modifierGroup">
            <IonItem className="ModifierGroup-Dropdown__item" lines="none" onClick={() => { onToggleDropdown(!isDropdownOpen, localId); }}>
                <div style={{
                    backgroundColor: detailsPageSettings?.dropdownBackgroundColour ? detailsPageSettings.dropdownBackgroundColour : "inherit",
                    color: detailsPageSettings?.dropdownFontColour ? detailsPageSettings.dropdownFontColour : "inherit"
                    }} className={`ModifierGroup-Dropdown__header ${totalQuantitySelected > 0 ? "selected" : ""}`}>
                    <div className="Dropdown-Label__container">
                    {
                        totalQuantitySelected > 0
                        ?
                            <div className="Dropdown-Label__selected">
                                <div className="Dropdown-Selected__title">{auxFilterGroup.groupTitle}: Click to change</div>
                                <div className="Dropdown-Selected__choice">{chosenItems}</div>
                            </div>
                        :
                            <div className="Dropdown-Label__default">Choose your {auxFilterGroup.groupTitle}</div>
                    }
                    </div>
                    {
                        isDropdownOpen 
                        ?
                            <IonIcon icon={chevronUpOutline} />
                        :
                            <IonIcon icon={chevronDownOutline}/>
                    }
                </div>
            </IonItem>
            <SlideDown closed={!isDropdownOpen}>
                <IonList lines="none">
                    <IonRadioGroup className="AuxJsonFilterRadioGroup" value={selected} onIonChange={e => { console.log("Selected ionChange"); onSelected(e.detail.value) }}>
                        {
                            auxFilterGroup.selectors.map((item) => {
                                return(
                                        <ProductSingleSelect debugLabel={`${item.name} ${item.tag ?? ""}`} price={item.price} isFromPrice={item.from} disabled={!item.enabled} productSingleSelectName={item.name}/>
                                )
                            })
                        }
                    </IonRadioGroup>
                </IonList>
            </SlideDown>
        </IonGrid>
    )
}

export default AuxJsonModifierFilters;