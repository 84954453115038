import React, { useEffect, useState } from 'react';
import { IonContent, IonAlert, IonHeader, IonButton, IonPage, IonTitle, IonToolbar, IonImg } from '@ionic/react';
import { useHistory, useLocation } from "react-router-dom";
import './BasketPage.scss';


import { closeCircle } from 'ionicons/icons';
import AppHeader from '../components/AppHeader';
import { formatCurrency } from '../helpers/currencyHelpers';
import BasketItemRow from '../components/BasketItemRow';
import * as headerFooterStore from '../store/HeaderFooterStore';
import { ApplicationState } from '../store';
import { useSelector } from 'react-redux';
import * as currencyHelpers from '../helpers/currencyHelpers';
import { getConfig } from '../helpers/configHelper';
import MopFooterCheckout from '../components/MopFooterCheckout';

import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as BasketStore from '../store/BasketStore';


const InfoPage: React.FC = () => {
    let history = useHistory();
    let location = useLocation();

    const locationState = useSelector((state: ApplicationState) => state.location);
    const basketStore = useSelector((state: ApplicationState) => state.basket);
    const [showAlert, setShowAlert] = useState(false);
    const boundClearBasket = bindActionCreators(BasketStore.actionCreators.clearBasket, useDispatch());
    const boundGetUpsells = bindActionCreators(BasketStore.actionCreators.getUpsells, useDispatch());
    let price: string = currencyHelpers.formatCurrency(basketStore?.basket.totalPrice);

    useEffect(() => {
        !basketStore?.upsellDone && boundGetUpsells();
    }, []);

    return (
        <IonPage >

            <IonContent fullscreen class="BasketPage">
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => { setShowAlert(false) }}
                    header={"Cancel Order"}
                    buttons={[
                        {
                            text: "No, don't cancel",
                            role: "cancel"
                        },
                        {
                            text: "Yes, cancel Order",
                            handler: () => {
                                boundClearBasket();
                            }
                        }
                    ]}
                    message={"Are you sure you want to cancel your order?"}
                />

                <IonContent className="ModalContent">
                    {
                        basketStore && basketStore.basket.isColleagueDiscountBasket && basketStore.basket.externalPromotionsApplied &&
                        <div className="MopBasketNotificationWrapper">
                            <div className="MopBasketNotification">Note: Your colleague discount will be recalculated at checkout</div>
                        </div>
                    }
                    {basketStore &&
                        basketStore.basket.menuItems.map((basketItem: IMopMenuItemDetail, index: number) => {
                            return <BasketItemRow key={"basket_" + basketItem.basketMenuItemId} basketItem={basketItem} readOnly={false}></BasketItemRow>
                        })}
                </IonContent>

            </IonContent>
        </IonPage>
    );
};

export default InfoPage;
