import './Location.scss';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LocationStore from '../store/LocationStore';
import { locationSharp, qrCode } from 'ionicons/icons';
import {   IonContent ,IonPage, IonButton, IonIcon} from '@ionic/react';
import { getConfig } from '../helpers/configHelper';
//import AppHeader from '../components/AppHeader';

type LocationProps =
    LocationStore.LocationState &
    typeof LocationStore.actionCreators &
    RouteComponentProps;

class Location extends React.Component<LocationProps> {

    constructor(props: any) {
        super(props);
        this.geoCallback = this.geoCallback.bind(this);
    }

    componentDidMount() {
        //this.props.requestClientGeoLocation(this.geoCallback);
    }

    geoCallback(latitude: number, longitude: number, accuracy: number, isOk: boolean) {
        if (isOk) {
            console.debug(`Location.geoCallback: latitude=${latitude}, longitude=${longitude}, accuracy=${accuracy}`);
        }
        else {
            console.error("Location.geoCallback: requestClientGeoLocation failed.");
        }
        this.props.history.push(`/storesearch`);
    }

    findAStore() {
        if (Number.isNaN(this.props.clientLatitude))
            this.props.requestClientGeoLocation(this.geoCallback);
        else
            this.props.history.push(`/storesearch`);
    }

    ScanAQRCode() {
        this.props.history.push(`/scanqrcode`);
    }

    render() {
        return <IonPage>
            <IonContent fullscreen>
                <div className="LocationWrapper">
                    <div className="textWrapper">
                        <div className="YourLocation">Your location</div>
                        <div className="LocationTitle">Use the options below to find your nearest {getConfig().isMarket ? "Market Kitchen" : "Morrisons Café"}</div>
                        <div className="LocationBody">If you're currently in a store, use your phone's camera to scan the Caf&#233;s QR code to place an order to take away. Alternatively, find your nearest store using the location button and place an order for Click and Collect.</div>
                    </div>
                    <div className="buttonsWrapper">

                        <IonButton disabled={this.props.isBusy} className="TwoLineButton" onClick={(e) => this.findAStore()}>
                            <div className="TwoLineButtonInner">
                                <IonIcon className="PinIcon" icon={locationSharp}></IonIcon>
                                <div className="TwoLineButtonText">
                                    <div className="TwoLineButtonTitleTop">Find a store near you</div>
                                    <div className="TwoLineButtonTitleBottom">For Click & Collect when you're not in a store</div>
                                </div>
                            </div>
                        </IonButton>
                        <IonButton disabled={this.props.isBusy} className="TwoLineButton" onClick={(e) => this.ScanAQRCode()}>
                            <div className="TwoLineButtonInner">
                                <IonIcon className="PinIcon" icon={qrCode}></IonIcon>
                                <div className="TwoLineButtonText">
                                    <div className="TwoLineButtonTitleTop">Scan a QR Code</div>
                                    <div className="TwoLineButtonTitleBottom">If you're in a store, see our posters near the caf&#233;s</div>                                   
                                </div>
                            </div>
                        
                        </IonButton>
                    </div>
                </div>
            </IonContent>
        </IonPage>;
    }

}

export default connect(
    (state: ApplicationState) => state.location, // Selects which state properties are merged into the component's props
    LocationStore.actionCreators // Selects which action creators are merged into the component's props
)(Location as any); // eslint-disable-line @typescript-eslint/no-explicit-any