import { Action, Reducer } from 'redux';
import { batch } from 'react-redux';
import { AppThunkAction } from './';
import update from 'immutability-helper';
import { baseUrl } from '../helpers/configHelper';
import { Dispatch } from 'react';
import { calculateMenuItemTotalPrice } from '../helpers/menuHelpers';
import _ from "lodash";
import { ILoginError, loginHasExpired } from '../helpers/apiHelpers';
import { resetMessage, ResetMessageAction } from './ColleagueStore';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface MenuItemDataState {
    isLoading: boolean;
    isBasket: boolean;
    menuItemId: number | null;
    menuItem: IMopMenuItemDetail | null;
}

const auxMenuDatatemp: AuxMenuItemData = {
    "detailsPageSettings": {
      "dropdownBackgroundColour": "#172a2e",
      "dropdownFontColour": "#fff"
    },
    "mustSelectTagOverrides": [
      {
        "mustSelectTagValue": 1,
        "auxJsonFilters": {
          "groups": [
            {
              "groupTitle": "Base",
              "selectors": [
                {
                  "name": "Hot Whole Chicken",
                  "enabled": true,
                  "price": 0,
                  "quantitySelected": 0
                },
                {
                  "name": "Cold Whole Chicken",
                  "price": 0,
                  "enabled": true,
                  "quantitySelected": 0
                },
                {
                  "name": "Half Chicken",
                  "price": 0,
                  "enabled": true,
                  "quantitySelected": 0
                },
                {
                  "name": "Chicken Leg",
                  "price": 0,
                  "enabled": true,
                  "quantitySelected": 0
                },
                {
                  "name": "Chicken Drum",
                  "price": 0,
                  "enabled": true,
                  "quantitySelected": 0
                }
              ]
            },
            {
              "groupTitle": "Flavour",
              "selectors": [
                {
                  "name": "Peri Peri",
                  "price": 0,
                  "enabled": true,
                  "quantitySelected": 0
                },
                {
                  "name": "Plain",
                  "price": 0,
                  "enabled": true,
                  "quantitySelected": 0
                },
                {
                  "name": "Thai",
                  "price": 0,
                  "enabled": true,
                  "quantitySelected": 0
                },
                {
                  "name": "BBQ",
                  "price": 0,
                  "enabled": true,
                  "quantitySelected": 0
                },
                {
                  "name": "Jerk",
                  "price": 0,
                  "enabled": true,
                  "quantitySelected": 0
                },
                {
                  "name": "Garlic, Lemon and Herb",
                  "price": 0,
                  "enabled": true,
                  "quantitySelected": 0
                },
                {
                  "name": "Masala",
                  "price": 0,
                  "enabled": true,
                  "quantitySelected": 0
                }
              ]
            }
          ],
          "outcomes": [
            {
              "filters": [
                "Whole Chicken",
                "Plain"
              ],
              "sku": "111551622"
            },
            {
              "filters": [
                "Whole Chicken",
                "BBQ"
              ],
              "sku": "111551649"
            },
            {
              "filters": [
                "Whole Chicken",
                "Thai"
              ],
              "sku": "111551665"
            },
            {
              "filters": [
                "Whole Chicken",
                "Peri Peri"
              ],
              "sku": "111551681"
            },
            {
              "filters": [
                "Whole Chicken",
                "Garlic, Lemon and Herb"
              ],
              "sku": "111551702"
            },
            {
              "filters": [
                "Whole Chicken",
                "Masala"
              ],
              "sku": "111551729"
            },
            {
              "filters": [
                "Whole Chicken",
                "Jerk"
              ],
              "sku": "112044585"
            },
            {
              "filters": [
                "Half Chicken",
                "Plain"
              ],
              "sku": "111551446"
            },
            {
              "filters": [
                "Half Chicken",
                "BBQ"
              ],
              "sku": "111551462"
            },
            {
              "filters": [
                "Half Chicken",
                "Thai"
              ],
              "sku": "111551489"
            },
            {
              "filters": [
                "Half Chicken",
                "Peri Peri"
              ],
              "sku": "111551500"
            },
            {
              "filters": [
                "Half Chicken",
                "Garlic, Lemon and Herb"
              ],
              "sku": "111551526"
            },
            {
              "filters": [
                "Half Chicken",
                "Masala"
              ],
              "sku": "111551542"
            },
            {
              "filters": [
                "Half Chicken",
                "Jerk"
              ],
              "sku": "112044489"
            },
            {
              "filters": [
                "Chicken Leg",
                "Plain"
              ],
              "sku": "111550700"
            },
            {
              "filters": [
                "Chicken Leg",
                "BBQ"
              ],
              "sku": "111550726"
            },
            {
              "filters": [
                "Chicken Leg",
                "Thai"
              ],
              "sku": "111550742"
            },
            {
              "filters": [
                "Chicken Leg",
                "Peri Peri"
              ],
              "sku": "111550769"
            },
            {
              "filters": [
                "Chicken Leg",
                "Garlic, Lemon and Herb"
              ],
              "sku": "111550785"
            },
            {
              "filters": [
                "Chicken Leg",
                "Masala"
              ],
              "sku": "111550806"
            },
            {
              "filters": [
                "Chicken Leg",
                "Jerk"
              ],
              "sku": "112044420"
            },
            {
              "filters": [
                "Chicken Drum",
                "Plain"
              ],
              "sku": "111550822"
            },
            {
              "filters": [
                "Chicken Drum",
                "BBQ"
              ],
              "sku": "111550849"
            },
            {
              "filters": [
                "Chicken Drum",
                "Thai"
              ],
              "sku": "111550865"
            },
            {
              "filters": [
                "Chicken Drum",
                "Peri Peri"
              ],
              "sku": "111555738"
            },
            {
              "filters": [
                "Chicken Drum",
                "Garlic, Lemon and Herb"
              ],
              "sku": "111550881"
            },
            {
              "filters": [
                "Chicken Drum",
                "Masala"
              ],
              "sku": "111550902"
            },
            {
              "filters": [
                "Chicken Drum",
                "Jerk"
              ],
              "sku": "112044340"
            },
            {
              "filters": [
                "Cold Whole Chicken",
                "Plain"
              ],
              "sku": "111597452"
            },
            {
              "filters": [
                "Cold Whole Chicken",
                "BBQ"
              ],
              "sku": "111597479"
            },
            {
              "filters": [
                "Cold Whole Chicken",
                "Thai"
              ],
              "sku": "111597495"
            },
            {
              "filters": [
                "Cold Whole Chicken",
                "Peri Peri"
              ],
              "sku": "111597516"
            },
            {
              "filters": [
                "Cold Whole Chicken",
                "Garlic, Lemon and Herb"
              ],
              "sku": "111597532"
            },
            {
              "filters": [
                "Cold Whole Chicken",
                "Masala"
              ],
              "sku": "111597559"
            },
            {
              "filters": [
                "Cold Whole Chicken",
                "Jerk"
              ],
              "sku": "112063145"
            }
          ]
        }
      },
      {
        "mustSelectTagValue": 0,
        "name": "Sauces"
      },
      {
        "mustSelectTagValue": -1,
        "name": "Sides"
      }
    ]
  }

const friesJson: MustSelectTagOverride = {
    mustSelectTagValue: 0,
    name: "Sides"
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestProductAction {
    type: 'REQUEST_PRODUCT';
    menuItemId: number;
    pfIdentifier: string | undefined;
}

interface ReceiveProductAction {
    type: 'RECEIVE_PRODUCT';
    menuItemId: number;
    menuItem: IMopMenuItemDetail;
}

interface RequestBasketItemAction {
    type: "REQUEST_BASKET_PRODUCT";
    menuItemId: number;
}

interface UpdateModifierGroupItem { 
    type: "UPDATE_MODIFIER_GROUP_ITEM";
    categoryIndex: number;
    menuItemIndex: number;
    quantity: number;
}

interface UpdateModifierGroup {
    type: "UPDATE_MODIFIER_GROUP",
    index: number,
    item: IMopModifierGroup
}

interface UpdateMenuItemQuantity {
    type: "UPDATE_MENU_ITEM_QUANTITY";
    quantity: number;
}

interface UpdateMenuItemTotalPrice {
    type: "UPDATE_MENU_ITEM_TOTAL_PRICE";
    totalPrice: number;
}

interface UpdateAuxFilterItemQuantity {
    type: "UPDATE_AUX_FILTER_ITEM_QUANTITY";
    quantity: number;
    modifierGroupIndex: number;
    filterGroupIndex: number;
    filterSelectorIndex: number;
}

interface CalculateAuxFilterItems {
    type: "CALCULATE_AUX_FILTER_ITEMS"
    modifierGroupIndex: number;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestProductAction | ReceiveProductAction | RequestBasketItemAction | UpdateModifierGroupItem | UpdateMenuItemQuantity | UpdateMenuItemTotalPrice | UpdateAuxFilterItemQuantity | CalculateAuxFilterItems | UpdateModifierGroup | ResetMessageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestMenuItem: (
        menuItemId: number,
        pfIdentifier: string,
        referrer: string | null,
        callback: any,
    ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        let channelIdentifier = appState.location?.channelIdentifier;
        //  if (appState && appState.weatherForecasts && startDateIndex !== appState.weatherForecasts.startDateIndex) {
        fetch(`${baseUrl()}/menudata/${channelIdentifier}/MenuItem/${menuItemId}${pfIdentifier ? "?pfIdentifier=" + pfIdentifier : ""}`)
            .then(response => response.json() as Promise<IMopMenuItemDetail>)
            .then((data: IMopMenuItemDetail | ILoginError) => {
                if (loginHasExpired(data)) {
                    dispatch(resetMessage("showExpireModal"))
                } else {
                    data.quantity = 1;
                    data.referrer = referrer;
                    dispatch({ type: 'RECEIVE_PRODUCT', menuItemId: menuItemId, menuItem: data, });
                    callback && callback();
                }
            });
        dispatch({
            type: 'REQUEST_PRODUCT',
            menuItemId: menuItemId,
            pfIdentifier: pfIdentifier,
        });
        //  }
    },
    requestBasketMenuItem: (basketMenuItemId: number, callback: any): AppThunkAction<KnownAction> => (dispatch: Dispatch<any>, getState) => {
        const appState = getState();
        let basketItem = appState.basket?.basket.menuItems.find(item => item.basketMenuItemId === basketMenuItemId); 

        batch(() => {
            if(basketItem){
                dispatch({ type: 'REQUEST_BASKET_PRODUCT', menuItemId: basketItem.menuItemId });
                dispatch({ type: 'RECEIVE_PRODUCT', menuItem: basketItem, menuItemId: basketItem.menuItemId });
                dispatch(actionCreators.updateMenuItemTotalPrice());
                callback && callback();
            }    
        });      
    },
    updateModifierGroupItem: (menuItemId: number, quantity: number): AppThunkAction<KnownAction> => (dispatch: Dispatch<any>, getState) => {
        const appState = getState();

        let idx = appState.menuItemData?.menuItem?.modifierGroups.flatMap((mod, i) => { //Finds index of modifierGroup and the modifierGroupItem using menuItemId of option
            const j = mod.modifierGroupItems.findIndex((item: IMopModifierGroupItem) => item.menuItemId === menuItemId);
            return j > -1 ? [i, j] : [];
        });

        /*

            Take loop from footer and use it here to set total item price, store this as singularPrice as well, 
            then when update quantity is called just multiply the price using this.
        */
        if(idx && idx.length > 0){
            let modifierGroup = appState.menuItemData?.menuItem?.modifierGroups[idx[0]] as IMopModifierGroup;

            //console.log("Updating group", modifierGroup);
            if(modifierGroup){
                //let menuItemIndex = modifierGroup.modifierGroupItems.findIndex(item => item.menuItemId == menuItemId);

                //console.log("Updating menu item from", modifierGroup.modifierGroupItems[idx[1]]);
                dispatch({ type: "UPDATE_MODIFIER_GROUP_ITEM", categoryIndex: idx[0], menuItemIndex: idx[1], quantity});
                dispatch(actionCreators.updateMenuItemTotalPrice());
            }
        }

    },
    updateMenuItemQuantity: (quantity: number): AppThunkAction<KnownAction> => (dispatch: Dispatch<any>) => {
        dispatch({ type: "UPDATE_MENU_ITEM_QUANTITY", quantity: quantity });
        dispatch(actionCreators.updateMenuItemTotalPrice());
    },
    updateAuxFilterItemQuantity: (quantity: number, modifierGroupIndex: number, filterGroupIndex: number, filterSelectorIndex: number): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: "UPDATE_AUX_FILTER_ITEM_QUANTITY", quantity: quantity, modifierGroupIndex: modifierGroupIndex, filterGroupIndex: filterGroupIndex, filterSelectorIndex: filterSelectorIndex});
    },
    updateMenuItemTotalPrice: () : AppThunkAction<KnownAction> => (dispatch, getState) => {
        //console.log("Updating total price of menu item");
        const appState = getState();

        let _price: number = 0;

        if(appState.menuItemData?.menuItem != null){
            _price = calculateMenuItemTotalPrice(appState.menuItemData.menuItem);
        }

        dispatch({ type: "UPDATE_MENU_ITEM_TOTAL_PRICE", totalPrice: _price });

    },
    calculateAuxFilterItems: (modifierGroupIndex: number) : AppThunkAction<KnownAction> => (dispatch: Dispatch<any>, getState) => {
        console.debug("STARTING calculateAuxFilterItems");
       const appState = getState();
       if(appState.menuItemData?.menuItem){
            let modifierGroups = appState.menuItemData.menuItem.modifierGroups as IMopModifierGroup[];
            let targetModifierGroup: IMopModifierGroup = JSON.parse(JSON.stringify(modifierGroups[modifierGroupIndex]));

            //Grab the current selected filters from each group's selector array.
            //These filters will be used to find a match from the outcomes array.
            let selectedFilters = targetModifierGroup.auxMenuItemData?.auxJsonFilters?.groups.reduce((filterSelection: string[], group) =>{
                const selection = group.selectors.reduce((groupFilters: string[], selector) => {
                    if(selector.quantitySelected == 1){
                        return [...groupFilters, selector.tag ? selector.tag : selector.name];
                    }
                    else{
                        return groupFilters;
                    }
                }, []);
                return [...filterSelection, ...selection];
            }, []);
            //console.log("Tags selected:", selectedFilters);

            let auxJsonFilters = targetModifierGroup.auxMenuItemData?.auxJsonFilters;
            if(auxJsonFilters){
                //Loop through each group, detect if a tag from that group has been selected and then loop through the selectors of the group
                //to calculate the price and whether it's enabled
                for(let i = 0; i < auxJsonFilters?.groups.length; i++){
                    
                    let group = auxJsonFilters.groups[i];
                    //console.group("--Looping through group", group.groupTitle);

                    let selectedFilterInGroup = group.selectors.find((selector) => selectedFilters?.includes(selector.tag ? selector.tag : selector.name));
                    //console.log("selectedFilterInGroup from ", group.groupTitle,":", selectedFilterInGroup);

                    for(let j = 0; j < group.selectors.length; j++){
                        let selector = group.selectors[j];
                        //console.group("--Selector", selector.name);

                        let _availableOutcomes: AuxJsonOutcome[] = [];
                        let _selectedFilters: string[] = JSON.parse(JSON.stringify(selectedFilters));
                        let selectorTag = selector.tag ? selector.tag : selector.name;

                        if(selectedFilterInGroup){
                            let filterInGroupTag = selectedFilterInGroup.tag ? selectedFilterInGroup.tag : selectedFilterInGroup.name;
                            let selectedFilterIndex = selectedFilters?.findIndex((filterSelected) => filterInGroupTag == filterSelected);
                            //console.log("Replacing tag", filterInGroupTag, "at index", selectedFilterIndex);

                            if(selectedFilters != undefined && selectedFilterIndex != undefined && selectedFilterIndex > -1){                                
                                _selectedFilters[selectedFilterIndex] = selectorTag;
                            }
                            else{
                                console.log("Cant find filterSelection or selectedFilterIndex", selectedFilters, selectedFilterIndex);
                            }
                        }
                        else{
                            if(selectedFilters){
                                _selectedFilters?.push(selectorTag);
                            }
                            else{
                                console.log("Cant find selectedFilters or selectedFilterIndex", selectedFilters);
                            }
                        }

                        //console.log("Temp selectedFilters", _selectedFilters);

                        _availableOutcomes = auxJsonFilters?.outcomes.filter((outcome) => {
                            return _selectedFilters.every((q) => outcome.filters.includes(q));
                        })
                        
                        //console.log("Available Outcomes for this array:", _availableOutcomes);

                        if(_availableOutcomes && _availableOutcomes.length > 0){
                            
                            //Find the ModifierGroupItem equivalents of JsonOutcomes
                            let availableModifierItems: IMopModifierGroupItem[] = targetModifierGroup.modifierGroupItems.filter((item: IMopModifierGroupItem) => _availableOutcomes.some((outcome) => outcome.sku == item.sku));
                            availableModifierItems.sort((a, b) => (a.price ?? 0) - (b.price ?? 0));

                            let samePrice = availableModifierItems.every((item) => availableModifierItems[0].price == item.price);
                            selector.from = !samePrice;

                            /*
                                Chicken Selected - £6
                                Plain Selected - £0

                                selector = Peri peri

                            */
                            let currentPrice: number = auxJsonFilters.groups.reduce((price: number, _group, index) => {
                                //console.group("Price for group", _group.groupTitle, price);
                                const selectionPrice = _group.selectors.reduce((groupPrice: number, _selector) => {
                                    //console.group("Price for selector", _selector.name, groupPrice);
                                    //console.groupEnd();
                                    if(group.groupTitle == _group.groupTitle){
                                        //console.log("selectors group is in reducers group, returning", groupPrice);
                                        return groupPrice
                                    }   
                                    else{
                                        //console.log("returning", (_selector.quantitySelected > 0) ? _selector.price + groupPrice : groupPrice);
                                        return (_selector.quantitySelected > 0) ? _selector.price + groupPrice : groupPrice;
                                    }                                   
                                }, 0);
                               // console.groupEnd();

                                return selectionPrice + price;
                            }, 0);

                            //console.log("currentPrice", currentPrice);

                            if(availableModifierItems.length == 0){
                              console.log("Cannot find matching SKU's for outcomes", _availableOutcomes, "from the items", targetModifierGroup.modifierGroupItems);
                            }
                            else{
                              selector.price = (availableModifierItems[0].price ?? 0) - (i == 0 ? 0 : currentPrice);
                              selector.enabled = true;
                            }                            
                        }
                        else{
                            selector.enabled = false;
                            selector.price = 0;
                        }

                        if(i == 0){ //if the first group
                            //selector.enabled = true;
                        }
                        else if((!selectedFilters || selectedFilters.length == 0)){
                            //Selected is not in the first group and there's no filters selected
                            selector.price = 0;
                            selector.enabled = false;
                        }

                        //console.groupEnd();
                    }

                    //console.groupEnd();
                }

                let outcomeMatches = auxJsonFilters.outcomes.filter((outcome) => { 
                    if(selectedFilters){
                        return _.isEqual(outcome.filters.sort(), selectedFilters.sort());                        
                    }
                    else{
                        return false;
                    }
                })
                
                if(outcomeMatches.length > 0){
                    //console.log("Outcome Matches found", outcomeMatches);

                    let prevSelectedModifier: number | undefined = targetModifierGroup.modifierGroupItems.findIndex((item: IMopModifierGroupItem) => item.quantitySelected == 1);
                    if(prevSelectedModifier > -1){
                        targetModifierGroup.modifierGroupItems[prevSelectedModifier].quantitySelected = 0;
                    }

                    let modifierMatches: IMopModifierGroupItem[] | undefined = targetModifierGroup.modifierGroupItems.filter((item: IMopModifierGroupItem) => outcomeMatches.some((outcome) => outcome.sku == item.sku));
                    //console.log("ModifierMatches found", modifierMatches);
                    if(modifierMatches){
                        modifierMatches.forEach((modifier) =>{
                            let modIndex = targetModifierGroup.modifierGroupItems.findIndex((item: IMopModifierGroupItem) => item.sku == modifier.sku);
                            targetModifierGroup.modifierGroupItems[modIndex].quantitySelected = 1;
                        })
                    }
                }

                //console.debug("DISPATCHING UPDATE_MODIFIER_GROUP");
                dispatch({type: "UPDATE_MODIFIER_GROUP", index: modifierGroupIndex, item: targetModifierGroup});
                dispatch(actionCreators.updateMenuItemTotalPrice());

            }
            else{
                console.log("No auxJsonFilters");
            }
            
       }   
       else{
           console.error("No menuItem found in state");
       }

       
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: MenuItemDataState = { menuItemId: null, menuItem: null, isLoading: false, isBasket: false };

export const reducer: Reducer<MenuItemDataState> = (state: MenuItemDataState | undefined, incomingAction: Action): MenuItemDataState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PRODUCT':
            return {
                ...state,
                menuItemId: action.menuItemId,
                isBasket: false,
                isLoading: true
            };
        case 'REQUEST_BASKET_PRODUCT':
            console.log("Setting basket item to update with " + action.menuItemId);
            return {
                ...state,
                menuItemId: action.menuItemId,
                isBasket: true
            }
        case 'RECEIVE_PRODUCT':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.menuItemId === state.menuItemId) {

                console.log("Loading PDP with ", action.menuItem);
                return {
                    ...state,
                    menuItemId: action.menuItemId,
                    menuItem: {
                        ...action.menuItem,
                        menuItemSinglePrice: action.menuItem.menuItemBasePrice,
                        menuItemTotalPrice: action.menuItem.menuItemBasePrice
                    },
                    isLoading: false
                };
            }
            break;
        case 'UPDATE_MODIFIER_GROUP_ITEM':
            //console.log("Updating menu item modifier group to ", action.quantity);
            if(state.menuItem != null){
                //state.menuItem.modifierGroups[categoryIndex].modifierGroupItems[menuItemIndex].quantity
                //state.first.second[someId].fouth
                return update(state, {
                    menuItem: {
                        modifierGroups: {
                            [action.categoryIndex]: {
                                modifierGroupItems: {
                                    [action.menuItemIndex]: {
                                        $merge:{
                                            quantitySelected: action.quantity,
                                            defaultQuantitySelected: 0
                                        }                                        
                                    }
                                }
                            }
                        }
                    }
                })
            }     
            break;  
        case 'UPDATE_AUX_FILTER_ITEM_QUANTITY':
            console.log(state.menuItem?.modifierGroups[0]);
            return update(state, {
                menuItem: {
                    modifierGroups: {
                        [action.modifierGroupIndex]: {
                            auxMenuItemData: {
                                auxJsonFilters: {
                                    groups: {
                                        [action.filterGroupIndex]: {
                                            selectors: {
                                                [action.filterSelectorIndex]: {
                                                    $merge:{ 
                                                        quantitySelected: action.quantity
                                                    }                                                    
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            })
        case 'UPDATE_MENU_ITEM_QUANTITY':
            console.log("Updating menu item quantity to ", action.quantity);
            if(state.menuItem != null){
                return {
                    ...state,
                    menuItem: {
                        ...state.menuItem,
                        quantity: action.quantity
                    },
                };
            }
            break;
        case 'UPDATE_MODIFIER_GROUP':
            return update(state, {
                menuItem: {
                    modifierGroups: {
                        [action.index]: {
                            $merge: {
                                ...action.item
                            }                            
                        }
                    }
                }
            })
        case "UPDATE_MENU_ITEM_TOTAL_PRICE":
            console.log("Updating total price to £", action.totalPrice);
            if(state.menuItem != null){
                return {
                    ...state,
                    menuItem:{
                        ...state.menuItem,
                        menuItemSinglePrice: action.totalPrice,
                        menuItemTotalPrice: action.totalPrice * state.menuItem.quantity
                    }
                }
            }
    }
    return state;
};
