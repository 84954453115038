import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import {
    IonApp,
    IonIcon,
    IonLabel,
    IonFooter,
    IonToolbar,
    IonTitle,
    IonToast,
    IonRouterOutlet,
    IonContent,
    IonTabBar,
    IonHeader,
    IonTabButton,
    IonTabs,
    IonAlert
} from '@ionic/react';

import { checkmarkCircleOutline } from "ionicons/icons";
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, triangle, home } from 'ionicons/icons';
import Categories from './pages/Categories';
import EnterEmail from './pages/EnterEmail';
import OrderConfirmation from './pages/OrderConfirmation';
import MenuItem from './pages/MenuItem';
import Location from './pages/Location';
import BasketPage from './pages/BasketPage';
import UpsellPage from './pages/UpsellPage';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';
import MopFooter from './components/MopFooter';
import AppHeader from './components/AppHeader';
import CheckoutTestPage from './pages/CheckoutTestPage';
import CouponsTestPage from './pages/CouponsTestPage';
import PaymentSuccess from './pages/PaymentSuccess';
import HistoryListener from './components/HistoryListener';
import LocationListener from './components/LocationListener';
import TimeSlotPage from './pages/TimeSlotPage';
import { createBrowserHistory } from 'history';
import StoreSearch from './pages/StoreSearch';
import { baseUrl, getConfig } from './helpers/configHelper';
import ScanQrCode from './pages/ScanQrCode';
import QRLanding from './pages/QRLanding';
import { useSelector } from 'react-redux';
/* Static Pages*/
import TCPage from './pages/TCPage';
//import FAQModal from './pages/FAQPage';

import Splash from './pages/Splash';

import { ApplicationState } from './store';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
//import './theme/variables.css';
import './theme/common.scss';
import ThemeManager from './components/ThemeManager';
import ChooseOrderType from './pages/ChooseOrderType';
import TableNumber from './pages/TableNumber';
import NameOrNickname from './pages/NameOrNickname';

let history = createBrowserHistory();



function loadGooglePlaces() {
    var tag = document.createElement('script') as any;
    tag.async = true;
    tag.type = "text/javascript";
    tag.onload = (s: any) => {
        console.log("GOOGLE PLACES LOADED");
    };

    tag.src = "https://maps.googleapis.com/maps/api/js?key=" + process.env.REACT_APP_GOOGLE_LOCATE_KEY + "&libraries=places";
    var body = document.getElementsByTagName('body')[0];
    body.appendChild(tag);
}

const loadHeadTags = () => {

    let faviconHref = baseUrl() + "/images/icon/cafe/favicon.png";
    let iconHref = baseUrl() + "/images/icon/cafe/icon.png";

    if(getConfig().isMarket){
        faviconHref = baseUrl() + "/images/icon/mk/favicon.png";
        iconHref = baseUrl() + "/images/icon/mk/icon.png";
    }


    const favicon = document.createElement('link');
    favicon.rel = "shortcut icon";
    favicon.type = "image/png";
    favicon.href = faviconHref;

    const icon = document.createElement('link');
    icon.rel = "apple-touch-icon";
    icon.href = iconHref;

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(favicon);
    head.appendChild(icon);

}

const createManifest = () => {
    let isMarket = getConfig().isMarket;
    let manifest = {
        "short_name": isMarket ? "Market Kitchen" : "Morrisons Cafe",
        "name": isMarket ? "Market Kitchen Order & Pay" : "Morrisons Cafe Order & Pay",
        "icons": [
        {
            "src": `images/icon/${isMarket ? "mk" : "cafe"}/favicon.png`,
            "sizes": "64x64 32x32 24x24 16x16",
            "type": "image/x-icon"
        },
        {
            "src": `images/icon/${isMarket ? "mk" : "cafe"}/icon.png`,
            "type": "image/png",
            "sizes": "512x512",
            "purpose": "maskable"
        }
        ],
        "start_url": ".",
        "display": "standalone",
        "theme_color": "#ffffff",
        "background_color": "#ffffff"
    };

    const stringManifest = JSON.stringify(manifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestUrl = URL.createObjectURL(blob);
    document.querySelector('#manifest-placeholder')?.setAttribute('href', manifestUrl);
}




const App: React.FC = () => {
    //const location = useLocation();
    

    useEffect(() => {
        loadGooglePlaces();
        loadHeadTags();
        createManifest();
    }, []);

    const [showExpireModal, setShowExpireModal] = useState<boolean>(false);

    const expired = useSelector((state: ApplicationState) => state.colleague?.expired);

    useEffect(() => {
        if (expired != null) {
            setShowExpireModal(true);
        }
    }, [expired])

    const contentRef = useRef<HTMLIonContentElement>(null);
    const basketState = useSelector((state: ApplicationState) => state.basket);
    return (
        <ThemeManager>
            <IonApp>
                <IonReactRouter basename={baseUrl()}>
                    <AppHeader >
                    </AppHeader>
                    

                    <IonContent ref={contentRef}>
                        <LocationListener contentRef={contentRef}></LocationListener>

                        <HistoryListener></HistoryListener>

                        {showExpireModal &&
                            <IonAlert
                                isOpen={showExpireModal}
                                onDidDismiss={() => { setShowExpireModal(false); }}
                                header={"You are leaving the colleague area"}
                                buttons={[
                                    {
                                        text: "Ok",
                                        handler: () => {
                                            localStorage.removeItem("isLoggedIn");
                                            localStorage.removeItem("pfIdentifier");
                                            window.location.reload();
                                        }
                                    }
                                ]}
                                message={""}
                            />
                        }

                        <IonRouterOutlet className="mainContent" animated={false} mode={"ios"}>
                            <Route path="/categories/:categoryIdentifier?" render={(props) => <Categories useStoreA={true} {...props}></Categories>} exact={true} />
                            <Route path="/categoriesb/:categoryIdentifier?" render={(props) => <Categories useStoreA={false} {...props}></Categories>} exact={true} />
                            <Route path="/menuitem/:menuItemId" component={MenuItem} />
                            <Route path="/payment" component={CheckoutTestPage} exact/>
                            <Route path="/payment/success" component={PaymentSuccess} />
                            <Route path="/location" component={Location} />
                            <Route path="/basketpage" component={BasketPage} />
                            <Route path="/temptyou" component={UpsellPage} />
                            <Route path="/orderconfirmation" component={OrderConfirmation} />
                            <Route path="/scanqrcode" component={ScanQrCode} />
                            <Route path="/qr/:storeReference?" component={QRLanding} />
                            <Route path="/enteremail" component={EnterEmail} />
                            <Route path="/choosetimeslot" component={TimeSlotPage} />
                            <Route path="/storesearch" component={StoreSearch} />
                            <Route path="/tab2" component={Tab2} exact={true} />
                            <Route path="/splash" component={Splash} exact={true} />
                            <Route path="/tab3" component={Tab3} />
                            <Route path="/tc" component={TCPage} />
                            <Route path="/ordertype" component={ChooseOrderType} />
                            <Route path="/tablenumber" component={TableNumber} />
                            <Route path="/name-or-nickname" component={NameOrNickname} />
                            <Route path="/couponz" component={CouponsTestPage} exact />

                            <Route
                                path="/mk"
                                render={({ location }) => {
                                    return <Redirect to={{ pathname: "/splash", search: location.search }} />;
                                }}
                            />
                            <Route
                                path="/"
                                exact
                                render={({ location }) => {
                                    return <Redirect to={{ pathname: "/splash", search: location.search }} />;
                                }}
                            />
                        </IonRouterOutlet>
                       
                        <IonToast animated={true} 
                            isOpen={basketState ? basketState?.toastNotificationOpen : false}                         
                        position={"bottom"} message="Added to Order" cssClass={"toastStyle"}/>

                    </IonContent>

                    <MopFooter>
                    </MopFooter>

                </IonReactRouter>
            </IonApp>
        </ThemeManager>
    )
};

export default App;
