import { IonLoading } from '@ionic/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { baseUrl, getConfig } from '../helpers/configHelper';
import { ApplicationState } from '../store';
import * as headerStore from '../store/HeaderFooterStore';

const CafeTheme = React.lazy(() => import('../theme/CafeTheme'));
const MarketTheme = React.lazy(() => import('../theme/MarketTheme'));

const LoadingFallback: React.FC = () => 
        <div>
            <IonLoading
                isOpen={true}
                message={"Loading..."}
            />
        </div>

const ThemeManager: React.FC = ({ children }) => {
    
    const headerFooter = useSelector((state: ApplicationState) => state.headerFooter);
    //const boundSetConfiguration = bindActionCreators(headerStore.actionCreators.setAppConfiguration, useDispatch());

    const getConfigurationFromApi = () => {
        fetch(baseUrl() + "/stores/GetAppConfiguration")
        .then(res => res.json() as Promise<any>)
        .then(res => {
            //console.log("GOT CONFIGURATION", res.isMarket);
            //res.isMarket = true;
            //boundSetConfiguration(res);
        })
        .catch((err) => {
            console.log(err);
        });        
    }

    

    useEffect(() => {
        //boundSetConfiguration(getConfig());
    }, [])
    
    return(
        <>
            <React.Suspense fallback={<LoadingFallback/>}>
                {
                    getConfig().isMarket
                    ?
                        <MarketTheme/>
                    :
                        <CafeTheme />
                }
            </React.Suspense>   
            { children }
        </>
    )
}

export default ThemeManager;