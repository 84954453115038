import React from 'react';
import { IonContent, IonHeader, IonButton, IonPage, IonTitle, IonToolbar, IonImg } from '@ionic/react';
import { useHistory } from "react-router-dom";
import './ScanQrCode.scss';
import { baseUrl, getConfig } from '../helpers/configHelper';

const ScanQrCode: React.FC = () => {
    let history = useHistory();
    return (
        <IonPage>
            <IonContent fullscreen>
                <div className="ScanQRcodeWrapper">
                    <div className="ScanQRcodeTitle">{getConfig().isMarket ? "Market Kitchen" : "Café"} Order & Pay</div>
                    <div className="ScanQRcodeTitleSmall">Use your phone's camera to scan the QR code in store to start your order</div>
                    <img src={`${baseUrl()}/images/assets/qrcode.jpg`} className="ScanQRcodeImage"/>
                    </div>
            </IonContent>
        </IonPage>
    );
};

export default ScanQrCode;
