import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getConfig } from '../helpers/configHelper';
import { ApplicationState } from '../store';

interface GooglePayProps {
    TotalPrice: number,
    GooglePayButtonContainer: HTMLElement,
    GooglePayButtonClickHandler: (paymentToken: any) => void,
    ShouldPaymentStart: (callback: (res: boolean) => void) => void,
    LivePayments: boolean
}

const GooglePay: React.FC<GooglePayProps> = (props) => {
    
    //const [totalPrice, setTotalPrice] = useState(props.TotalPrice.toString());
    const totalPrice = useRef(useSelector((state: ApplicationState) => state.basket?.basket.totalPrice)?.toString() || "1.23");

    //Step 1 - Define Google Pay API Version
    const baseRequest = {
        apiVersion: 2,
        apiVersionMinor: 0
    };

    //Step 2 - Request a payment token from Cybersrouce
    const tokenizationSpecification = {
        type: 'PAYMENT_GATEWAY',
        parameters: {
        'gateway': 'cybersource',
        'gatewayMerchantId': getConfig().isMarket ? props.LivePayments ? "barcfuse1548282" : "marketkitchen_sit" : props.LivePayments ? "barcfuse1539368" : 'morrisonscafeapp_sit'
        }
    };

    //Step 3
    const allowedCardNetworks = ["AMEX", "JCB", "MASTERCARD", "VISA"];
    const allowedAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];

    const merchantInfo = {
        merchantName: getConfig().isMarket ? "Market Kitchen" : "Morrisons Cafe",
        merchantId: props.LivePayments ? getConfig().isMarket ? "BCR2DN6T36MOD52S" : "BCR2DN6T36MOD52S" : "12345678901234567890"
    }

    //Step 4
    const baseCardPaymentMethod: any = {
        type: "CARD",
        parameters: {
            allowedCardNetworks: allowedCardNetworks,
            allowedAuthMethods: allowedAuthMethods,
            assuranceDetailsRequired: true
        }
    }

    const cardPaymentMethod = Object.assign(
        {tokenizationSpecification: tokenizationSpecification},
        baseCardPaymentMethod
        );

    let paymentDataRequest: google.payments.api.PaymentDataRequest = {
        ...baseRequest, //apiVersion, apiVersionMinor
        merchantInfo: merchantInfo,
        allowedPaymentMethods: [
            cardPaymentMethod
        ],
        transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPrice: totalPrice.current,
            countryCode: "GB",
            currencyCode: "GBP",
            checkoutOption: "COMPLETE_IMMEDIATE_PURCHASE"
        }
    }

    const isReadyToPayRequest: google.payments.api.IsReadyToPayRequest = {
        ...baseRequest,
        allowedPaymentMethods:  [
            cardPaymentMethod
        ]
    }

    let paymentsClient = new google.payments.api.PaymentsClient({ environment: props.LivePayments ? "PRODUCTION" : "TEST" });
        
    const googleButtonOnClick = () => {
        //console.log(paymentDataRequest);
        //console.log("PaymentDataRequest: ", paymentDataRequest, totalPrice, props.TotalPrice);
        paymentsClient.loadPaymentData({ ...paymentDataRequest, transactionInfo: {...paymentDataRequest.transactionInfo, totalPrice: totalPrice.current}})
        .then((paymentData: google.payments.api.PaymentData) => {
            props.GooglePayButtonClickHandler(paymentData) // CALLBACK WITH BASE64 TOKEN
        })
        .catch((err) => {
            console.log(err);
        })                 
    }

    const clearElements = (container: HTMLElement) => {
        while (container.firstChild){
            container.removeChild(container.firstChild);
        }
    }

    useEffect(() => {
        paymentsClient.isReadyToPay( isReadyToPayRequest )
            .then((response: google.payments.api.IsReadyToPayResponse) => {
                //console.log(response);
                if(response.result){
                    //Add Google Pay payment button
                    let button = paymentsClient.createButton({ 
                        onClick: () => {
                                //console.log("Total Price state: " + totalPrice);
                                props.ShouldPaymentStart((res) => {
                                    if(res){
                                        googleButtonOnClick();                                    
                                    }                                    
                                })
                        },
                        buttonColor: "black",
                        buttonSizeMode: "fill",
                        buttonType: "plain"
                })
                clearElements(props.GooglePayButtonContainer);
                props.GooglePayButtonContainer.appendChild(button);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }, [props.GooglePayButtonContainer])

    useEffect(() => {
        //console.log("Price changed in GOogle component", props.TotalPrice);
        //setTotalPrice(props.TotalPrice.toString());
    }, [props.TotalPrice])

    return(
        <div>

        </div>
    )
};

export default GooglePay;