const pushToGoogleEcommerce = (event: string, ecommerce: any) => {
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({ 
        event: event,
        ecommerce: ecommerce
     });
}   

export const addItemToGtagBasket = (currentMenuItem: IMopMenuItemDetail, affiliation: string, value: number ) => {
    //Old gtag method - (window as any).gtag('event', 'add_to_cart', getGtagBasketObject([currentMenuItem], value, affiliation));
    pushToGoogleEcommerce("add_to_cart", getGtagBasketObject([currentMenuItem], value, affiliation));
}

export const removeItemFromGtagBasket = (currentMenuItem: IMopMenuItemDetail, affiliation: string) => {
    //(window as any).gtag('event', 'remove_from_cart', getGtagBasketObject([currentMenuItem], currentMenuItem.menuItemSinglePrice, affiliation));
    pushToGoogleEcommerce("remove_from_cart", getGtagBasketObject([currentMenuItem], currentMenuItem.menuItemSinglePrice, affiliation));
}

export const addPurchaseToGtag = (transactionId: string, basket: IMopBasket, affiliation: string, orderType: string, collectionTime: string | undefined) => {
    if(basket.menuItems){
        let gtagPurchaseObject = {
            transaction_id: transactionId,
            affiliation: affiliation,
            collection_time: collectionTime,
            order_type: orderType,
            ...getGtagBasketObject(basket.menuItems, basket.totalPrice, affiliation, true)
          };
        
        //(window as any).gtag('event', 'purchase', gtagPurchaseObject);
        pushToGoogleEcommerce("purchase", gtagPurchaseObject);

        //console.log("Sending basket to google e-commerce", gtagPurchaseObject);
    }  
}

const getGtagBasketObject = (menuItems: IMopMenuItemDetail[], value: number, affiliation: string, addCoupon?: boolean) => {
    let _menuItems: IMopMenuItemDetail[] = [];
    let _discounts: IMopMenuItemDetail[] = [];
    let _googleItems: any[] = [];

    for(let i = 0; i < menuItems.length; i++){
        let menuItem = menuItems[i];
        if(!menuItem.isDiscount){
            _menuItems.push(menuItem);
        }        
        else{
            _discounts.push(menuItem);
        }
    }
    
    let totalPricePreDiscount = _menuItems.reduce((prev, curr) => {
        return prev + (curr.menuItemSinglePrice * curr.quantity)
    }, 0);

    let discount: any = {};
    if(_discounts.length > 0){
        discount = _discounts.reduce((prev, curr, i) => {
            return {
                coupon: i == 0 ? curr.menuItemName : prev.coupon + ", " + curr.menuItemName,
                totalDiscount: prev.totalDiscount == undefined ? curr.menuItemBasePrice : prev.totalDiscount + curr.menuItemBasePrice,
                percentage: 0
            }
        }, discount);
        discount.percentage = Number.parseFloat(((totalPricePreDiscount + discount.totalDiscount) / totalPricePreDiscount).toFixed(2));
        //console.log(discount, totalPricePreDiscount);
    }

    for(let i = 0; i < _menuItems.length; i++){
        let menuItem = _menuItems[i];
        let itemDiscount: any = {   //will be undefined if no discount
            coupon: discount.coupon,
            discount: discount.percentage == undefined ? undefined : Number.parseFloat(((menuItem.menuItemSinglePrice) * (1 - discount.percentage)).toFixed(2))
        }
        _googleItems.push(formatMopMenuItemToGoogleMenuItem(menuItem, menuItem.quantity, affiliation, itemDiscount));
    }

    let gtagMenuItem = {
        currency: 'GBP',
        items: _googleItems,
        value: value,
        coupon: discount.coupon
        };
    
    return gtagMenuItem;
}

const formatMopMenuItemToGoogleMenuItem = (currentMenuItem: IMopMenuItemDetail, quantity: number, affiliation: string, discount: any) => {
    //console.log("Discount format item ", discount);
    return {
        item_id: currentMenuItem.sku,
        affiliation: affiliation,
        item_name: currentMenuItem.menuItemName,
        price: currentMenuItem.menuItemSinglePrice,
        currency: 'GBP',
        quantity: quantity,
        item_category: currentMenuItem.categoryName,
        ...discount
    };
}
