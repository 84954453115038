import React, { useEffect } from 'react';
import './AppHeader.scss';
import { ApplicationState } from '../store';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import '../theme/common.scss';
import * as headerFooterStore from '../store/HeaderFooterStore';
import { bindActionCreators } from 'redux'

interface AppHeaderProps { }

const HistoryListener: React.FC<AppHeaderProps> = ({ }) => {
    let history = useHistory();
    const boundSetPage = bindActionCreators(headerFooterStore.actionCreators.setPage, useDispatch());
	const locationState = useSelector((state: ApplicationState) => state.location);
    // Set up a listener to notify the HeaderFooter store when the page changes
    useEffect(() => history.listen((location, action) => {
        boundSetPage(location.pathname, locationState?.selectedChannel?.channelType);
        console.log("Changing HL page to=" + location.pathname);
    }), []);
    return (<div></div>);
};

export default HistoryListener;
