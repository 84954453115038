import { baseUrl } from '../helpers/configHelper';
import { addPurchaseToGtag } from '../helpers/googleHelpers';
import { ApplicationState } from '../store';
import { simpleChannelType } from '../store/LocationStore';
/// -- Checkout Request Typings --

export interface IMopCheckoutRequest {
    transactionType: string;
    ignoreCsvResult?: boolean;
    email?: string;
    timeslot: TimeSlot;
    channelIdentifier?: string;
    expiryDate?: string;
    orderNumber?: string;
    basket: IMopBasket;
    cardRequest?: ICardRequest;
    tableNumber: string;
    nickname?: string;
    digitalRequest?: IDigitalRequest;
    
    createPaymentRequest?: Ptsv2CreatePaymentRequest
    cardInformation?: Ptsv2PaymentInformationCard
}

export interface IGetRewardDetailsResponse {
    showMorePoints: boolean
    morePointsRewarded?: number
}

export interface ICardRequest {
    flexObj: string;
    referenceId?: string;
    returnUrl?: string;
    transactionId?: string;
}

export interface IDigitalRequest {
    cardSummary: string;
    paymentSolution: string;
    descriptor?: string;
    encoding?: string;
    value: string;
}

export interface IAuthenticatePaymentRequest {
    flexTokenInformation?: FlexTokenInformation
    paymentInformation?: Ptsv2PaymentInformation
}

export interface IMopCheckoutResponse {
    checkoutRecord: MopCheckoutRecord
    paymentResult: Ptsv2PaymentResponse
}

interface MopCheckoutRecord {
    channelIdentifier: string,
    basket: IMopBasket
    email: string
    orderUid: string,
    orderNumber: string
}

export const AuthenticatePaymentRequest = async (request: PayerAuthSetupRequest) => {
    console.log("Authenticate called with", request);
    let params: RequestInit = { 
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request)
    }
    const response = await fetch(baseUrl() +'/api/checkout/Authenticate', params);
    const data = await response.json() as RiskV1AuthenticateResponse;
    return data;

}


export const GetRewardDetails = async (basketId?: string) => {

    console.log("Get rewards call with", basketId);
    let params: RequestInit = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        },
    }
    // Ensure baseUrl is correct and doesn't include any backslashes
    const base = baseUrl();
    const url = `${base}/api/checkout/GetRewardDetails?basketId=${basketId}`;

    const response = await fetch(url, params);
    const data = await response.json() as IGetRewardDetailsResponse;

    return data;

}


export const CreatePaymentRequest = async (request: IMopCheckoutRequest, affiliation: string, appState: ApplicationState, callback: (err: string | null, checkoutResponse: IMopCheckoutResponse | null) => void) => {
    let pfIdentifier = localStorage.getItem("pfIdentifier")

    let orderType = appState.location?.selectedChannel?.channelType;
    let _orderType = "Unset";
    switch(orderType){
        case simpleChannelType.ClickAndCollect:
            _orderType = "Takeaway";
            break;
        case simpleChannelType.TableOrder:
            _orderType = "Eat In";
            break;
    };

    let collectionTime = orderType == simpleChannelType.ClickAndCollect ? appState.location?.selectedTimeSlot.label : undefined;


    let params: RequestInit = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request)
    }
    fetch(baseUrl() + `/api/checkout/CreatePayment${pfIdentifier ? `?pfIdentifier=${pfIdentifier}` : ""}`, params )
        .then(response => {
            if(!response.ok){
                return response.text().then(text => { throw new Error(text); })
            }
            else{
                return response.json();
            }
        })
        .then((responseData: IMopCheckoutResponse) => {            

            callback(null, responseData);
            console.log(responseData);
            if(responseData.checkoutRecord.orderUid != undefined && responseData.checkoutRecord.basket.basketId != undefined){
                addPurchaseToGtag(responseData.checkoutRecord.basket.basketId, responseData.checkoutRecord.basket, affiliation, _orderType, collectionTime);
                console.log("Saving order to local storage....");
                let previousOrders: string[] = JSON.parse(localStorage.getItem("mopOrders") || "[]");
                previousOrders.push(responseData.checkoutRecord.orderUid);
                localStorage.setItem("mopOrders", JSON.stringify(previousOrders));
            }
            else{
                console.log("Unable to add purchase to google tag manager, order UID undefined?", responseData.checkoutRecord.orderUid == undefined, "basketId undefined?", responseData.checkoutRecord.basket.basketId == undefined);
            }
            
        })
        .catch((err) => {
            callback(err, null);
        })
};
