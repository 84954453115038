import React, { useState } from 'react';
import { IonRow, IonCol, IonText, IonImg } from '@ionic/react';
import './CategoryGrid.scss';
import { baseUrl, cacheBust } from '../helpers/configHelper';
import { getConfig } from '../helpers/configHelper';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';


interface CategoryGridProps {
	categoryId: number;
	categoryImage: string;
	categoryName: string;
	fullWidth: boolean;
	onClick?: (categoryId: number) => void;
	isFavourites?: boolean;
}

const CategoryGrid: React.FC<CategoryGridProps> = ({ categoryId, categoryImage, categoryName, fullWidth, onClick, isFavourites }) => {

	const headerState = useSelector((state: ApplicationState) => state.headerFooter);

	return (
		<IonCol key={"KY" + categoryId} onClick={() => onClick && onClick(categoryId)} size={fullWidth ? "12" : "6"}>
			<div className={`MenuItem-Tile_Category__container ${isFavourites && "isFavourites"}`}>
				<div className="MenuItem-Tile__image_container">

					{/* <img src={(getConfig().isMarket ? fullWidth ? `${baseUrl()}/images/CategoryShots/null_fw.jpg` : `${baseUrl()}/images/CategoryShots/null.jpg` : `${baseUrl()}/images/assets/Logo_Cafe_Default_PDP.jpg`)} />*/}
					<img className="MenuItemImage" alt={`category_image_${categoryId}`} src={(fullWidth ? `${baseUrl()}/images/CategoryShots/` + isFavourites ? `${baseUrl()}/images/CategoryShots/favourites_fw.jpg` : categoryImage + '_fw.jpg' : `${baseUrl()}/images/CategoryShots/` + categoryImage + '.jpg') + `?v=${cacheBust()}`} />
				</div>
				<div className="MenuItemCaptionContainer">
					<div className="MenuItemCaption">
						{isFavourites ? "More Card" : categoryName}
						{isFavourites && <span>Quick access to Market Kitchen, favourites you've ordered before</span>}
					</div>
				</div>
			</div>
			{
				headerState?.showDebugLabel &&
				<div className="catDebugLabel">
					{categoryId} {categoryName}
				</div>
			}
		</IonCol>
	);
};

export default CategoryGrid;