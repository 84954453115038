


export function formatCurrency(amount: number | null | undefined) {
    if (!amount) return "£ --";

    // check if amount is positive or negative and return the appropriate symbole

    if (amount < 0) {
        if (amount > -1) {
            return "-" + amount.toFixed(2).split('.')[1] + "p";
        }

        return "-£" + Math.abs(amount).toFixed(2);
    }

    if (amount < 1) {
        return amount.toFixed(2).split('.')[1] + "p";
    }

    return "£" + amount.toFixed(2);
}