import * as MenuItemData from './MenuItemStore';
import * as MenuData from './MenuStore';
import * as Counter from './Counter';
import * as HeaderFooter from './HeaderFooterStore';
import * as Location from './LocationStore';
import * as Basket from './BasketStore';
import * as Colleague from './ColleagueStore';

import { MenuDataState } from './MenuStoreTypes';

// The top-level state object
export interface ApplicationState {
    counter: Counter.CounterState | undefined;
    menuData: MenuDataState | undefined;
    menuItemData: MenuItemData.MenuItemDataState | undefined;
    headerFooter: HeaderFooter.HeaderFooterState | undefined;
    location: Location.LocationState | undefined;
    basket: Basket.BasketState | undefined;
    colleague: Colleague.PinState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    counter: Counter.reducer,
    menuData: MenuData.reducer,
    menuItemData: MenuItemData.reducer,
    location: Location.reducer,
    headerFooter: HeaderFooter.reducer,
    basket: Basket.reducer,
    colleague: Colleague.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
