import React from "react";
import { IonModal, IonButton } from "@ionic/react";
import './AllergenPopup.scss';

interface AllergenPopupProps {
    show?: boolean;
    close: () => void;
    accept: () => void;
}

function AllergenPopup(props: AllergenPopupProps) {
    return (
        <IonModal
            cssClass='AllergenPopup'
            isOpen={props.show ?? false}
            swipeToClose={true}
            onDidDismiss={props.close}
        >
            <div className='content'>
                <h1>Allergy Information</h1>

                <p>We freshly prepare food in a busy kitchen which means that it may contain other allergens than those listed and may not be suitable for you.</p>

                <p><strong>Do you want to continue?</strong></p>

                <div
                    className='button-row'
                >
                    <IonButton
                        onClick={props.close}
                        expand='block'
                        color='light'
                        size='large'
                    >
                        No
                    </IonButton>
                    <IonButton
                        onClick={props.accept}
                        expand='block'
                        size='large'
                    >
                        Yes
                    </IonButton>
                </div>
            </div>
        </IonModal>
    );
}

export default AllergenPopup;
