import React, { useState, useEffect, ReactNode } from 'react';
import { IonItem, IonLabel, IonRadio, IonGrid,IonList,IonRadioGroup, IonCol, IonRow, IonIcon } from '@ionic/react';
import * as MenuItemDataStore from '../store/MenuItemStore';
import './ModifierGroup.scss';
import ProductSingleSelect from '../components/ProductSingleSelect';
import ProductMultiSelect from '../components/ProductMultiSelect';
import { menuSingleItemSelected } from '../helpers/menuHelpers';
import { getConfig } from '../helpers/configHelper';

import { ApplicationState } from '../store';
import { useSelector, useDispatch } from 'react-redux';
import * as menuItemStore from '../store/MenuItemStore';
import { bindActionCreators } from 'redux';
import { formatCurrency } from '../helpers/currencyHelpers';
import QuantityInput from './QuantityInput';

import AltQuantityInput from './AltQuantityInput';

import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { chevronDownOutline, chevronUpOutline, menu } from 'ionicons/icons';
import HorizontalRadioGroup from './HorizontalRadioGroup';


interface IModifierGroup {
    
    modifierGroup: IMopModifierGroup;
    menuItem: IMopMenuItemDetail | null;
    onClick?: (categoryId: number) => void;
    isDropdownOpen: boolean;
    onToggleDropdown: (v: boolean, id: string) => void;
    onSelectedSingle: (id: string) => void;
    localId: string;
    isColleague: boolean | null;
}

const ModifierGroup: React.FC<IModifierGroup> = ({ modifierGroup, onClick, menuItem, isDropdownOpen, onToggleDropdown, localId, onSelectedSingle, isColleague }) => {

    let { groupTitle, canSelectMultiple, itemsAreChargeable, maxDifferentItems, maxPoints, minDifferentItems, modifierGroupItems, groupType, auxMenuItemData: auxData } = modifierGroup;

    const headerState = useSelector((state: ApplicationState) => state.headerFooter);
    const isBasket = useSelector((state: ApplicationState) => state.menuItemData?.isBasket);
    const boundUpdateItem = bindActionCreators(menuItemStore.actionCreators.updateModifierGroupItem, useDispatch());
    

    const [selected, setSelected] = useState<string | undefined>();
    const [totalQuantitySelected, setTotalQuantitySelected] = useState<number>(0);
    const [chosenItems, setChosenItems] = useState<string[]>([]);
    const [updated, setUpdated] = useState(false);

    /*
        onModifierGroupItemSelected
            if min is 1 (OR max met?), and it's the first time it's been changed (or totalSelected == max)
                tryOpenNextModifierGroup
                    if there is a next modifier group
                        move on to next
    */

    /*
        TODO: 
        Group 10 - Radio - DONE
        Group 20 - Quantity No Checkbox ChargeableExtras
        Group 30 - Checkbox Customisations 
        Group 40 - Quantity with Checkbox Swaps - 
        Group 50 - Extras           

    */

    const getModifierComponent = (singleLine?: boolean): JSX.Element[] => {
        let nodes: JSX.Element[] = [];
        if(groupType == 10 || groupType == 60){       
            
            //if(auxData && auxData.controlOrientationHorizontal){
            //    nodes = [<HorizontalRadioGroup chargeable={itemsAreChargeable} selected={selected} onChange={e => setSelected(e.detail.value)} GroupItems={modifierGroupItems}/>]
                console.log(nodes, modifierGroupItems);
            //}
            //else{

                nodes = modifierGroupItems?.map((mgi: IMopModifierGroupItem, index: number) => {
                    let { menuItemId, menuItemName, sku, defaultQuantitySelected, price, quantitySelected } = mgi;

                    return (    //onIonChange - update quantitySelected property?
                        <IonList no-lines="none" key={"mgi_" + menuItemId}>
                            <IonRadioGroup value={selected} onIonChange={e => setSelected(e.detail.value)}> 
                                <div data-text='menuItemId={menuItemId},  menuItemName={menuItemName}, defaultQuantitySelected={defaultQuantitySelected}, price={price}, quantitySelected={quantitySelected}'>
                                    <ProductSingleSelect kcal={mgi.kcal} debugLabel={`${sku} ${menuItemId}`} price={itemsAreChargeable ? price : null} productSingleSelectName={menuItemName} ></ProductSingleSelect>
                                </div>
                            </IonRadioGroup>
                        </IonList>
                    )
                })
            
            //}
        }
        if(groupType == 30 || groupType == 40){ 
            nodes = modifierGroupItems?.map((mgi: IMopModifierGroupItem, index: number) => {
                let { menuItemId, menuItemName, sku, defaultQuantitySelected, price, quantitySelected } = mgi;
                return (
                    <IonList no-lines="none" key={"mgi_" + menuItemId}>
                        <ProductMultiSelect 
                            showQuantityComponent={groupType == 40 && quantitySelected > 0} 
                            productMultiSelectName={menuItemName} 
                            isSelected={quantitySelected > 0} 
                            disableCheckbox={totalQuantitySelected >= maxDifferentItems && quantitySelected < 1 && maxDifferentItems > 0}
                            disableAddQuantityButton={(totalQuantitySelected >= maxDifferentItems && maxDifferentItems > 0) || (mgi.maxQuantity != null && quantitySelected >= mgi.maxQuantity)}
                            menuItem={mgi} 
                            onClick={handleChange} 
                        ></ProductMultiSelect>
                        {
                            headerState?.showDebugLabel &&
                            <div className="modifierItemDebug">
                                {sku} {menuItemId}
                            </div>
                        }
                    </IonList>
                )
                
            })
        }
        if(groupType == 20 || groupType == 50){
            nodes = modifierGroupItems?.map((mgi: IMopModifierGroupItem, index: number) => {
                //mgi.kcal = 1234;
                //console.log("Returning ", mgi.menuItemName);
                return (
                    <IonGrid className="chargeableListWrapper" key={"mgi_" + mgi.menuItemId}>
                        <IonRow>
                            <IonCol className="chargeableListName">
                                <div>
                                    <span dangerouslySetInnerHTML={{ __html: mgi.menuItemName }}></span><span className='modifierGroupItemKcal'>({mgi.kcal}kcal)</span>
                                </div>
                            </IonCol>
                            <IonCol size="2" className="chargeableListPrice">
                                <div>+{formatCurrency(mgi.price)}</div>
                            </IonCol>
                            <IonCol size="4" className="chargeableListQuantity">
                                <AltQuantityInput
                                    value={mgi.quantitySelected}
                                    subtractButtonColour="grey"
                                    addButtonColour="primary"
                                    onChange={(quantity) => { handleChange(mgi, quantity ); } }/>
                            </IonCol>             
                        </IonRow>
                    </IonGrid>)
            })
        }
        return nodes;
    }

    useEffect(() => {
        var startSelected = menuSingleItemSelected(modifierGroup, isBasket as boolean);  //Pass through isBasketUpdate flag to decide whether to check defaultQuantitySelected or quantitySelected 
        setSelected(startSelected);
    }, []);

    useEffect(() => {
        if(groupType == 10){ //Only perform this check if it's a radio group modifier, could use maxDifferentItems instead
            
            let menuItem: IMopModifierGroupItem = modifierGroupItems.find((item: IMopModifierGroupItem) => item.menuItemName == selected);
            let prevItem: IMopModifierGroupItem = modifierGroupItems.find((item: IMopModifierGroupItem) => item.quantitySelected == 1 && item.defaultQuantitySelected != 1);
            console.log("Selected", selected, menuItem, prevItem, "isBasket", isBasket, "isColleague", isColleague);
            if(menuItem){
                handleChange(menuItem, 1);
                if(prevItem && prevItem.menuItemId != menuItem.menuItemId){
                    setUpdated(true);
                    handleChange(prevItem, 0);
                    //console.log(menuItem, prevItem);
                    onSelectedSingle(localId);
                }
                else{
                    if(!isBasket && menuItem.defaultQuantitySelected > 0){
                        //Program selected this option.
                    }
                    else if(isBasket && menuItem.quantitySelected > 0){
                        //Program selected this option.
                    }
                    else{
                        onSelectedSingle(localId);
                    }
                }
            }            
        }        
    }, [selected])

    //Max Quantity Handler
    useEffect(() => {
        let total = 0;
        let chosen = [];
        for(let i = 0; i < modifierGroupItems.length; i++){
            let item: IMopModifierGroupItem = modifierGroupItems[i];
            total += item.quantitySelected;
            if(item.quantitySelected > 0){
                chosen.push(item.menuItemName);
            }
        }

        setChosenItems(chosen);
        setTotalQuantitySelected(total);
    }, [modifierGroupItems]);

    const handleChange = (menuItem: IMopModifierGroupItem, quantity: number) => {
        //console.log("Updating menu item in group at ", menuItem.menuItemId, "Quantity = ", quantity);   
        boundUpdateItem(menuItem.menuItemId, quantity);
    }

    const detailsPageSettings = menuItem?.auxData?.detailsPageSettings;

    return (
        <IonGrid className="ion-no-padding modifierGroup">
            {getConfig().isMarket 
            ?
                <>
                    {
                        (!auxData || !auxData.hideDefaultTitle) 
                        ?
                            <>
                            {
                                <div className="ProductOptionBanner">
                                    <div className="ProductOptionBannerTitle" dangerouslySetInnerHTML={{ __html: groupTitle}}></div>
                                    <div className="ProductOptionBannerDescription">These will be added to your order</div>
                                </div>
                            }
                            {
                                getModifierComponent()
                            }
                            </>
                        :
                            (modifierGroupItems.length > 1 && !auxData.hideDropdown) 
                            ?
                                <>
                                <IonItem className="ModifierGroup-Dropdown__item" lines="none" onClick={() => { onToggleDropdown(!isDropdownOpen, localId); }}>
                                    <div style={{
                                        backgroundColor: detailsPageSettings?.dropdownBackgroundColour ? detailsPageSettings.dropdownBackgroundColour : "inherit",
                                        color: detailsPageSettings?.dropdownFontColour ? detailsPageSettings.dropdownFontColour : "inherit"
                                        }} className={`ModifierGroup-Dropdown__header ${totalQuantitySelected > 0 ? "selected" : ""}`}>
                                        <div className="Dropdown-Label__container">
                                        {
                                            totalQuantitySelected > 0
                                            ?
                                                <div className="Dropdown-Label__selected">
                                                    <div className="Dropdown-Selected__title">{auxData?.name ? auxData.name + ": " : ""}Click to change</div>
                                                    <div className="Dropdown-Selected__choice">
                                                        {
                                                            chosenItems.map((chosen, i) => {
                                                                /*
                                                                chosen += chosen == "" ? "" : ", ";
                                                                chosen += `${item.quantitySelected > 1 ? item.quantitySelected + " x " : ""}${item.menuItemName}`;
                                                                */
                                                                let prefix = i > 0 ? ", " : "";
                                                                return <span><span>{prefix}</span><span dangerouslySetInnerHTML={{__html: chosen}}></span></span>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                <div className="Dropdown-Label__default">{auxData.preSelectedTitle ? auxData.preSelectedTitle : "Choose your " + auxData.name}</div>
                                        }
                                        </div>
                                        {
                                            isDropdownOpen 
                                            ?
                                                <IonIcon icon={chevronUpOutline} />
                                            :
                                                <IonIcon icon={chevronDownOutline}/>
                                        }
                                    </div>
                                </IonItem>
                                <SlideDown closed={!isDropdownOpen}>
                                    {getModifierComponent(modifierGroupItems.length == 1)}
                                </SlideDown>
                                </>
                            :
                                <>
                                {
                                    (auxData.showCustomTitle && auxData.name) 
                                    &&
                                    <div className="ProductOptionBanner">
                                        <div className="ProductOptionBannerTitle">{auxData.name}</div>
                                        {
                                            auxData.description &&
                                            <div className="ProductOptionBannerDescription">{auxData.description}</div>
                                        }
                                    </div>
                                }
                                {
                                    getModifierComponent()
                                }
                                </>

                    }
                    
                </>
            :
                <>
                    <div className="ProductOptionBanner">
                        <div className="ProductOptionBannerTitle" dangerouslySetInnerHTML={{ __html: groupTitle}}></div>
                        <div className="ProductOptionBannerDescription">These will be added to your order</div>
                    </div>
                    <>
                        {getModifierComponent()}     
                    </>
                </>
            }
        </IonGrid>
    );
};

export default ModifierGroup;
