import React, { useEffect, useState } from 'react';
import {IonRow, IonCol, IonItem } from '@ionic/react';
import './QuantityInput.scss'; //Styling for plus minus buttons used in MopFooterMenuItem.tsx

interface IQuantityInput {
    value: number;
    subtractButtonColour?: string;  //grey, green
    addButtonColour?: string;
    valueColour?: string;   //white
    disableAddQuantityButton?: boolean;
    disableDecreaseQuantityButton?: boolean;
    onChange: (value: number) => void;
    className?: string;
}

const QuantityInput: React.FC<IQuantityInput> = ({value, onChange, subtractButtonColour, addButtonColour, disableAddQuantityButton, disableDecreaseQuantityButton, valueColour, className}) => {

    return (
        <IonItem lines="none" className={`ion-no-padding QuantityInputWrapper ${className != undefined ? className : ""}`}>
            <IonCol size="4" className={`ion-no-padding QuantityButtonSubtract`}>
                <div className={`QuantityButton ${disableDecreaseQuantityButton && "QuantityDisabled"} QuantityButtonColour__${subtractButtonColour != undefined ? subtractButtonColour : "default"}`} 
                onClick={() => {if(value > 0 && !disableDecreaseQuantityButton){
                    onChange(value - 1);
                } }}>-</div>
            </IonCol>
            <IonCol size="4" className={`ion-no-padding QuantityValueColour__${valueColour != undefined ? valueColour : "black"}`}>
                <div className="QuantityCount"><div className="QuantityNumber">{value}</div></div>
            </IonCol>
            <IonCol size="4" className={`ion-no-padding QuantityButtonAdd`}>
                <div className={`QuantityButton ${disableAddQuantityButton && "QuantityDisabled"} QuantityButtonColour__${addButtonColour != undefined ? addButtonColour : "default"}`} 
                onClick={() => { if(!disableAddQuantityButton) { onChange(value + 1) } }}>+</div>
            </IonCol>
        </IonItem>
    );
};

export default QuantityInput;