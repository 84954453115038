import React, { createRef, FormEvent, SyntheticEvent, useState } from 'react';
import { IonPage, IonLoading, IonButton, IonInput, IonItem, IonLabel, IonDatetime, IonToast, IonSpinner, IonContent} from '@ionic/react';
import AppHeader from './AppHeader';
import './CheckoutPayCard.scss';
import {
    Formik,
    FormikProps,
    Field,
    FieldProps,
  } from 'formik';

import moment from 'moment';

import { cardCheckoutSchema } from '../helpers/formValidationHelpers';

import { connect } from 'react-redux';
import * as BasketStore from '../store/BasketStore';
import * as HeaderFooterStore from '../store/HeaderFooterStore';
import { ApplicationState } from '../store';
import { withRouter } from "react-router-dom";
import { Redirect, RouteComponentProps } from "react-router";
import { baseUrl } from '../helpers/configHelper';
import {  getConfig } from '../helpers/configHelper';
import { CreatePaymentRequest, IMopCheckoutRequest, ICardRequest, IMopCheckoutResponse } from '../helpers/checkoutHelper';
import PayerAuthentication, { PayerAuthenticationHelpers } from '../helpers/PayerAuthentication';
import jwtDecode from 'jwt-decode';
import { on } from 'stream';


declare global {
    interface Window {
        Flex: any;
        hideStepUpScreen: any;
        listenToAuthentication?: (e: MessageEvent) => void;
    }
}
  
interface ChecoutInitResponse { //Token returned when starting checkout process (first landing on page)
    jwk: string;
}

interface CheckoutFormRequest {
    Name: string;
    Expiry: string;
}

interface IProps {
    Basket: IMopBasket,
    Email: string,
    ChannelIdentifier: any,
    TimeSlot: TimeSlot,
    appState: ApplicationState
}

type CheckoutMergeProps = 
    typeof BasketStore.actionCreators
    & typeof HeaderFooterStore.actionCreators
    & RouteComponentProps
    & IProps

interface IState {
    renderIframe: boolean;
    isPageLoading: boolean;
    isPageValid: boolean;
    isPayLoading: boolean;
    isCreatingPaymentRequest: boolean;
    fields: {
        number: any,
        ccv: any
    },
    customerInformation: {
        cardExpiryDate: string
    },
    toast: {
        isToastOpen: boolean;
        toastMessage: string;
    },    
    payerAuth: {
        deviceDataCollectionUrl: string,
        accessToken: string,
        referenceId: string
    },
    payerAuthEnrolled: {
        stepUpUrl: string,
        accessToken: string,
    }
    microform: any;
    token: string;
    errorMessage: string | undefined;
    disablePayButton: boolean;
    serverBasket: IMopBasket | undefined;
    cvvError:string |undefined;
    numberError:string | undefined;

}

function ErrorIcon () {
    return (
<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M8.49984 1.41669C4.58984 1.41669 1.4165 4.59002 1.4165 8.50002C1.4165 12.41 4.58984 15.5834 8.49984 15.5834C12.4098 15.5834 15.5832 12.41 15.5832 8.50002C15.5832 4.59002 12.4098 1.41669 8.49984 1.41669ZM7.7915 5.66669C7.7915 5.2771 8.11025 4.95835 8.49984 4.95835C8.88942 4.95835 9.20817 5.2771 9.20817 5.66669L9.20817 8.50002C9.20817 8.8896 8.88942 9.20835 8.49984 9.20835C8.11025 9.20835 7.7915 8.8896 7.7915 8.50002V5.66669ZM9.20817 12.0417V10.625L7.7915 10.625L7.7915 12.0417L9.20817 12.0417ZM2.83317 8.50002C2.83317 11.6238 5.37609 14.1667 8.49984 14.1667C11.6236 14.1667 14.1665 11.6238 14.1665 8.50002C14.1665 5.37627 11.6236 2.83335 8.49984 2.83335C5.37609 2.83335 2.83317 5.37627 2.83317 8.50002Z" fill="#C80000"/>
</svg>
    );
}


/*
    Test Payment Gateway
    https://developer.cybersource.com/api/developer-guides/dita-flex/SAFlexibleToken/FlexMicroform/get-started/setting-up-client-side.html
         ^- Microform Docs
*/
class CheckoutPayCard extends React.Component<CheckoutMergeProps, IState> {

    //font-family: 'MorrisonsAgenda', this won't work in iFrame
    private myStyles = {

        'input': {
            'font-size': '18px',
            'font-family': 'Roboto,helvetica, tahoma, calibri, sans-serif',
            'color': '#262626',
            'opacity':'0.8',
            'height': '55px !important'
        },
        //':focus': { 'color': 'blue' },
        ':disabled': { 'cursor': 'not-allowed' },
        'valid': { 'color': 'rgb(38, 38, 38)' },
        'invalid': { 'color': '#C80000' }
    };

    private formRef = createRef<HTMLFormElement>();
    private stepUpForm = createRef<HTMLFormElement>();
    private stepUpIframe = createRef<HTMLIFrameElement>();
    private deviceCollectionIframe = createRef<HTMLIFrameElement>();

    private payerAuthRef = createRef<PayerAuthenticationHelpers>();

    private pageWrapper = createRef<HTMLIonContentElement>();

    controller = new AbortController();


    constructor(props: CheckoutMergeProps & FormikProps<CheckoutFormRequest>){
        super(props);

        this.state = {
            renderIframe: true,
            isCreatingPaymentRequest: false,
            isPageValid: true,
            isPageLoading: true,
            isPayLoading: false,
            microform: undefined,
            fields: {
                number: undefined,
                ccv: undefined,
            },
            customerInformation: {
                cardExpiryDate: "",
            },
            toast: {
                isToastOpen: false,
                toastMessage: "",
            },            
            payerAuth: {
                deviceDataCollectionUrl: "",
                accessToken: "",
                referenceId: ""
            },
            payerAuthEnrolled: {
                stepUpUrl: "",
                accessToken: ""
            },
            token: "",
            errorMessage: undefined,
            disablePayButton: false,
            serverBasket: undefined,
            cvvError:undefined,
            numberError:undefined
        };
    } 
    ;
    componentDidMount() {
        this.InitCheckout();
    }   

    componentWillUnmount() {
        this.controller.abort();
    }

    componentDidUpdate(prevProps: IProps, prevState: IState){
        if(this.state.microform !== prevState.microform){
            //console.log("-----Loading microform fields");
            this.state.fields.number.load('#number-container');
            this.state.fields.ccv.load('#securityCode-container');
        }
    }

    InitCheckout() {
        if(this.state.fields.number != undefined){
            this.state.fields.number.unload();
            this.state.fields.ccv.unload();
        }

        let that = this;

        fetch(baseUrl()+'/api/checkout/InitCheckout') //Get capture context of checkout as Jwk token
            .then(response => response.json() as Promise<ChecoutInitResponse>)
            .then(data => {
                var flex: any = new window.Flex(data.jwk);  //Use Flex Microform from script in HTML, delcared at top of page, takes in captureContext string
                var microform = flex.microform({ styles: this.myStyles });   //only property in docs for microform options object is styles
                var number = microform.createField('number', { placeholder: 'XXXX-XXXX-XXXX-XXXX' }); //Create iFrames
                var securityCode = microform.createField('securityCode', { placeholder: 'XXX' });

                number.on('focus', function (data:any) {
                    that.setState({numberError:undefined});
                });

                securityCode.on('focus', function (data:any) {
                    that.setState({cvvError:undefined});
                });

                this.setState({
                    isPageLoading: false,
                    fields: {
                        number: number,
                        ccv: securityCode
                    },
                    microform: microform    //Store microform so it can be used when form is submitted to create token
                });  
            })
    }

    onPaymentError(error: string){
        this.unLockUi();
        this.InitCheckout();
        this.setState({
            isPayLoading: false,
            toast: {
                isToastOpen: true,
                toastMessage: error
            }
            //errorMessage?
        })
    }

    onPaymentSuccess(checkoutResponse: IMopCheckoutResponse){
        this.unLockUi();
        this.props.history.push(`/payment/success`);
        this.props.updateOrderNumber(checkoutResponse.checkoutRecord.orderNumber);
        let self = this;
        this.props.updateTempBasket(() => {
            self.props.clearBasket();
        })                            

        this.setState({
            isPayLoading: false
        })
    }

    onReceivedCardToken(token: string) {
        if(this.payerAuthRef.current){
            const decodedFlexObj = jwtDecode(token) as FlexTokenInformation;
            this.payerAuthRef.current.Authenticate({
                cardSummary: {
                    cardDetails: decodedFlexObj.data,
                    transactionType: "Card"
                },
                authPayInfo: {
                    flexTokenInformation: decodedFlexObj
                },
                processingInformation: {
                    authorizationOptions:{
                        ignoreCsvResult: false
                    }
                }
            });
        }
        else{
            console.log("payerAuthRef.current undefined");
        }
        
    }

    lockUI(){
        this.props.setShowBack(false);
        this.setState({
            isPayLoading: true
        })
    }

    unLockUi(){
        this.props.setShowBack(true);
        this.setState({
            isPayLoading: false
        })
    }

    render() {
        const { isPageLoading, isPayLoading } = this.state;

        const initialValues: CheckoutFormRequest = { Name: '', Expiry: ''};

        // START - THIS MUST BE UNCOMMENTED (MTR)
        if(this.props.Basket.menuItems.length < 1 && this.props.location.pathname == "/payment"){
            window.location.href = baseUrl() +"/splash";
        }
       // END - THIS MUST BE UNCOMMENTED (MTR)
        
        return (
            <IonContent ref={this.pageWrapper}>

                <PayerAuthentication
                    ref={this.payerAuthRef}
                    onPaymentSuccess={this.onPaymentSuccess.bind(this)}
                    onError={this.onPaymentError.bind(this)}                    
                />


                <div className="CheckoutPayCard__wrapper">

                    <IonLoading isOpen={isPageLoading} message={"Please wait..."}/>
                    {
                        !isPageLoading &&
                        <div>

                            <Formik
                                initialValues={initialValues}
                                validationSchema={cardCheckoutSchema}
                                onSubmit={(values, {}) => {
                                    //console.log(values.Name, moment(values.Expiry).format("MM[/]YYYY")); 
                                    this.setState({
                                        isPayLoading: true,
                                        numberError:undefined,
                                        cvvError:undefined
                                    });
                                    let dates = moment(values.Expiry).format("MM[/]YYYY").split("/");
                                    let options = {
                                        expirationMonth: dates[0],
                                        expirationYear: dates[1]
                                    }
                                    //when form is submitted
                                    this.state.microform.createToken(options, (err: any, token: any) => {
                                        if(err){
                                            console.log(err);
                                            this.setState({
                                            //    toast: {
                                            //        toastMessage: "Please enter your details again.",
                                            //        isToastOpen: true
                                            //    },
                                                isPayLoading: false
                                            })

                                            if(err.details.find((f:any) => f.location === "number" )) {
                                                this.setState({numberError: "Please enter a valid card number"});
                                            }

                                            if(err.details.find((f:any) => f.location === "securityCode")) {
                                                this.setState({cvvError: "Please enter the CVV number on the back of the card"});
                                            }

                                            this.state.fields.ccv.load('#securityCode-container');
                                            this.state.fields.number.load('#number-container');
                                            //Display error);
                                        }else{
                                            //console.log(token);
                                            this.onReceivedCardToken(token);
                                        }
                                    });
                                }}>
                                {props => (
                                    <form onSubmit={props.handleSubmit} className="Checkout__form">
                                        <Field name="Name">
                                            {({ field, form, meta }: FieldProps) => (
                                                <div>
                                                    <IonLabel>Name on card<span className="required">*</span></IonLabel>
                                                    <IonInput className={`nameInput ${meta?.error && " error-border"}`} name={field.name} value={field.value} onIonChange={field.onChange} 
                                                    enterkeyhint="next" autoCapitalize="true" autofocus={true} inputMode="text" placeholder="Name on card"/> 
                                                    {meta?.error && <div className="field-error-message"><ErrorIcon/>{meta?.error}</div>}
                                                </div>
                                            )}
                                        </Field>
                                        <div className="number-outer">
                                            <IonLabel>Card number<span className="required">*</span></IonLabel>
                                            <div id="number-container" 
                                            className={`form-control ${this.state.numberError && " error-border"}` }
                                            ></div>
                                            {this.state.numberError && <div className="field-error-message"><ErrorIcon/>{this.state.numberError}</div>}
                                        </div>                                                        
                                        <Field name="Expiry">
                                            {({ field, form, meta }: FieldProps) => (
                                                <div>
                                                    <IonLabel>Expiry date<span className="required">*</span></IonLabel>
                                                    <IonDatetime 
                                                    className={`${meta?.error && " error-border"}`}
                                                    
                                                    name={field.name} value={field.value} onIonChange={field.onChange} min={moment().format('YYYY')} max={moment().add(10, 'y').format('YYYY')} displayFormat="MM/YY" placeholder="MM/YY"/>
                                                    {meta?.error && <div className="field-error-message"><ErrorIcon/>{meta?.error}</div>}
                                                </div>
                                            )}
                                        </Field>
                                        <div className="cvv-outer">
                                            <IonLabel>CVV<span className="required">*</span></IonLabel>
                                            <div id="securityCode-container" className={`form-control ${this.state.cvvError && " error-border"}` }></div>
                                            {this.state.cvvError && <div className="field-error-message"><ErrorIcon/>{this.state.cvvError}</div>}
                                        </div>

                                        <div className="pay-footer-container">
                                            <div className="pay-footer">
                                            {
                                                    this.state.errorMessage ?
                                                        <div className="error-message">{this.state.errorMessage}</div>
                                                    :
                                                        <IonButton className="large-button" color={getConfig().isMarket ? "secondary" : "primary"} size="large" type="submit" disabled={isPayLoading}>{isPayLoading ? <IonSpinner /> : "Pay"}</IonButton>
                                            }
                                            </div>                                            
                                        </div>
                                    </form>
                                )}
                            </Formik>

                            <IonToast
                            cssClass="card-payment-toast"
                                isOpen={this.state.toast.isToastOpen}
                                duration={10000}
                                message={this.state.toast.toastMessage}
                                onDidDismiss={(e) => {this.setState({
                                    toast: {
                                        isToastOpen: false,
                                        toastMessage: ""
                                    }
                                })}}/>
                        </div>
                    }                
                </div>
            </IonContent>
        )
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: any) =>({
    Basket: state.basket?.basket,
    Email: state.basket?.email,
    ChannelIdentifier: state.location?.channelIdentifier,
    TimeSlot: state.location?.selectedTimeSlot,
    appState: state
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearBasket: () => dispatch(BasketStore.actionCreators.clearBasketItems()),
        updateTempBasket: (callback: () => void) => dispatch(BasketStore.actionCreators.updateTempBasket(callback)),
        updateOrderNumber: (orderNumber: string) => dispatch(BasketStore.actionCreators.updateOrderNumber(orderNumber)),
        setShowBack: (showBack: boolean) => dispatch(HeaderFooterStore.actionCreators.setShowBack(showBack))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CheckoutPayCard as any));