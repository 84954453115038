import React, { useEffect, useState } from 'react';
import './AltQuantityInput.scss'; //Styling for plus minus buttons used in MopFooterMenuItem.tsx

interface IAltQuantityInput {
    value: number;
    subtractButtonColour?: string;  //grey, green
    addButtonColour?: string;
    valueColour?: string;   //white
    disableAddQuantityButton?: boolean;
    disableDecreaseQuantityButton?: boolean;
    onChange: (value: number) => void;
    className?: string;
}


// Will rename this to QuantityInput later in the re-style (MTR)

const AltQuantityInput: React.FC<IAltQuantityInput> = (
    {   value, 
        onChange, 
        subtractButtonColour, 
        addButtonColour, 
        disableAddQuantityButton, 
        disableDecreaseQuantityButton, 
        valueColour, 
        className
    }) => {

    const onClickDecrease = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(!disableDecreaseQuantityButton && value > 0){
            //event.stopPropagation();
            onChange(value - 1);
        }
    }

    const onClickAdd = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(!disableAddQuantityButton){
            //event.stopPropagation();
            onChange(value + 1);
        }
    }

    console.log(`disableDecreaseQuantityButton = ${disableDecreaseQuantityButton} / disableAddQuantityButton = ${disableAddQuantityButton} / ${value}`);

    return(
        <div className="alt-quantity-select">
            <div className={`decrease ${disableDecreaseQuantityButton && "disabled"}`}  onClick={onClickDecrease}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.42578 10.0131C4.42578 10.4644 4.93996 10.8336 5.56841 10.8336L14.7094 10.8336C15.3379 10.8336 15.852 10.4644 15.852 10.0131C15.852 9.5618 15.3379 9.19257 14.7094 9.19257L5.56841 9.19257C4.93996 9.19257 4.42578 9.5618 4.42578 10.0131Z" fill="#262626"/>
                </svg>
            </div>
            <div className="value">{value}</div>
            <div className={`add ${disableAddQuantityButton && "disabled"}`} onClick={onClickAdd}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.7576 10.8366L10.6768 10.8366L10.6768 14.9391C10.6768 15.3904 10.3095 15.7596 9.86059 15.7596C9.4117 15.7596 9.04443 15.3904 9.04443 14.9391L9.04443 10.8366L4.96362 10.8366C4.51473 10.8366 4.14746 10.4673 4.14746 10.016C4.14746 9.56477 4.51473 9.19554 4.96362 9.19554L9.04443 9.19554L9.04443 5.09297C9.04443 4.64169 9.4117 4.27246 9.86059 4.27246C10.3095 4.27246 10.6768 4.64169 10.6768 5.09297L10.6768 9.19554L14.7576 9.19554C15.2064 9.19554 15.5737 9.56477 15.5737 10.0161C15.5737 10.4673 15.2064 10.8366 14.7576 10.8366Z" fill="white"/>
                </svg>
            </div>
        </div>
    );
};

export default AltQuantityInput;

/*


const AltQuantityInput: React.FC<IAltQuantityInput> = ({value, onChange, subtractButtonColour, addButtonColour, disableAddQuantityButton, disableDecreaseQuantityButton, valueColour, className}) => {

    return (
        <IonItem lines="none" className={`ion-no-padding QuantityInputWrapper ${className != undefined ? className : ""}`}>
            <IonCol size="4" className={`ion-no-padding QuantityButtonSubtract`}>
                <div className={`QuantityButton ${disableDecreaseQuantityButton && "QuantityDisabled"} QuantityButtonColour__${subtractButtonColour != undefined ? subtractButtonColour : "default"}`} 
                    onClick={() => {
                        if(value > 0 && !disableDecreaseQuantityButton){
                            onChange(value - 1);
                        } }}>-</div>
            </IonCol>
            <IonCol size="4" className={`ion-no-padding QuantityValueColour__${valueColour != undefined ? valueColour : "black"}`}>
                <div className="QuantityCount"><div className="QuantityNumber">{value}</div></div>
            </IonCol>
            <IonCol size="4" className={`ion-no-padding QuantityButtonAdd`}>
                <div className={`QuantityButton ${disableAddQuantityButton && "QuantityDisabled"} QuantityButtonColour__${addButtonColour != undefined ? addButtonColour : "default"}`} 
                onClick={() => { if(!disableAddQuantityButton) { onChange(value + 1) } }}>+</div>
            </IonCol>
        </IonItem>
    );
};



*/