import React, { useState } from 'react';
import { IonInput } from '@ionic/react';
import {
    Field,
    FieldInputProps,
    FieldProps,
    FormikProps,
  } from 'formik';

interface GenericInputProps {
    name: string;
    className?: string;
    IonInputProps?: any;
    onError?: () => void;
    onIonChange?: (event: CustomEvent<any>, field: FieldInputProps<any>, form: FormikProps<any>) => void;
}

const GenericInput = ({ name, IonInputProps, className, onError, onIonChange}: GenericInputProps) => {

    //IonInputProps = { type="email", autofocus={true} } placeholder="Enter email..."
    //className="emailInput " 
    
    return (
        <Field name={name}>
            {({ field, meta, form }: FieldProps) => {
                var error = (meta.error && meta.touched) ? meta.error : false;
                if(error){
                    onError && onError();
                }
                return  <div className="fieldWrapper">
                            <IonInput {...IonInputProps} className={`${className} ${error && "fieldError"}`}  name={field.name} value={field.value} onIonChange={(e) => { onIonChange != undefined ? onIonChange(e, field, form) : field.onChange(e); }}></IonInput>    
                            {error && <div className="fieldErrorMessage">{error}</div> }  
                        </div>
            }}
        </Field>                    
    )
};

export default GenericInput;