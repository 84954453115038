import React, { useEffect, useState } from 'react';
import { IonHeader, IonTitle, IonToolbar, IonIcon, IonGrid, IonRow, IonCol, IonModal, IonContent } from '@ionic/react';
import './MopHeaderMenusModal.scss';
import { closeCircle, chevronForward, menu } from 'ionicons/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import '../theme/common.scss';
import * as headerFooterStore from '../store/HeaderFooterStore';



const AllergenInfoModal: React.FC<{ isModalOpen: boolean, closeModalClick: any,allergenHtml:string | undefined }> = ({ isModalOpen, closeModalClick,allergenHtml }) => {

    const modalCss = ['AppHeaderMenu','allergen-modal']

    return (
        <IonModal isOpen={isModalOpen}
            cssClass={modalCss}
            onDidDismiss={closeModalClick}
            swipeToClose={true}>
            <IonHeader>
                <IonIcon className="CloseButton" color="primary" icon={closeCircle} onClick={closeModalClick} ></IonIcon>
            </IonHeader>
            <IonContent>
                <div className="AllergenInfoContent">
                    <span dangerouslySetInnerHTML={{__html: allergenHtml?allergenHtml:'No information available'}} />
                </div>
                <div className="AllergenInfoContent"><p>DUE TO THE WAY WE PREPARE OUR FOOD, IT IS POSSIBLE THAT OTHER ALLERGENS ARE PRESENT</p></div>
            </IonContent>
        </IonModal>
    );
};

export default AllergenInfoModal;
