// MTR: GET RID OF THIS BEFORE FINAL BITS

export interface MenuDataState {
    isLoading: boolean;
    categoryId: number | null;
    title: string;
    categoryListA: CategoryList | null;
    categoryListB: CategoryList | null;
    categoryCurrent: CategoryList | null;
    isFirstAccess: boolean;
}

export interface CategoryList {
    categoryId: number;
    categories: MopCategory[];
    menuItems: MopMenuItem[];
    categoryTitle: string;
    menuDisplayType: number;
    auxCategoryData?: AuxCategoryData;
    isFavourites?: boolean;
}

export interface AuxCategoryData {
    categoryBackgroundColour?: string,
    categoryFontColour?: string,
    hideCategoryHeader?: boolean,
    priceLocation?: PriceLocations,
    categoryFooter?: FooterImage,
    categoryImageName?: string;
    fullWidthSkus?: string[];
    description?: string,
    displayType?: MenuDisplayType
    promotionalButtons?: HeaderPromoImage[];
}

export interface HeaderPromoImage {
    buttonImage: string;
    buttonLinkType: ButtonLinkTypeEnum;
    buttonLinkId: string;
    buttonLinkName: string;
}

export enum ButtonLinkTypeEnum {
    Category,
    MenuItem
}

export interface PriceLocations {
    top?: string,
    left?: string,
    right?: string,
    backgroundColour?: string,
    hiddenPriceSkus: string[]
}

interface FooterImage {
    imageName: string,
    imageLink: string
}

export enum MenuDisplayType {
    BasicList = 0,
    BasicGrid,
    ProductGrid
}

export interface MopCategory {
    categoryId: number;
    categoryName: string;
    categoryImage: string;
    auxData: MopCategorySettings | null;
    menuItemIds?: number[];
    subCategoryIds?: number[];
    isFavourites?: boolean;
}

export interface MopCategorySettings {
    fullWidth: boolean;
}

export interface MopMenuItem {
    menuItemId: number;
    description: string;
    menuItemName: string;
    menuItemImage: string;
    sku: string;
    itemTags: string;
    menuItemBasePrice: number;
    menuItemFromPrice: number | null;
    inStock: boolean;
    kcal?: number;
    categoryLevelFilter: string;
	plpRoundels: string[];
}
