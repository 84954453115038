import React from 'react';
import { IonRow, IonCol, IonButton } from '@ionic/react';
import './MopFooterUpsellPage.scss';

import { ApplicationState } from '../store';
import { useSelector, useDispatch } from 'react-redux';
import * as BasketStore from '../store/BasketStore';
import { bindActionCreators } from 'redux';

import { simpleChannelType } from '../store/LocationStore';

const MopFooterBasketPage: React.FC = () => {
    const locationState = useSelector((state: ApplicationState) => state.location);
    const menuItemData = useSelector((state: ApplicationState) => state.menuItemData);

    const setShowAllergenPopup = bindActionCreators(BasketStore.actionCreators.showAllergenPopup, useDispatch());

    const goToNextPage = () => {
        setShowAllergenPopup(true);
    };

    return (
        <div className="MopFooterInner">
            { menuItemData?.menuItem &&
                <IonRow className="ion-align-items-center">
                    <IonCol size="6" className="FooterStoreWrapper">
                        <span className="CollectFrom">{locationState?.selectedChannel === undefined ? "Store" : locationState.selectedChannel.channelType === simpleChannelType.ClickAndCollect ? "Take away" : "Eat in" }</span><br/>
                        <span className="Location">{locationState?.selectedStore === undefined ? "Select a store" : locationState?.selectedStore.storeName}</span>
                        {locationState?.selectedStore?.takingMopOrders === false ? <span style={{ color: "#ff0" }}> - Order ahead not available</span> : null}
                    </IonCol>
                    <IonCol size="6">
                        <IonButton expand="block" size="small" color="light" onClick={() => { goToNextPage(); }}>
                           <span>Continue</span>
                        </IonButton>
                    </IonCol>
                </IonRow>
            }
        </div>
    );
};

export default MopFooterBasketPage;
