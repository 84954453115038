import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { IonItem, IonLabel, IonPage, IonSpinner } from '@ionic/react';
import AppHeader from '../components/AppHeader';

import CategoryMenuItemLister from '../components/CategoryMenuItemLister';
import './Categories.scss';
import * as MenuDataStore from '../store/MenuStore';
import * as HeaderFooterStore from '../store/HeaderFooterStore';
import * as MenuItemDataStore from '../store/MenuItemStore';
import { ApplicationState } from '../store';
import { refreshOutline } from 'ionicons/icons';

import { MenuDataState } from '../store/MenuStoreTypes';

interface CategoryProps {
    useStoreA: boolean
    menuData: MenuDataState
    productData: MenuItemDataStore.MenuItemDataState
    headerData: HeaderFooterStore.HeaderFooterState
    pfIdentifier: string | undefined
}

interface CategoryState {
    isLoading: boolean;
}

// At runtime, Redux will merge together...
type CategoriesProps =
    typeof MenuDataStore.actionCreators // ... plus action creators we've requested
    & typeof MenuItemDataStore.actionCreators // ... plus action creators we've requested
    & typeof HeaderFooterStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ categoryIdentifier: string }>
    & CategoryProps; // ... plus incoming routing parameters


class Categories extends React.Component<CategoriesProps, CategoryState> {

    constructor(props: CategoriesProps){
        super(props);
        this.state = {
            isLoading: false
        };
    }

    componentDidMount() {
        let requiredId = +this.props.match.params.categoryIdentifier;

        if (this.props.menuData.categoryId == requiredId) {
            console.debug("Showing correct content");
        } else {
            console.debug("Not Showing correct content - force load");
            this.setState({
                isLoading: true
            })
            this.props.requestCategoryList(
                +this.props.match.params.categoryIdentifier,
                this.props.useStoreA ? "a" : "b",
                this.props.pfIdentifier ?? "",
                () => {
                    this.setState({
                        isLoading: false
                    }, () => {
                        console.log("Setting state to false", this.state.isLoading);
                    });
                },
            );
        }

        this.props.useStoreA && this.props.history.listen((location, action) => {
            // location is an object like window.location
            if (action == "POP") {

                let breakUrl = location.pathname.split("/");
                let currentCatgegoryId: number = 0;
                if (breakUrl.length == 3) {
                    currentCatgegoryId = +breakUrl[2];
                }
                this.props.setCurrentCategory(currentCatgegoryId);
            }
            console.debug(action, location.pathname, location.state)
        });

        //this.props.setTitle("Menu",false);
        console.debug("Mount");
    }

    componentWillUnmount() {
        console.debug("Unmount");
    }

    onReceiveCategoryList(categoryId: number) {
        if (this.props.menuData.categoryCurrent) {
            this.props.history.push(`/categories${this.props.useStoreA ? "b" : ""}/${categoryId}`);
        }
        else {
            console.debug("No items in categoryCurrent");
        }
    }

    navigateToCategory(categoryId: number) {
        if (this.props.useStoreA) {
            this.props.requestCategoryList(
                categoryId, "b", this.props.pfIdentifier ?? "",
                () => { this.onReceiveCategoryList(categoryId) }
            );
        } else {
            this.props.requestCategoryList(
                categoryId, "a", this.props.pfIdentifier ?? "",
                () => { this.onReceiveCategoryList(categoryId) }
            );
        }
    }

    navigateToMenuItem(menuItemId: number) {
        console.debug("Navigating to ", menuItemId);
        this.props.requestMenuItem(menuItemId, this.props.pfIdentifier ?? "", "", () => {
            this.props.history.push(`/menuitem/${menuItemId}`);
        });
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {

        const { isLoading, categoryListA, categoryListB, categoryId } = this.props.menuData;
        let categoryList = this.props.useStoreA ? categoryListA : categoryListB

        const pfIdentifierValue = this.props.pfIdentifier;
        let isColleague = pfIdentifierValue ? true : false;

        console.debug(categoryList, this.props.useStoreA, this.state.isLoading, "isLoading:", isLoading, " isColleague: ", isColleague);
        return (
            <IonPage>
                {this.state.isLoading
                    ?
                    <div className="SpinnerContainer">
                        <IonSpinner color="primary" name="lines" />
                    </div>
                    :
                    <CategoryMenuItemLister
                        categoryList={categoryList}
                        isLoading={isLoading}
                        onNavigateToCategory={this.navigateToCategory.bind(this)}
                        onNavigateToMenuItem={this.navigateToMenuItem.bind(this)}
                        isColleague={isColleague}
                    />
                }
            </IonPage>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
    menuData: state.menuData,
    useStoreA: ownProps.useStoreA,
    pfIdentifier: localStorage.getItem("pfIdentifier"),
});

const mapDispatchToProps = (
    dispatch: any) => {
    return {
        requestCategoryList: (categoryId: number, container: string, pfIdentifier: string, callback: any) => dispatch(MenuDataStore.actionCreators.requestCategoryList(categoryId, container, pfIdentifier, callback)),
        setCurrentCategory: (categoryId: number) => dispatch(MenuDataStore.actionCreators.setCurrentCategory(categoryId)),
        setTitle: (title: string, showBack: boolean) => dispatch(HeaderFooterStore.actionCreators.setTitle(title, showBack)),
        requestMenuItem: (menuItemId: number, pfIdentifier: string, referer: string | null, callback: any) => dispatch(MenuItemDataStore.actionCreators.requestMenuItem(menuItemId, pfIdentifier, "", callback))
    };
};

export default connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    mapDispatchToProps    // Selects which action creators are merged into the component's props,
)(Categories as any);
