import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { baseUrl, getConfig } from '../helpers/configHelper';
import { ApplicationState } from '../store';
import { simpleChannelType } from '../store/LocationStore';

interface IProps {
	headerTitle?: string //If undefined, use the default header defined below.
}

/*
	Default Logos
		Generic
		EatIn
		Takeaway
*/

export enum LogoTitles {
	Default = "Default",
	Takeaway = "Takeaway",
	EatIn = "EatIn"
};

const AppHeaderImage: React.FC<IProps> = ({ headerTitle = "Menu" }) => {  //Menu is current Default header 

	const [showLogo, setShowLogo] = useState(true);
	const [logoTitle, setLogoTitle] = useState("default");
	const locationState = useSelector((state: ApplicationState) => state.location);
	const location = useLocation();

	useEffect(() => {
		if (headerTitle == "Menu" && !getConfig().isMarket) {
			setShowLogo(true);
		}
		else {
			setShowLogo(false);
		}
	}, [headerTitle]);

	useEffect(() => {
		let _logoTitle = LogoTitles.Default;
		switch (locationState?.selectedChannel?.channelType) {
			case simpleChannelType.ClickAndCollect:
				_logoTitle = LogoTitles.Takeaway;
				break;
			case simpleChannelType.TableOrder:
				//_logoTitle = "eatin";
				break;
		}

		switch (location.pathname) {
			case '/ordertype':
				_logoTitle = LogoTitles.Default;
				break;
		}

		setLogoTitle(_logoTitle);
	}, [locationState?.selectedChannel, location.pathname]);

	const ListerHeader = styled.div`
        display:block;
        background-color: var(--ion-color-secondary);
        background-size: contain;
		background-repeat: no-repeat;
		width: 100%;
		height: 0;
		padding-top: 25%;
        background-image:url('${baseUrl()}/images/assets/category_header_${headerTitle}_${getConfig().imageThemeID}.png');
    `;


	const ListerLogo = styled.div`
        margin:0 auto 0 10%;
        width:30%;
        height:100%;
        background-position:center center;
        background-size: contain;
        transform: scale(0.8);
        background-repeat:no-repeat;
        background-image:url('${baseUrl}/images/assets/Logo_${getConfig().imageThemeID}_${logoTitle}.png');
    `;

	return (
        localStorage.getItem('isLoggedIn') !== "true" ? <ListerHeader>
			{showLogo && <ListerLogo />}
		</ListerHeader>
        :
        <></>
	)
}

export default AppHeaderImage;
