import React, { RefObject, useEffect, useRef, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonContent, IonButton, IonPage, IonItem, IonSpinner } from '@ionic/react';
import AppHeader from '../components/AppHeader';
import GooglePay from '../components/GooglePay';
import './OrderConfirmation.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { ApplicationState } from '../store';
import BasketItemRow from '../components/BasketItemRow';
import { formatCurrency } from '../helpers/currencyHelpers';
import { CreatePaymentRequest, IAuthenticatePaymentRequest, IDigitalRequest, IMopCheckoutRequest, IMopCheckoutResponse } from '../helpers/checkoutHelper';
import { State } from 'ionicons/dist/types/stencil-public-runtime';
import { Base64 } from 'js-base64';
import { bindActionCreators } from 'redux';
import * as basketActions from '../store/BasketStore';
import { Redirect, useLocation } from "react-router";
import ApplePay from '../components/ApplePay';
import { baseUrl } from '../helpers/configHelper';
import "../components/ApplePay.scss";
import { STATUS_CODES } from 'http';
import { getConfig } from '../helpers/configHelper';
import { simpleChannelType } from '../store/LocationStore';
import PayerAuthentication, { CardSummary, PayerAuthenticationHelpers } from '../helpers/PayerAuthentication';

enum TransactionType {
    GooglePay = "Google Pay",
    ApplePay = "Apple Pay"
}

const OrderConfirmation: React.FC = () => {

    let history = useHistory();
    const location = useLocation();
    const basketStore = useSelector((state: ApplicationState) => state.basket);
    const locationState = useSelector((state: ApplicationState) => state.location);
    const appState = useSelector((state: ApplicationState) => state);
    const updateTempBasket = bindActionCreators(basketActions.actionCreators.updateTempBasket, useDispatch());
    const clearBasket = bindActionCreators(basketActions.actionCreators.clearBasketItems, useDispatch());
    const updateOrderNumber = bindActionCreators(basketActions.actionCreators.updateOrderNumber, useDispatch());
    const updatePaymentStatus = bindActionCreators(basketActions.actionCreators.updatePaymentStatus, useDispatch());
    const [error, setError] = useState<string>("");
    const [showAppleButton, setShowAppleButton] = useState(false)
    const googlePayButtonRef = useRef<HTMLDivElement>(null);
    const applePayButtonRef = useRef<HTMLDivElement>(null);
    const [paymentOptions, setPaymentOptions] = useState<string[]>([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    //const [paymentsLive, setPaymentsLive] = useState(false);
    const paymentsLive = useRef(false);
    const debugApplePay = useRef(false);

    const stateRef = useRef(basketStore?.paymentStatus);
    stateRef.current = basketStore?.paymentStatus;

    const payerAuthRef = useRef<PayerAuthenticationHelpers>(null);
    

    useEffect(() => {
        fetch(baseUrl() + "/api/checkout/GetAvailablePaymentOptions")
            .then(res => res.json() as Promise<any>)
            .then((data: PaymentOptions) => {
                let res = data.options;
                let pLive = data.livePayments;
                let google = "Google";
                if(res.includes(google)){
                    let navigator: any = window.navigator //Check if running in Home screen App Mode
                    if((navigator.standalone != undefined) && navigator.standalone){
                        res.splice(res.indexOf(google), 1);
                    }
                }
                paymentsLive.current = pLive;
                debugApplePay.current = data.debugApplePay;
                setPaymentOptions(res);
                //setPaymentsLive(paymentsLive);
                
            });
    }, [])

    useEffect(() => {
        if(basketStore?.basket != undefined && basketStore.basket.menuItems.length < 1 && location.pathname == "/orderconfirmation")
        {
            setTimeout(() => {
                window.location.href = baseUrl() + "/splash";   //Restart app
            }, 500);
        }
    }, [location.pathname])

    useEffect(() => {
        if(basketStore?.basket.totalPrice){
            setTotalPrice(basketStore.basket.totalPrice);
        }        
    }, [basketStore?.basket.totalPrice])

    const shouldPaymentStart = (callback: (res: boolean) => void) =>{
        
        let status = stateRef.current;
        //console.log(status);
        switch(status){
            case basketActions.PaymentStatus.REQUESTED:
                callback(false);
                break;
            default:
                callback(true);
                break;
        }
    }

    const onPaymentStart = () => {
        setIsLoading(true);
        updatePaymentStatus(basketActions.PaymentStatus.REQUESTED);
    }

    const onError = (err: string) => {
        console.log(err);
        setError(err);
        setIsLoading(false);
        updatePaymentStatus(basketActions.PaymentStatus.UNSET);
    }

    const onPaymentSuccess = (response: IMopCheckoutResponse) => {

        updatePaymentStatus(basketActions.PaymentStatus.COMPLETE);
        history.push(`/payment/success`);
        updateOrderNumber(response.checkoutRecord.orderNumber);
        updateTempBasket(() => {
            clearBasket();
        })
    }

    const handleApplePayButtonClick = async (paymentToken: ApplePayJS.ApplePayPaymentToken, session: ApplePaySession) => {
        
        var status: number = 0;
        let paymentData = paymentToken.paymentData;
        let paymentData64 = Base64.encode(JSON.stringify(paymentData));
        console.log("Apple Pay button clicked")
        onPaymentStart();

        try{
            if(payerAuthRef.current){
                try{
                    const response = await payerAuthRef.current.CreatePayment({
                        paymentInformation: {
                            fluidData: {
                                descriptor: "RklEPUNPTU1PTi5BUFBMRS5JTkFQUC5QQVlNRU5U",
                                encoding: "Base64",
                                value: paymentData64 
                            }
                        }
                    },{
                        transactionType: "Apple Pay",
                        cardDetails: {
                            number: paymentToken.paymentMethod.displayName,
                        }
                    },  {
                        paymentSolution: "001",
                        authorizationOptions: {
                            ignoreCsvResult: true
                        }
                    });
                    if(response.paymentResult.status == "AUTHORIZED"){
                        status = ApplePaySession.STATUS_SUCCESS;
                    }
                    else{
                        throw new Error("Unhandled Apple Pay error. ERR:UAPE");
                    }
                }
                catch(err){
                    throw err; //rethrow so we can handle payerAuthRef error at the same time
                }
            }
            else{
                console.log("payerAuthRef.current undefined applepay click")
                throw new Error("Unexpected payment error, please try again. ERR:APPAR");
            }
        }
        catch(e){
            let result: string | undefined;
            if (typeof e === "string") {
                result = e;
            } else if (e instanceof Error) {
                result = e.message // works, `e` narrowed to Error
            }
            status = ApplePaySession.STATUS_FAILURE;
            onError(result ?? "Unexpected payment error. ERR:APPUE");
        }
        finally{
            //non apple specific stuff is managed by onPaymentSuccess
            session.completePayment(status);
        }
    }

    const handleGooglePayButtonClick = (paymentData: google.payments.api.PaymentData) => {
        console.log("Got data: ", paymentData);
        let paymentToken = paymentData.paymentMethodData.tokenizationData.token;
        let paymentToken64 = Base64.encode(paymentToken);
        onPaymentStart();

        try{
            if(payerAuthRef.current){
                try{

                    let cardSummary: CardSummary = {
                        transactionType: "Google Pay",
                        cardDetails: {
                            number: paymentData.paymentMethodData.description || "Card using Google Pay"
                        }
                    }
                    let authSetupRequest: IAuthenticatePaymentRequest = {
                        paymentInformation: {
                            fluidData: {
                                descriptor: "RklEPUNPTU1PTi5BUFBMRS5JTkFQUC5QQVlNRU5U",
                                encoding: "Base64",
                                value: paymentToken64 
                            }
                        }
                    }
                    let processingInformation: Ptsv2ProcessingInformation = {
                        paymentSolution: "012",
                        authorizationOptions: {
                            ignoreCsvResult: true
                        }
                    }
                    //Need to perform SCA
                    if(paymentData.paymentMethodData.info && (paymentData.paymentMethodData.info as any).assuranceDetails?.cardHolderAuthenticated != undefined && (paymentData.paymentMethodData.info as any).assuranceDetails?.cardHolderAuthenticated == false){
                        payerAuthRef.current.Authenticate({
                            authPayInfo: authSetupRequest,
                            cardSummary: cardSummary,
                            processingInformation: processingInformation
                        })
                    }
                    else{
                        payerAuthRef.current.CreatePayment(authSetupRequest, cardSummary, processingInformation).catch((err) => {
                            throw err;    
                        });
                    }
                }
                catch(err){
                    throw err; //rethrow so we can handle payerAuthRef error at the same time
                }
            }
            else{
                console.log("payerAuthRef.current undefined googlepay click")
                throw new Error("Unexpected payment error, please try again. ERR:GPPAR");
            }
        }
        catch(e){
            let result: string | undefined;
            if (typeof e === "string") {
                result = e;
            } else if (e instanceof Error) {
                result = e.message // works, `e` narrowed to Error
            }
            onError(result ?? "Unexpected payment error. ERR:APPUE");
        }
    }    
    

    return (
        <IonPage>
            <PayerAuthentication
                onError={onError}
                onPaymentSuccess={onPaymentSuccess}
                ref={payerAuthRef}

            />
            {
                totalPrice > 0 &&
                <>
                {
                    paymentOptions?.map((option, index) => {
                        switch(option){
                            case "Google":
                                return <GooglePay LivePayments={paymentsLive.current} ShouldPaymentStart={shouldPaymentStart} key={option} TotalPrice={totalPrice} GooglePayButtonContainer={googlePayButtonRef.current as HTMLElement} GooglePayButtonClickHandler={handleGooglePayButtonClick} />
                            case "Apple":
                                return <ApplePay Debug={debugApplePay.current} ShouldPaymentStart={shouldPaymentStart} ApplePayButton={applePayButtonRef.current || undefined} key={`${option}`} TotalPrice={totalPrice} SetShowButton={setShowAppleButton} ApplePayButtonClickHandler={handleApplePayButtonClick} />
                        }
                    })
                } 
                </>  
            }            
            <IonContent fullscreen className="order-confirmation-outer">
                <IonGrid className="order-confirmation">
                    <IonRow className="please-choose">
                        <IonCol><div className="please-choose-caption">Please choose your payment method</div></IonCol>
                    </IonRow>
                    <IonRow className="order-total ion-align-items-center">
                        <IonCol>
                            <div className="order-total-caption">Order total</div>
                            <div className="order-total-actual">{formatCurrency(basketStore?.basket.totalPrice)}</div>
                            { error !== undefined && <div className="error-display">{error}</div> } 
                        </IonCol>
                    </IonRow>
                    <IonRow className="payment-method">
                        <IonCol className="OrderConfirmationButtonWrapper">
                            {isLoading && <div className="OrderConfirmationProcessing"><span>Processing Payment</span><IonSpinner /></div> }
                            <div ref={applePayButtonRef} style={{display: showAppleButton ? "block" : "none", height: "40px"}} className="apple-pay-button apple-pay-button-black"></div>
                            <div ref={googlePayButtonRef} className={`${isLoading ? "OrderConfirmationPaymentButtonLoading" : ""}`} style={{ opacity: isLoading ? .6 : 1 }}></div>
                            <IonButton className={`OrderConfirmationPaymentButton ${isLoading ? "OrderConfirmationPaymentButtonLoading" : ""} pay-by-card-button`} style={{ opacity: isLoading ? .6 : 1 }} color="primary" onClick={() => { if(!isLoading) { history.push(`/payment`); } }}>Pay with Card</IonButton>                          
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default OrderConfirmation;