import { IonCol, IonGrid, IonItem, IonButton,IonLabel, IonList, IonRadio, IonRadioGroup, IonRow } from "@ionic/react";
import React from "react";

import { MopMenuItem } from '../store/MenuStoreTypes';
import './CategoryFilters.scss';


const CategoryFilters: React.FC<{ menuItems: MopMenuItem[],selectedFilter: string|undefined | null,onFilterSelected:(selectedFilter:string)=>void }> = ({menuItems,selectedFilter = "",onFilterSelected }) => {



    const matchingMenuItems = menuItems.filter(a => a.categoryLevelFilter);

    const uniqueFilters = matchingMenuItems.map(item => item.categoryLevelFilter)
        .filter((value, index, self) => self.indexOf(value) === index);
    console.log(uniqueFilters);

    return (
        <div className="filterContainer">
            <div className="filterSlider">
                {uniqueFilters.length > 1 && (
                    <div
                        onClick={() => selectedFilter !== "" && onFilterSelected("")}
                        className={"filterButton" + ("" === selectedFilter ? " selectedFilter" : "")}
                        key={"All_Selected"}
                    >
                        All
                    </div>
                )}
                {uniqueFilters.length > 1 && uniqueFilters.map((value: string, index: number) => (
                    <div
                        onClick={() => selectedFilter !== value && onFilterSelected(value)}
                        className={"filterButton" + (value === selectedFilter ? " selectedFilter" : "")}
                        key={"JACK" + index}
                    >
                        {value}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CategoryFilters;