import React, { useEffect, useState } from 'react';
import { IonRow, IonCol, IonContent, IonButton, IonPage, IonItem, IonLabel, IonCheckbox, IonText, IonImg } from '@ionic/react';

import './EnterEmail.scss';
import { useHistory, useLocation } from "react-router-dom";

import {
	Formik,
	FormikProps,
	Field,
	FieldProps,
} from 'formik';


import { connect } from 'react-redux';
import * as BasketStore from '../store/BasketStore';
import { ApplicationState } from '../store';

import { enterEmailSchema } from '../helpers/formValidationHelpers';
import GenericInput from '../inputs/GenericInput';
import 'react-slidedown/lib/slidedown.css'

interface EmailProps {
	email: string
	staffCardNumber: string;
	consent: boolean;
}

type EmailMergeProps =
	typeof BasketStore.actionCreators
	& EmailProps

const EnterEmail: React.FC<EmailMergeProps> = (props: EmailMergeProps) => {

	let history = useHistory();
	const location = useLocation();

	const [showStaffDiscount, setShowStaffDiscount] = useState(false); //localStorage.getItem("staffCardNumber") != null - To show it when we find a staffCardNumber in localStorage 

	const [initialValues, setInitialValues] = useState({});

	useEffect(() => {
		//console.log( "Local storage: ", localStorage.getItem("email"));
		setInitialValues({ email: localStorage.getItem("email") || props.email, staffCardNumber: localStorage.getItem("staffCardNumber") || props.staffCardNumber, consent: localStorage.getItem("consent") == "true" });
	}, [location.pathname])

	return (
		<IonPage>
			<IonContent fullscreen>
				<div className="EnterEmailWrapper">
					<div className="EnterEmailTitle">Where should we send your receipt?</div>
					<Formik
						initialValues={initialValues}
						validationSchema={enterEmailSchema}
						enableReinitialize={true}
						onSubmit={(values, actions) => {
							//console.log("Submitting");
							props.updateBasketDetails(values, () => {
								setTimeout(() => {
									//console.log("Pushing to order");
									history.push({
										pathname: `/orderconfirmation`  //Pushes to confirmation only when basket has been fully updated.
									});
								}, 500);
							});
						}}
					>
						{props => (
							<form onSubmit={props.handleSubmit} className="EmailInputFormWrapper">
								<IonLabel className="inputLabel">{localStorage.getItem("isLoggedIn") === "true" ? "Your Morrisons email address" : "Your email address"}<span>*</span></IonLabel>
								<GenericInput
									name="email"
									className={"emailInput"}
									IonInputProps={{ type: "email", autofocus: true, placeholder: "example@gmail.com" }}
								/>
								<IonLabel className="inputLabel">Your More Card number</IonLabel>
								<GenericInput
								
									name="staffCardNumber"
									className="emailInput"
									IonInputProps={{ placeholder: "XXXX-XXXX-XXXX-XXXX-XXX",type:"text" }}
									onError={() => { setShowStaffDiscount(true) }}
								/>

								<IonText>Earn exclusive offers when you order from Morrisons Market Kitchen. You'll find your number in your app or on the front of your card.</IonText>
								{localStorage.getItem('isLoggedIn') !== "true" && <IonImg alt="morrisons-save-card" src="/images/assets/Morrisons_Save.png?v=2" />}
								<Field name="consent">
									{({ field, meta }: FieldProps) => {
										return <div className="fieldWrapper">
											<IonItem lines="none">
												<IonLabel>Remember my details on this device</IonLabel>
												<IonCheckbox slot="start" name={field.name} checked={field.value} onIonChange={() => { props.setFieldValue(field.name, !field.value) }} />
											</IonItem>
										</div>
									}}
								</Field>
								<input type="submit" hidden={true} /> {/* Pressing Enter will not submit form without this hidden input */}
								<div className="EmailFooter">
									<IonRow className="ion-align-items-center">
										<IonCol size="12">
											<IonButton type="submit" size="large" expand="full" color="secondary" className="EmailFooterButton">
												Pay
											</IonButton>
										</IonCol>
									</IonRow>
								</div>
							</form>
						)}
					</Formik>
				</div>
			</IonContent>

		</IonPage>
	);
};

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
	email: state.basket?.email,
	staffCardNumber: state.basket?.basket.staffCardNumber
});

const mapDispatchToProps = (dispatch: any) => {
	return {
		updateBasketDetails: (values: any, callback: any) => dispatch(BasketStore.actionCreators.updateBasketDetails(values, callback))
	};
};


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EnterEmail as any);
