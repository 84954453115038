import React, { useEffect, useState } from 'react';
import { IonHeader, IonTitle, IonToolbar, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/react';
import './AppHeader.scss';
import { closeCircle, chevronBack, menu } from 'ionicons/icons';
import { ApplicationState } from '../store';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import '../theme/common.scss';
import * as headerFooterStore from '../store/HeaderFooterStore';
import { bindActionCreators } from 'redux'
import MopHeaderMenusModal from '../modals/MopHeaderMenusModal'

interface LocationListenerProps {
    contentRef: React.RefObject<HTMLIonContentElement>
}


const LocationListener: React.FC<LocationListenerProps> = (props) => {

    const location = useLocation();    

    // Set up a listener to notify the HeaderFooter store when the page changes


    useEffect(() => {
        if(props.contentRef.current != null && location.pathname != undefined){
            if(location.pathname.includes('/menuitem') && !props.contentRef.current.classList.contains("negative-margin")){
                props.contentRef.current.classList.add("negative-margin");
            }else{
                props.contentRef.current.classList.remove("negative-margin");
            }
        }
    }, [location.pathname]);


    return (
       <div></div>
    );
};

export default LocationListener;
