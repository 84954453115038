import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { baseUrl } from '../helpers/configHelper';
import { CategoryList, MenuDataState } from './MenuStoreTypes';
import { ILoginError, loginHasExpired } from '../helpers/apiHelpers';
import { resetMessage, ResetMessageAction } from './ColleagueStore';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestCategoryListAction {
    type: 'REQUEST_CATEGORYLIST';
    categoryId: number;
    container: string;
    pfIdentifier: string | undefined,
}

interface ReceiveCategoryListAction {
    type: 'RECEIVE_CATEGORYLIST';
    categoryId: number;
    categoryList: CategoryList;
    container: string;
}

interface SetCategoryIdAction {
    type: 'SET_CATEGORYID';
    categoryId: number;
}

interface ClearMenuStore {
    type: "CLEAR_MENU_STORE"
}

export interface ResetFirstCallAction {
    type: 'RESET_FIRST_CALL';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestCategoryListAction | ReceiveCategoryListAction | SetCategoryIdAction | ResetFirstCallAction | ClearMenuStore | ResetMessageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    resetFirstCall: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'RESET_FIRST_CALL' });
    },

    requestCategoryList: (
        categoryId: number,
        container: string,
        pfIdentifier: string,
        callback: any,
    ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        if (!categoryId) categoryId = 0;
        const appState = getState();

        let channelIdentifier = appState.location?.channelIdentifier
        let timeSlotStart = appState.location?.selectedTimeSlot.startTime;

        console.log("Requesting category list", categoryId, timeSlotStart); 
        //  if (appState && appState.weatherForecasts && startDateIndex !== appState.weatherForecasts.startDateIndex) {
        fetch(`${baseUrl()}/menudata/${channelIdentifier}/Category/${categoryId}?${localStorage.getItem("staffCardNumber") ? "cardNumber=" + localStorage.getItem("staffCardNumber") : ""}${pfIdentifier ? "&pfIdentifier=" + pfIdentifier + "&" : "&"}timeSlot=` + timeSlotStart + (appState.menuData?.isFirstAccess == true ? "&firstCall=true":""))
            .then(response => response.json() as Promise<CategoryList>)
            .then((data: CategoryList | ILoginError) => {
                if (loginHasExpired(data)) {
                    dispatch(resetMessage("showExpireModal"))
                } else {
                    dispatch({ type: 'RECEIVE_CATEGORYLIST', categoryId: categoryId, categoryList: data, container: container });
                    callback && callback();
                }
            });

        dispatch({
            type: 'REQUEST_CATEGORYLIST',
            categoryId: categoryId,
            container: container,
            pfIdentifier: pfIdentifier,
        });
        //  }
    },
    setCurrentCategory: (categoryId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_CATEGORYID', categoryId: categoryId });
        //  }
    },
    clearMenuStore: () : AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'CLEAR_MENU_STORE' });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: MenuDataState = { categoryCurrent: null, categoryListA: null, categoryListB: null, categoryId: null, isLoading: false, title: "Menu", isFirstAccess:true };

export const reducer: Reducer<MenuDataState> = (state: MenuDataState | undefined, incomingAction: Action): MenuDataState => {
    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CLEAR_MENU_STORE':
            return { ...unloadedState }
        case 'SET_CATEGORYID':
            let title = "Menu";
            if (action.categoryId == state.categoryListA?.categoryId) {
                title = state.categoryListA.categoryTitle;
            }
            if (action.categoryId == state.categoryListB?.categoryId) {
                title = state.categoryListB.categoryTitle;
            }
            return {
                ...state,
                categoryId: action.categoryId,
                title :title
            };
        case 'REQUEST_CATEGORYLIST':
            return {
                ...state,
                categoryId: action.categoryId,
                isLoading: true
            };
        case 'RESET_FIRST_CALL':
            //console.log("Reset first call");
            return {
                ...state,
                isFirstAccess:true
            };
        case 'RECEIVE_CATEGORYLIST':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.categoryId === state.categoryId) {
                if (action.container == "a") {
                    //console.log("Loading A with " + action.categoryId);
                    return {
                        ...state,
                        isFirstAccess:false,
                        categoryId: action.categoryId,
                        categoryListA: action.categoryList,
                        categoryCurrent: action.categoryList,
                        title: action.categoryList.categoryTitle,
                        isLoading: false
                    };
                } else if (action.container == "b") {
                    //console.log("Loading B with " + action.categoryId);
                    return {
                        ...state,
                        isFirstAccess: false,
                        categoryId: action.categoryId,
                        categoryListB: action.categoryList,
                        categoryCurrent: action.categoryList,
                        title: action.categoryList.categoryTitle,
                        isLoading: false
                    };
                }

            }
            break;
    }

    return state;
};
