import { getConfig } from "./configHelper";

export function menuSingleItemSelected(items: IMopModifierGroup, isBasket: boolean) {
    console.log(items);
    var out = '';
    items.modifierGroupItems.map((item: any) => {
        if(isBasket){
            if (item.quantitySelected === 1){
                out = item.menuItemName;
            }
        } 
        else{
            if (item.defaultQuantitySelected === 1) {
                out = item.menuItemName;
            }
        }       
    })
    if(!getConfig().isMarket && out == '' && items.minDifferentItems == 1){
        out = items.modifierGroupItems[0].menuItemName;
    }
    return out;
}

export function calculateMenuItemTotalPrice(item?: IMopMenuItemDetail, calcQuantity: boolean = false) {
    if(item){
        let mods = item.modifierGroups;
        let _price = item.menuItemBasePrice != undefined ? item.menuItemBasePrice : 0;

        if(mods){
            for(let i = 0; i < mods.length; i++){   //Foreach modifier in groupModifiers

                let mod: IMopModifierGroup = mods[i];   
                if(mod.itemsAreChargeable){ //If chargeable

                    for(let j = 0; j < mod.modifierGroupItems.length; j++){ //Foreach item in modifier

                        let item: IMopModifierGroupItem = mod.modifierGroupItems[j];  
                        if(item.quantitySelected > 0){  //If selected

                            if(item.price){ //If price not null | undefined
                                _price += item.quantitySelected * item.price;   
                            }                            
                        }
                    }
                }
            }
        }

        _price = calcQuantity ? _price * item.quantity : _price; 

        return _price;
    }
    else{
        return 0;
    }
    
}