import React from 'react';
import { IonFooter, IonGrid, IonCol, IonButton} from '@ionic/react';
import './MopFooter.scss';
import * as headerFooterStore from '../store/HeaderFooterStore';
import { ApplicationState } from '../store';
import { useSelector } from 'react-redux';
import MopFooterList from './MopFooterList';
import MopFooterMenuItem from './MopFooterMenuItem';
import MopFooterBasketPage from './MopFooterBasketPage';
import MopFooterConfirmStore from './MopFooterConfirmStore';
import MopFooterUpsellPage from './MopFooterUpsellPage';
import MopFooterTablePage from './MopFooterTablePage';
import BasketTotalAndCoupons from './BasketTotalsAndCoupons';
import MopFooterPaymentSuccessPage from './MopFooterPaymentSuccessPage';

const MopFooter: React.FC = () => {
    const headerFooter = useSelector((state: ApplicationState) => state.headerFooter);
    return (
        <>
        {headerFooter?.footerMode === headerFooterStore.FooterMode.BasketPage && <BasketTotalAndCoupons/>}
        <IonFooter className="MopFooterWrapper" hidden={headerFooter?.footerMode === headerFooterStore.FooterMode.Hidden}>
            {headerFooter?.footerMode === headerFooterStore.FooterMode.Basket && <MopFooterList/>}
            {headerFooter?.footerMode === headerFooterStore.FooterMode.PDP && <MopFooterMenuItem />}
            {headerFooter?.footerMode === headerFooterStore.FooterMode.BasketPage && <MopFooterBasketPage />}
            {headerFooter?.footerMode === headerFooterStore.FooterMode.UpsellPage && <MopFooterUpsellPage/>}
            {headerFooter?.footerMode === headerFooterStore.FooterMode.TablePage && <MopFooterTablePage />}
            {headerFooter?.footerMode === headerFooterStore.FooterMode.ConfirmStore && <MopFooterConfirmStore/>}
            {headerFooter?.footerMode === headerFooterStore.FooterMode.PaymentSuccessPage && <MopFooterPaymentSuccessPage />}
        </IonFooter>
        </>
    );
};

export default MopFooter;
