import React, { useState } from 'react';
import { IonGrid, IonCol, IonButton, IonRow, IonInput, IonIcon} from '@ionic/react';
import './BasketTotalAndCoupons.scss';

import { ApplicationState } from '../store';
import { useSelector } from 'react-redux';
import * as currencyHelpers from '../helpers/currencyHelpers';
import { chevronDownOutline,chevronUpOutline } from 'ionicons/icons';
import * as BasketStore from '../store/BasketStore';
import { connect } from 'react-redux';

interface KeyedErrorMappings {
    [index: number]: string;
}

const couponErrorMappings: KeyedErrorMappings = {
    1: "This coupon has expired.",
    2: "The qualifying spend for this coupon has not been met.",
    3: "Item required for coupon is not in basket.",
    4: "Not enough qualifying products in basket. Coupon not valid.",
    5: "Invalid Coupon.",
    6: "This coupon has already been used and is no longer valid.",
    7: "This coupon is worth more than the total spend.",
    8: "This coupon cannot be used on this till type.",
    9: "This coupon has already been used in this transaction.",
    10: "The customer is not eligible for this coupon.",
    11: "Please use More Card card.",
    12: "Service is unavailable, coupon cannot be redeemed.",
    989: "Digital money off coupons cannot be redeemed at this time.",
    20: "Colleage Discount limit reached.",
    21: "No items qualify for Colleague Discount.",
    50: "We are really sorry some promotions and discounts are unavailable at this time.",
    1001: "Your coupon is not supported here. Place your order at the till."
};

interface BasketTotalAndCouponsProps {
    mopCoupons: any[];
    menuItems: IMopMenuItemDetail[];
}

type BasketTotalAndCouponsPropsMergeProps =
    typeof BasketStore.actionCreators
    & BasketTotalAndCouponsProps;

function CouponCheckMarkSvg() {
    return(<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM10 14.17L15.88 8.29C16.27 7.9 16.91 7.9 17.3 8.29C17.69 8.68 17.69 9.31 17.3 9.7L10.71 16.29C10.32 16.68 9.69 16.68 9.3 16.29L6.71 13.7C6.32 13.31 6.32 12.68 6.71 12.29C6.89683 12.1027 7.15048 11.9975 7.415 11.9975C7.67952 11.9975 7.93317 12.1027 8.12 12.29L10 14.17Z" fill="white"/>
    </svg>);
}

function ErrorSvg() {
    return(<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.50008 1.41663C4.59008 1.41663 1.41675 4.58996 1.41675 8.49996C1.41675 12.41 4.59008 15.5833 8.50008 15.5833C12.4101 15.5833 15.5834 12.41 15.5834 8.49996C15.5834 4.58996 12.4101 1.41663 8.50008 1.41663ZM8.50008 4.95829C8.88967 4.95829 9.20842 5.27704 9.20842 5.66663L9.20842 8.49996C9.20842 8.88954 8.88967 9.20829 8.50008 9.20829C8.1105 9.20829 7.79175 8.88954 7.79175 8.49996V5.66663C7.79175 5.27704 8.1105 4.95829 8.50008 4.95829ZM9.20841 12.0416V10.625L7.79175 10.625L7.79175 12.0416L9.20841 12.0416Z" fill="#D8393A"/>
</svg>);
}

const BasketTotalAndCoupons: React.FC<BasketTotalAndCouponsPropsMergeProps> = (props: BasketTotalAndCouponsPropsMergeProps) => {

    const [CouponCode, SetCouponCode] = useState("");
    const [showCouponEntry, setShowCouponEntry] = useState(false);
    const [Error, SetError] = useState("");
    const [IsBusy, SetIsBusy] = useState(false);
    const basketStoreEx = useSelector((state: ApplicationState) => state.basket);

    const calculateCouponsTotal = () => {
        let couponsTotal:number = 0;        
        if(props.mopCoupons) {
            const validCoupons = props.mopCoupons.filter((c: IMopCoupon) => !c.rejected);
            validCoupons.forEach((coupon: IMopCoupon) => {
                let couponMenuItem = props.menuItems.filter((c: IMopMenuItemDetail) => c.sku.includes(coupon.couponCode));
                if(couponMenuItem.length === 1) {
                    couponsTotal += couponMenuItem[0].menuItemTotalPrice;
                }
            });
        }
        return couponsTotal;
    }

    const couponsTotal = calculateCouponsTotal();
    const subTotal = basketStoreEx?.basket.totalPrice ? basketStoreEx.basket.totalPrice - couponsTotal : basketStoreEx?.basket.totalPrice;

    return (<div className="sandbags-container">
    <IonGrid className="sandbags">
        <IonRow className="other-total">
            <IonCol>Subtotal</IonCol>
            <IonCol size="3" className="ion-text-right">{currencyHelpers.formatCurrency(subTotal)}</IonCol>
        </IonRow>
        {couponsTotal !== 0 && 
        <IonRow className="other-total">
            <IonCol>Coupons</IonCol>
            <IonCol size="3" className="ion-text-right">{currencyHelpers.formatCurrency(couponsTotal)}</IonCol>
        </IonRow>}
        <IonRow className="order-total">
            <IonCol>Order Total</IonCol>
            <IonCol size="3" className="ion-text-right">{currencyHelpers.formatCurrency(basketStoreEx?.basket.totalPrice)}</IonCol>
        </IonRow>
        <IonRow className="coupons-add-container">
            <IonCol>
                {localStorage.getItem("isLoggedIn") !== "true" &&
                <div>
                    <div className={`add-coupon ${IsBusy && "disable-item"}`} onClick={() => { setShowCouponEntry(show => !show) }}>
                        Add coupon{!showCouponEntry && <IonIcon icon={chevronDownOutline}/>}{showCouponEntry && <IonIcon icon={chevronUpOutline}/>}
                    </div>
                </div>
                }
                { showCouponEntry && <div className="coupon-widget">
                    <div className="enter-number-caption">Enter coupon number</div>
                    <div className={`coupon-input-container ${Error.length > 0 && "error"}` }>
                        <div className="coupon-input"><IonInput 
                        disabled={IsBusy}
                        placeholder="XXXXXXXXXXXXX"                       
                            value={CouponCode}
                            onIonChange={(s) => {
                                if (s.detail.value != undefined) {
                                    SetCouponCode(s.detail.value);
                                    SetError("");
                                }
                            }}
                            type="number" /></div>
                        <div className="coupon-apply">
                            <IonButton disabled={IsBusy}
                        onClick={() => {

                            const canAdd = CouponCode?.length == 13 && CouponCode.substring(0, 3) == "992";

                            if (canAdd == false) {
                                SetError("Coupon not recorgnised");
                                return;
                            }

                            SetError("");

                            let duplicatCoupon: any = [];

                            if (props.mopCoupons != null) {
                                duplicatCoupon = props.mopCoupons.filter((c: any) => c.couponCode == CouponCode);
                            }

                            if (duplicatCoupon.length == 0) {
                                SetError("");
                                SetIsBusy(true);
                                props.addCouponCode(CouponCode, (r: boolean, basket: any) => {
                                    if (basket) {
                                        let couponsAdded = basket.mopCoupons.filter((c: any) => c.couponCode == CouponCode);
                                        if (couponsAdded.length > 0) {
                                            const thatCoupon = couponsAdded[0] as IMopCoupon;
                                            if (thatCoupon.rejected == true) {
                                                const mappedError = couponErrorMappings[thatCoupon.errorCode];
                                                if (mappedError) {
                                                    SetError(mappedError);
                                                }
                                                else {
                                                    SetError("An unknown error has occurred");
                                                }
                                                props.deleteCouponCode(thatCoupon.couponCode, (r: boolean) => {
                                                    SetIsBusy(false);
                                                });
                                            }
                                            else {
                                                SetCouponCode("");
                                            }
                                        }
                                    }
                                    SetIsBusy(false);
                                });
                            }
                            else {
                                SetError("This coupon has already been added");
                            }
                        }}
                        >Apply</IonButton></div>
                    </div>  
                    { Error.length > 0 && 
                    <div className="error-bubble">
                        <div><ErrorSvg/></div>
                        <div className="message">{Error}</div>
                    </div>}  
                </div>}
            </IonCol>
        </IonRow>            
        {props.mopCoupons != null && props.mopCoupons.filter((b: IMopCoupon) => b.rejected == false).map((c: IMopCoupon, idx: number) => (
        <IonRow key={idx} className="coupon-row">
            <IonCol className="description">
                <div><CouponCheckMarkSvg/></div>
                <div className="code">{c.couponCode}</div>
            </IonCol>
            <IonCol size="3"><div className={`remove ${IsBusy && "disable-item"}` }
                        onClick={() => {
                            SetIsBusy(true);
                            props.deleteCouponCode(c.couponCode, (r: boolean) => {
                                SetIsBusy(false);
                            });
                        }}>Remove
                    </div>
            </IonCol>
        </IonRow>))}
    </IonGrid>
</div>)};

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
    mopCoupons: state.basket?.basket.mopCoupons,
    menuItems: state.basket?.basket.menuItems
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        addCouponCode: (couponCode: string, callback: any) => dispatch(BasketStore.actionCreators.addCouponCode(couponCode, callback)),
        deleteCouponCode: (couponCode: string, callback: any) => dispatch(BasketStore.actionCreators.deleteCouponCode(couponCode, callback))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BasketTotalAndCoupons as any)

// 9928220872006
