import './StoreSearch.scss';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { RouteComponentProps } from 'react-router';

import * as LocationStore from '../store/LocationStore';
import * as BasketStore from '../store/BasketStore';
import { simpleChannelType, SimpleStore } from '../store/LocationStore';

import * as HeaderFooterStore from '../store/HeaderFooterStore';
import { locationSharp, qrCode, locate, search } from 'ionicons/icons';
import LocationItem from '../components/LocationItem';
import { getConfig } from '../helpers/configHelper';
import TimeSlotChooser from '../components/TimeSlotChooser';

import {
    IonGrid, IonRow, IonCol, IonContent, IonHeader, IonFooter, IonPage, IonTitle, IonToolbar, IonInput, IonItem, IonLabel, IonList, IonItemDivider, IonButton,
    IonThumbnail, IonImg, IonRadioGroup, IonRadio, IonCheckbox, IonSearchbar, IonLoading, IonIcon, IonAlert, IonSpinner
} from '@ionic/react';

import { updateNamespaceExportDeclaration } from 'typescript';

type StoreSearchProps =
    LocationStore.LocationState &
    BasketStore.BasketState &
    typeof LocationStore.actionCreators &
    typeof HeaderFooterStore.actionCreators &
    typeof BasketStore.actionCreators &
    RouteComponentProps

//type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type AutocompletePrediction = HereLocation | MapBoxLocation;

type HereLocation = {
    api: "HERE",
    data: HereItemsEntity
}

type MapBoxLocation = {
    api: "MapBox",
    data: FeaturesEntity
}

interface IStoreSearchState {
    input: string,
    ignoreInput:boolean,
    selectedStore?: LocationStore.SimpleStore,
    localPredictions: AutocompletePrediction[],
    cancelOrderAlertShow: boolean,
    isLoading: boolean
}

function metersToMiles(metersValue:number) {
    return metersValue * 0.000621371;
}

function tidyPlaceDescription(title:string) {
    let newTitle = title.replace(", England", "");
    newTitle = newTitle.replace(", Scotland", "");
    newTitle = newTitle.replace(", Wales", "");
    newTitle = newTitle.replace(", Northern Ireland", "");
    newTitle = newTitle.replace(", Ireland", "");
    newTitle = newTitle.replace(", United Kingdom", "");
    return newTitle;
    //Google - return str.replace(', UK', '');
}   

class StoreSearch extends React.Component<StoreSearchProps,IStoreSearchState> {

    constructor(props: any) {
        super(props);
        this.state = { input: "", selectedStore: undefined, localPredictions: [], cancelOrderAlertShow: false, ignoreInput:false, isLoading: false};
        this.requestStoresCallback = this.requestStoresCallback.bind(this);
        this.requestServerGeoAutoCompleteCallback = this.requestServerGeoAutoCompleteCallback.bind(this);
        this.requestServerGeoPlaceCallback = this.requestServerGeoPlaceCallback.bind(this);
        this.geoCallback = this.geoCallback.bind(this);
    }

    componentDidMount() {
        console.debug("StoreSearch.componentDidMount()");
        if (Number.isNaN(this.props.clientLatitude) == false) {
            //console.log("componentDidMount: setting isLoading = true");
            this.setState({isLoading:true});   
            this.props.requestStores(this.props.clientLatitude, this.props.clientLongitude, Number.NaN, this.requestStoresCallback);
        }
        else {
            this.location();
        }
    }

    componentDidUpdate(prevProps: StoreSearchProps, prevState: IStoreSearchState) {
        if(prevProps.location.pathname != this.props.location.pathname && this.props.location.pathname == "/storesearch")
        {
            if(this.props.basket.menuItems.length > 0){
                this.setState({
                    cancelOrderAlertShow: true
                })
            }
        }
    }

    requestStoresCallback() { 
        //console.log("Setting isLoading to false");
        this.setState({isLoading: false});
    }

    onIonRadioGroupChange(s:any) {
        //console.log("onIonRadioGroupChange: " + JSON.stringify(s.detail.value));
        if(this.props.basket.menuItems.length < 1){
            const storeNumber = s.detail.value;
            this.setSelectedStore(storeNumber);
        }
    }

    setSelectedStore(_storeNumber: string){
        const storeNumber = _storeNumber;
            console.debug("storeNumber = " + storeNumber);
            const selectedStore = this.props.stores.filter((p) => { return p.storeNumber == storeNumber});
            if (selectedStore && selectedStore.length == 1) {
                const theStore = selectedStore[0];
                if(theStore.supportedChannels.length > 0){
                    //const theChannel = theStore.supportedChannels.filter((c) => { return c.channelType == simpleChannelType.ClickAndCollect });
                    const theChannel = theStore.supportedChannels[0];
                    if (theChannel) {
                        const theChannelIdentifier = theChannel.channelIdentifier;
                        console.debug("channelIdentifier = " + theChannelIdentifier);
                        this.props.selectStore(theStore, theChannelIdentifier);
                        //console.log("Scrolling");
                    }
                }
            }
    }

    requestServerGeoPlaceCallback() {
       if (this.props.place) {
           if (this.props.placeLatitude){
               this.props.requestStores(this.props.placeLatitude, this.props.placeLongitude, Number.NaN, this.requestStoresCallback);
           }
       }
    }

    //Unused - Google
    requestServerGeoAutoCompleteCallback() {
        this.clearSuggestions();
        if (this.props.predictions?.length > 0) {
            this.props.predictions.forEach((i:any) => {
                //console.debug("google description: " + i.description);
                this.setState({ localPredictions: [...this.state.localPredictions,i] });
            });  
        }
    }

    clearSuggestions() {
        this.setState({ localPredictions: [] }); // some spaff
    }

    onBlur(e:any) {
        if (e.detail?.relatedTarget?.tagName !== "ION-ITEM")
            this.clearSuggestions();
    }

    onInputChanged(evt: any) {
        if (this.state.ignoreInput) {
            this.setState({ ignoreInput: false });
        }
        else {
            const str: string = evt.detail.value;
            this.setState({ input: evt.detail.value });
            if (str && str.length > 0)
                //this.props.requestServerGeoAutoComplete(str, this.requestServerGeoAutoCompleteCallback);
                this.requestAutocomplete(str, (predictions) => {
                    this.clearSuggestions();
                    this.setState({
                        localPredictions: predictions
                    });
                });
            else
                this.clearSuggestions();
        }
    }

    requestAutocomplete = async (query: string, callback?: (predictions: AutocompletePrediction[]) => void) => {
        let useMapBox = true;
        if(useMapBox){
            //Reference - https://docs.mapbox.com/api/search/geocoding/#forward-geocoding
            //Demo - https://docs.mapbox.com/playground/geocoding/?search_text=Warmington&country=gb%2Cie&limit=10&types=locality%2Cpostcode%2Cneighborhood%2Cplace%2Cdistrict%2Caddress&autocomplete=true&fuzzyMatch=true&routing=false&access_token=sk.eyJ1IjoiaHdkLXdpbGxqaWNhciIsImEiOiJja3d4bXUzZzYwZjVhMm9sY2JmYnZmOTRjIn0.yyTvWe5mhXVITFXb2T8npg
            try{
                const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?country=gb%2Cie&limit=10&types=locality%2Cpostcode%2Cneighborhood%2Cplace%2Cdistrict%2Caddress&autocomplete=true&fuzzyMatch=true&routing=false&access_token=sk.eyJ1IjoiaHdkLXdpbGxqaWNhciIsImEiOiJja3d4bXUzZzYwZjVhMm9sY2JmYnZmOTRjIn0.yyTvWe5mhXVITFXb2T8npg`);
                if(!response.ok){
                    if(response.status == 429){
                        setTimeout(() => {
                            this.requestAutocomplete(query, callback);
                        }, 1000);                        
                    }
                    else{
                        console.error(response);
                        throw new Error(response.statusText);
                    }                    
                }
                else{
                    const responseJson = await response.json() as MapBoxResponse;
                    console.log(responseJson.features);

                    callback && callback(responseJson.features ? responseJson.features?.map((feature) => {
                        return {
                            api: "MapBox",
                            data: feature
                        }
                    }) : []);
                }
            }
            catch(err){
                console.log(err);
            }  
        }
    }

    onPlaceSelect(id: any, title:any, long?: any, lat?: any) {
        console.debug(`onPlaceSelect: place_id = ${id}, description = ${title}`);
        if (id) {
            //console.log("onPlaceSelect: Setting isLoading to true");
            this.setState({ input: tidyPlaceDescription(title), ignoreInput:true, isLoading: true });
            this.clearSuggestions();
            if(long && lat){
                this.props.requestStores(lat, long, Number.NaN, this.requestStoresCallback);
            }
            //this.props.requestServerGeoPlace(id, this.requestServerGeoPlaceCallback);
        }
    }

    geoCallback(latitude: number, longitude: number, accuracy: number, isOk: boolean) {
        if (isOk) {
            //console.debug(`StoreSearch.geoCallback: latitude=${latitude}, longitude=${longitude}, accuracy=${accuracy}`);
            this.setState({ isLoading: true});
            this.props.requestStores(this.props.clientLatitude,
                this.props.clientLongitude,
                Number.NaN,
                this.requestStoresCallback);
        }
        else {
            console.error("StoreSearch.geoCallback: requestClientGeoLocation failed.");
        }
    }

    location() {
        this.props.requestClientGeoLocation(this.geoCallback);
        this.setState({ input: ""});
    }

    render() {
        return <IonPage>
            <IonAlert
                isOpen={this.state.cancelOrderAlertShow}
                onDidDismiss={() => { this.setState({ cancelOrderAlertShow: false }) }}
                header={"Change Location"}
                buttons={[
                    {
                        text: "No",
                        role: "cancel",
                        handler: () => {
                            //TODO CHECK IF SELECTED STORE HAS A EAT IN CHANNEL
                            this.props.history.push("/ordertype");
                        }
                    },
                    {
                        text: "Yes, clear basket",
                        handler: () => {
                            this.props.clearBasket()
                        }
                    }
                ]}
                message={"Changing your location will clear your basket. Are you sure you want to do this?"}
            />
            <IonContent fullscreen>
                <div className="StoreSearchInner">
                    <IonGrid>
                        <IonRow>
                            <IonCol className="SearchTitle">Choose a store</IonCol>
                        </IonRow>
                        <IonRow className="StoreSearchInput">
                            <IonCol className="SearchAutocomplete">

                                <div className="search-box">
                                    <IonIcon className="pin-icon" icon={search}/>
                                    <IonInput onIonBlur={(e) => this.onBlur(e)}
                                        debounce={500}
                                        value={this.state.input}
                                        placeholder="Enter postcode or location"
                                        className="LocationSearch"
                                        onIonChange={(e) => this.onInputChanged(e)} />
                                    <IonIcon onClick={(e) => this.location()} icon={locate} className={`locate-icon ${this.props.isBusy && "disabled"}`}/>
                                </div>
                                {this.state.input.length > 0 ? <IonList className="SearchAutocompleteContent">
                                    {this.state.localPredictions.map((prediction: AutocompletePrediction, i) => {
                                        /*
                                        Google API
                                        return <IonItem className="SearchAutocompleteContentItem"
                                            key={i.place_id}
                                            onMouseDown={(e) => this.onPlaceSelect(i.place_id, i.description)}>
                                            {tidyPlaceDescription(i.description)}
                                        </IonItem>
                                        */
                                        if(prediction.api === "HERE"){
                                            let data = prediction.data;
                                            return  <IonItem className="SearchAutocompleteContentItem" lines={i !== this.state.localPredictions.length-1 ? "full" : "none"}
                                                        key={data.id}
                                                        onMouseDown={(e) => this.onPlaceSelect(data.id, data.title, data.position.lng, data.position.lat)}>
                                                        {tidyPlaceDescription(data.title)}
                                                    </IonItem>
                                        }
                                        else{
                                            let data = prediction.data;
                                            return  <IonItem className="SearchAutocompleteContentItem" lines={i !== this.state.localPredictions.length-1 ? "full" : "none"}
                                                        key={data.id}
                                                        onMouseDown={(e) => this.onPlaceSelect(data.id, data.place_name, data.geometry.coordinates ? data.geometry.coordinates[0] : 0,  data.geometry.coordinates ? data.geometry.coordinates[1] : 0)}>
                                                        {tidyPlaceDescription(data.place_name)}
                                                    </IonItem>
                                        }
                                    })}
                                </IonList> : null}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
                <div className="StoreListInner">
                    {this.state.isLoading && //
                        <div className="SpinnerContainer">
                            <IonSpinner name="lines" color="primary"></IonSpinner>
                        </div>
                    }
                    {!this.state.isLoading && //
                        <IonRow>
                            <IonCol>
                                <IonRadioGroup value={!this.props.selectedStore?.storeNumber ? "-1" : this.props.selectedStore.storeNumber}
                                                onIonChange={(e) => this.onIonRadioGroupChange(e)}>
                                    {this.props.stores.map((store,i) => {
                                        return <LocationItem
                                                    storeNumber={store.storeNumber}
                                                    selectedStoreNumber={this.props.selectedStore?.storeNumber || "-1"}
                                                    key={store.storeNumber}
                                                    takingMopOrders={store.takingMopOrders}
                                                    storeOpen={store.storeOpen}
                                                    openingTimes={store.openingTimes}
                                                    locationName={store.storeName}
                                                    locationAddress={store.firstLineAddress}
                                                    locationPostcode={store.postcode}
                                                    locationDistance={metersToMiles(Number(store.distance)).toFixed(2)}
                                                    locationIsMarketKitchen={false}
                                                    locationIsCafe={false}
                                                    isFirstItem={i === 0}
                                                    isLastItem={i === this.props.stores.length - 1}/>
                                    })}
                                </IonRadioGroup>        
                        </IonCol>
                    </IonRow>
                    }



                    </div>
            </IonContent>
            
            {/* 
             STEALING FOCUS
                         <IonLoading
                isOpen={this.props.isBusy}
                message={'Searching...'}
                duration={10 * 1000}/>
             */}
        </IonPage>;
    }
}

const mapDispatchToProps = (
    dispatch: any) => {
    return {
        selectStore: (store: SimpleStore, channelIdentifier: string) => dispatch(LocationStore.actionCreators.selectStore(store, channelIdentifier)),
        setTitle: (title: string, showBack: boolean) => dispatch(HeaderFooterStore.actionCreators.setTitle(title, showBack)),
        requestStores: (latitude: number, longitude: number, radius: number, callback: any) => dispatch(LocationStore.actionCreators.requestStores(latitude, longitude, radius,callback)),
        requestServerGeoAutoComplete: (input: string, callback: any) => dispatch(LocationStore.actionCreators.requestServerGeoAutoComplete(input, callback)),
        requestServerGeoPlace: (place: any, callback: any) => dispatch(LocationStore.actionCreators.requestServerGeoPlace(place, callback)),
        requestClientGeoLocation: (callback: any) => dispatch(LocationStore.actionCreators.requestClientGeoLocation(callback)),
        clearBasket: () => dispatch(BasketStore.actionCreators.clearBasket())
    };
};

export default connect(
    (state: ApplicationState) => { return { ...state.location, ...state.basket } }, // Selects which state properties are merged into the component's props
    mapDispatchToProps // Selects which action creators are merged into the component's props
)(StoreSearch as any); // eslint-disable-line @typescript-eslint/no-explicit-any