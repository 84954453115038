import React, { useEffect, useState } from 'react';
import { IonGrid, IonFooter, IonRow, IonModal, IonCol, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon, IonText, IonImg } from '@ionic/react';
import * as headerFooterStore from '../store/HeaderFooterStore';
import * as LocationStore from '../store/LocationStore';
import { ApplicationState } from '../store';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux'
import { useHistory, useLocation } from "react-router-dom";
import styled from 'styled-components';
import moment from 'moment';
import useMopHooks from '../helpers/useMopHook';
import { simpleChannelType } from '../store/LocationStore';

const MopFooterConfirmStore: React.FC = () => {
    let history = useHistory();
    const location = useLocation();
    const locationState = useSelector((state: ApplicationState) => state.location);
    const boundSelectTimeslot = bindActionCreators(LocationStore.actionCreators.selectTimeslot, useDispatch());
    const { onChannelSelected } = useMopHooks();
    const [showButton, setShowButton] = useState(true);

    useEffect(() => {
        if(location.pathname == "/ordertype"){
            setShowButton(false);
        }
        else{
            setShowButton(true);
        }
    }, [location.pathname]);

    return (
        <div className="MopFooterInner">
            <IonRow className="ion-align-items-center">
                <IonCol size="4">
                    <div className="FooterStoreWrapper">
                        <span className="CollectFrom">{locationState?.selectedChannel === undefined ? "Store" : locationState.selectedChannel.channelType === simpleChannelType.ClickAndCollect ? "Collect from" : "Store" }</span><br />
                        <span className="Location">{locationState?.selectedStore === undefined ? "Select a store" : locationState?.selectedStore.storeName}</span>
                        {locationState?.selectedStore?.takingMopOrders === false ? <span style={{ color: "#ff0" }}> - Order ahead not available</span>: null}
                    </div>
                </IonCol>
                { showButton &&
                    <IonCol size="8">
 
                            <IonButton disabled={locationState?.selectedStore === undefined || locationState?.selectedStore.takingMopOrders === false}
                                expand="full" color="secondary" onClick={() => { 
                                    /*
                                        Use selected store channel instead of having extra state for order type?
                                    */
                                    if (location.pathname.includes('/storesearch')) {
                                        if(locationState?.selectedStore){
                                            if(locationState?.selectedStore?.supportedChannels.length > 0) { //if selectedStore supports both eat in and takeaway, then orderType, else onEatInSelected();
                                                history.push('/ordertype');
                                            }
                                            else{
                                                onChannelSelected(locationState.selectedStore.supportedChannels[0]);
                                            }
                                        }
                                    } 
                                    else {   //From choosetimeslot
                                        history.push(`/categories`); 
                                    }   
                                }}>Confirm</IonButton>
                           
                    </IonCol>
                }
            </IonRow>
        </div>
    );
};

export default MopFooterConfirmStore;