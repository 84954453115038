import { IonAlert, IonCol, IonContent, IonGrid, IonicSafeString, IonItem, IonPage, IonRow, IonRadioGroup, IonLabel, IonRadio } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMopHooks from '../helpers/useMopHook';
import { ApplicationState } from '../store';
import { SimpleChannel, simpleChannelType } from '../store/LocationStore';
import * as BasketStore from '../store/BasketStore';
import { useHistory, useLocation } from "react-router-dom";
import './ChooseOrderType.scss';
import { bindActionCreators } from 'redux';
import { baseUrl } from '../helpers/configHelper';

interface IChannelProps {
    type: simpleChannelType,
    title: string,
    subTitle: string
}

const ChannelProps: IChannelProps[] = [
    {
        type: simpleChannelType.TableOrder,
        title: "Eat in",
        subTitle: "We’ll bring your order to your table"
    },
    {
        type: simpleChannelType.ClickAndCollect,
        title: "Take away",
        subTitle: "Collect right away or choose a time"
    }
];

const ChooseOrderType: React.FC = () => {
    const history = useHistory();
    const location = useLocation();

    const { onChannelSelected } = useMopHooks();
    const [selectedChannel, setSelectedChannel] = useState() as any;
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isClearBasketAlertOpen, setClearBasketAlertOpen] = useState(false);
    const LocationState = useSelector((state: ApplicationState) => state.location);
    const BasketState = useSelector((state: ApplicationState) => state.basket);
    const boundClearBasket = bindActionCreators(BasketStore.actionCreators.clearBasket, useDispatch());

    const handleOrderTypeClick = (channel: SimpleChannel) => {
        if(channel == null) {
            return;
        }
        if(LocationState?.selectedChannel !== undefined && LocationState.selectedChannel.channelType !== channel.channelType && BasketState && BasketState.basket.menuItems.length > 0){
            setSelectedChannel(channel);
            setClearBasketAlertOpen(true);
            return;
        }
        switch(channel.channelType){
            case simpleChannelType.ClickAndCollect:
                onChannelSelected(channel)
                break;
            case simpleChannelType.TableOrder:
                if (localStorage.getItem('isLoggedIn') === 'true') {
                    // Colleague logged in so bypass the alert dialog.
                    onChannelSelected(channel)
                } else {
                    setIsAlertOpen(true);
                    setSelectedChannel(channel);
                }
                break;
        }
    }

    useEffect(() => {
        setSelectedChannel(null)
    }, [location.pathname])

    return (
        <IonPage>
            <IonContent>
                <IonAlert
                    backdropDismiss={false}
                    isOpen={isAlertOpen}
                    onDidDismiss={() => { 
                        setIsAlertOpen(false); 
                        onChannelSelected(selectedChannel); }}
                    header={"Eat In"}
                    buttons={[
                        {
                            text: "I understand",
                            role: "cancel"
                        }
                    ]}
                    message={new IonicSafeString('<div style="text-align: center"><div style="margin-bottom: 10px">We\'ll collect your table number later.</div><div style="margin-bottom: 10px">Your order will be delivered to your table in a few minutes.</div></div>')}
                />
                <IonAlert
                    isOpen={isClearBasketAlertOpen}
                    onDidDismiss={() => { 
                        setClearBasketAlertOpen(false); 
                    }}
                    header={"Are you sure?"}
                    buttons={[
                        {
                            text: "Cancel",
                            role: "cancel"
                        },
                        {
                            text: "Clear basket",
                            handler: () => {
                                boundClearBasket();
                                onChannelSelected(selectedChannel);
                            }
                        }
                    ]}
                    message="Changing your order type will clear the items in your basket, are you sure you want to continue?"
                />
                <IonGrid className="ChooseOrderType__container">
                    <IonRow>
                        <IonCol size="12" className="OrderType_storename">{LocationState?.selectedStore?.storeName}</IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12" className="OrderType_caption">How would you like to order?</IonCol>
                    </IonRow>
                    <IonRow className="order-type-container">
                        <IonCol size="12" className="ion-no-padding">
                            <IonRadioGroup value={selectedChannel} onIonChange={(e) => { handleOrderTypeClick(e.detail.value) }}>
                            {
                                LocationState?.selectedStore?.supportedChannels
                                .sort((a,b) => (ChannelProps.find((ch) => ch.type === a.channelType)?.title ?? "") < (ChannelProps.find((ch) => ch.type === b.channelType)?.title ?? "") ? -1 : 1)
                                .map((channel, i) => {  
                                    let thisChannel = ChannelProps.find((ch) => ch.type === channel.channelType);
                                    return <IonItem key={"orderType__" + i} lines="none">
                                                <IonRadio slot="start" value={channel} disabled={!channel.enabled} />
                                                <IonLabel>                                    
                                                    <div className="button-content__container">
                                                        <div className="button_title">
                                                            {thisChannel?.title}
                                                        </div>
                                                        <div className="button_sub-title">
                                                            {thisChannel?.subTitle}
                                                        </div>
                                                    </div>
                                                </IonLabel>
                                        </IonItem>    
                                })
                            }                                
                            </IonRadioGroup>
                        </IonCol>
                    </IonRow>
                    {(LocationState?.selectedStore?.supportedChannels.length === 1) && <IonRow>
                        <IonCol size="12">      
                            {ChannelProps.find((ch) => ch.type === simpleChannelType.ClickAndCollect) ? "Table service is not" : "Take away are not"} currently available at this store.
                            <div className="change-store" onClick={() => history.goBack() }>Change store</div>
                        </IonCol>
                    </IonRow>}
                    {/* 
                    <div className="eatIn-warning">
                        By selecting 'Eat in' you are confirming that you have completed the Test &amp; Trace requirements for this venue.
                    </div>
                    */}
                </IonGrid>
                {localStorage.getItem('isLoggedIn') !== 'true' &&
                    <img alt='morrisons-save-card'
                        className="ChooseOrderType__image"
                        src={`${baseUrl()}/images/assets/Morrisons_Save.png?v=2`} />
                }
            </IonContent>
        </IonPage>
    )
} 

export default ChooseOrderType;
