import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { IonContent, IonButton, IonPage, IonLoading } from '@ionic/react';
import { useHistory } from "react-router-dom";
import { RouteComponentProps } from 'react-router';

import * as LocationStore from '../store/LocationStore';
import * as HeaderFooterStore from '../store/HeaderFooterStore';

import { simpleChannelType, SimpleStore } from '../store/LocationStore';
import { continueFromInitialPage } from '../helpers/configHelper';

import './QRLanding.scss';
import { call } from 'ionicons/icons';

interface IQRLandingProps {
    storeReference: string;
}

interface IQRLandingState {
    hasFailed: boolean;
}

type QRLandingProps =
    LocationStore.LocationState &
    typeof HeaderFooterStore.actionCreators &
    typeof LocationStore.actionCreators &
    RouteComponentProps<IQRLandingProps>

class QRLanding extends React.Component<QRLandingProps, IQRLandingState> {

    constructor(props: any) {
        super(props);
        this.state = { hasFailed: false };
        this.requestStoreCallback = this.requestStoreCallback.bind(this);
        this.requestStoreErrorCallback = this.requestStoreErrorCallback.bind(this);
    }

    requestStoreCallback() {
        if (this.props.stores.length == 1) {
            const theStore = this.props.stores[0];
            const theChannel = theStore.supportedChannels.filter((c) => { return c.channelType == simpleChannelType.ClickAndCollect });
            if (theChannel && theChannel.length == 1) {
                const theChannelIdentifier = theChannel[0].channelIdentifier;
                console.debug("channelIdentifier = " + theChannelIdentifier);
                this.props.selectStore(theStore, theChannelIdentifier);
            }
        }
        else {
            this.setFailedState();
        }
    }

    requestStoreErrorCallback() {
        this.setFailedState();
    }

    setFailedState() {
        this.setState({ hasFailed: true });
    }

    componentDidMount() {
        this.props.setPage("qrlanding");
        console.debug("storeReference = " + this.props.match.params.storeReference);
        if (this.props.match.params.storeReference)
            this.props.requestStore(this.props.match.params.storeReference, this.requestStoreCallback, this.requestStoreErrorCallback);
        else
            this.setFailedState();
    }

    changeStore() {
        this.props.clearStores();
        this.props.clearSelectedStore();
        continueFromInitialPage(this.props.history, this.props.setHasStartedApp, "/storesearch");
    }

    getStarted() {
        continueFromInitialPage(this.props.history, this.props.setHasStartedApp, "/choosetimeslot");
    }

    render() {
        return <IonPage>
            <IonContent fullscreen>
                {this.props.selectedStore == undefined ? null : <div className="QRlandingWrapper">
                    <div className="QRlandingTextWrapper">
                        <div className="QRlandingTitle">Your location</div>
                        <div className="QRlandingLarge">Hi, looks like you're in our <span className="QRlandingLocationTitle">{this.props.selectedStore?.storeName}</span> store.</div>
                        <div className="QRlandingLarge">Ready to start?</div>
                    </div>
                    <div className="QRlandingButtonsWrapper">
                        <IonButton className="QRlandingButton change" color="transparent" onClick={(e) => this.changeStore()}>Change store</IonButton>
                        <IonButton className="QRlandingButton start" disabled={this.props.selectedStore?.takingMopOrders == false}
                            onClick={(e) => this.getStarted()}>Get started</IonButton>
                    </div>
                </div>}
                {this.state.hasFailed == true ?

                    <div className="QRlandingWrapper">
                        <div className="QRlandingTextWrapper">
                            <div className="QRlandingTitle">Your location</div>
                            <div className="QRlandingLarge">Please press 'Find store' and let us know which store you wish to collect your order from.</div>
                        </div>
                        <div className="QRlandingButtonsWrapper">
                            <IonButton className="QRlandingButton find" color="transparent" onClick={(e) => this.changeStore()}>Find store</IonButton>
                        </div>
                    </div>
                    : null}
            </IonContent>
            <IonLoading
                isOpen={this.props.isBusy}
                message={'One moment...'}/>
        </IonPage>
    }
}

const mapDispatchToProps = (
    dispatch: any) => {
    return {
        setPage: (path: string) => dispatch(HeaderFooterStore.actionCreators.setPage(path)),
        selectStore: (store: SimpleStore, channelIdentifier: string) => dispatch(LocationStore.actionCreators.selectStore(store, channelIdentifier)),
        requestStore: (storeReference: string, callback: any, errorCallback:any) => dispatch(LocationStore.actionCreators.requestStore(storeReference, callback,errorCallback)),
        clearStores: () => dispatch(LocationStore.actionCreators.clearStores()),
        clearSelectedStore: () => dispatch(LocationStore.actionCreators.clearSelectedStore()),
        setHasStartedApp: (callback: () => void) => dispatch(HeaderFooterStore.actionCreators.setHasStartedApp(callback)),
    };
};

export default connect(
    (state: ApplicationState) => state.location, // Selects which state properties are merged into the component's props
    mapDispatchToProps // Selects which action creators are merged into the component's props
)(QRLanding as any); // eslint-disable-line @typescript-eslint/no-explicit-any