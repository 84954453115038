import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../store';
import * as LocationStore from '../store/LocationStore';

const useMopHooks = () => {

    let history = useHistory();
    const location = useLocation();
    const locationState = useSelector((state: ApplicationState) => state.location);
    const boundSelectTimeslot = bindActionCreators(LocationStore.actionCreators.selectTimeslot, useDispatch());
    const boundSetChannel = bindActionCreators(LocationStore.actionCreators.setChannel, useDispatch());

    const onChannelSelected = (channel: LocationStore.SimpleChannel) => {
        boundSetChannel(channel);
        switch(channel.channelType){
            case LocationStore.simpleChannelType.ClickAndCollect:
                if(locationState?.selectedStore){   //undefined check
                    if(locationState.selectedStore.enableTimeSlots) { //timeslots enabled
                        history.push('/choosetimeslot'); 
                    } 
                    else {    
                        setAsapTimeSlot();
                        history.push(`/categories`); 
                    } 
                }          
                break;
            case LocationStore.simpleChannelType.TableOrder:
                if (localStorage.getItem('isLoggedIn') === 'true') {
                    if (locationState?.selectedStore) {
                        if (locationState.selectedStore.enableTimeSlots) {
                            history.push('/choosetimeslot');
                            break;
                        }
                    }
                }

                setAsapTimeSlot();
                history.push("/categories");
                break;
        }
    }

    const setAsapTimeSlot = () => {
        if(locationState?.selectedStore){            
            let serverTime = locationState.selectedStore.serverTime;
            let remainder = (moment(serverTime).minute() % 15);
            let startTime =  moment(serverTime).subtract(remainder, "m");
            //console.log(moment(serverTime).minute() % 15);
            boundSelectTimeslot({
                label: "ASAP", 
                startDateTime: moment(startTime).format(),
                endDateTime: moment(startTime).add(15, 'm').format(),
                startTime: moment(startTime).format("HH:mm"),
                endTime: moment(startTime).add(15, 'm').format("HH:mm"),
                takingOrders: true
            })
        }
    }

    return {
        onChannelSelected: onChannelSelected
    }

}

export default useMopHooks;
