import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { IonContent, IonRadioGroup, IonList, IonItem, IonLabel, IonRadio, IonIcon, IonAlert, IonGrid, IonRow , IonCol} from '@ionic/react';
import { ellipseSharp, stopwatchSharp, time } from 'ionicons/icons';
import './TimeSlotChooser.scss';
import { bindActionCreators } from 'redux';
import * as locationStore from '../store/LocationStore';
import * as basketStore from '../store/BasketStore';
import * as menuStore from '../store/MenuStore';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../store';

import './TimeSlotChooser.scss';
import { baseUrl } from '../helpers/configHelper';
import { useHistory } from 'react-router';
import { loginHasExpired } from '../helpers/apiHelpers';
import { resetMessage } from '../store/ColleagueStore';

const getTimeSlotIntervals = (from: string, to: string, interval: number) => {
    //moment('24/12/2019 09:15:00', "DD MM YYYY hh:mm:ss");
    let mFrom = moment(from, 'hh:mm');
    let mTo = moment(to, 'hh:mm');
    let times: TimeSlot[] = [];
    for(let i = mFrom; i < mTo; i = moment(i).add(interval, 'm')){
        //|| (i <= moment() && i >= moment().subtract(3, 'h')) //Do not show time slots that have already passed
        if((i >= moment() && i <= moment().add(3, 'h'))){
            let suffix = 'pm';
            if(i < moment('12:00', 'hh:mm'))
                suffix = 'am';
            let slotValue = moment(i).add(interval, 'm').format('HH:mm');
            let slot = `${i.format('HH:mm')} - ${slotValue} ${suffix}`;

            let takingOrders = true;
            times.push({
                label: slot,
                startTime: i.format('HH:mm'),
                startDateTime: "",
                endDateTime: "",
                endTime: slotValue,
                takingOrders: Math.random() < 0.5
            });
        }
    }
    return times;
}

const getTestTimeslots = () : TimeSlot[] => {
    return [
        {
            label: "ASAP", 
            startTime: "12:00",
            startDateTime: "",
            endDateTime: "",
            endTime: "12:15",
            takingOrders: true
        }
    ]
}

const TimeSlotChooser: React.FC<{ className?: string }> = ({ className }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [isChanging, setIsChanging] = useState(false);
    const [timeSlots, setTimeSlots] = useState<TimeSlot[]>();
    const [timeRange, setTimeRange] = useState("");
    const boundSelectTimeslot = bindActionCreators(locationStore.actionCreators.selectTimeslot, useDispatch());
    //const boundClearMenuStore = bindActionCreators(menuStore.actionCreators.clearMenuStore, useDispatch());
    const clearBasket = bindActionCreators(basketStore.actionCreators.clearBasket, useDispatch());
    const locationState = useSelector((state: ApplicationState) => state.location);
    const basketState = useSelector((state: ApplicationState) => state.basket);
    const pfIdentifier = localStorage.getItem('pfIdentifier');

    const formRef = useRef<HTMLFormElement>(null);
    const [showAlert, setShowAlert] = useState(false);

    const [asapTimeSlot, setAsapTimeSlot] = useState<TimeSlot>({ label: "ASAP", startTime: "ASAP", endTime: "ASAP", startDateTime: "ASAP", endDateTime: "ASAP", takingOrders: false });
    const [pickATimeObj, setPickATimeObj] = useState<TimeSlot>({ label: "timeSlot", startTime: "timeSlot", endTime: "timeSlot", startDateTime: "timeSlot", endDateTime: "timeSlot", takingOrders: true });
    const [prevTimeSlot, setPrevTimeSlot] = useState<TimeSlot>(asapTimeSlot);

    const handleHeaderRadioChange = (value: TimeSlot) => {        
        console.log("handleHeaderRadioChange: " + (pickATimeObj === value));            
        if(timeSlots){    
            let timeslot:TimeSlot;
            if(pickATimeObj === value) {
                let firstTimeslot:TimeSlot = timeSlots.find(timeslot => { return timeslot.takingOrders === true}) || asapTimeSlot;
                timeslot = firstTimeslot;
            }
            else {
                timeslot = locationState?.selectedTimeSlot || asapTimeSlot;
                if(value.label !== "ASAP"){   //If this event is not trying to switch to ASAP 
                    if(locationState?.selectedTimeSlot.label !== "ASAP"){    //Check if it's coming from ASAP choice or time slots
                        timeslot = timeSlots.find(timeslot => { return timeslot.takingOrders === true}) || asapTimeSlot;
                    }
                }
                else
                {
                    timeslot = value;
                    formRef.current?.reset();   //This will clear front-end view of whatever timeslot is selected.
                }
            }
            boundSelectTimeslot(timeslot); 
        }
    }

    const handleTimeslotChange = (value: TimeSlot, isHeader: boolean) => {
        //boundClearMenuStore();
        //console.log("Changing slot", value, isHeader, isChanging);
        if(locationState?.selectedTimeSlot !== undefined){
            setPrevTimeSlot(locationState.selectedTimeSlot);
        }
        if(basketState?.basket && basketState.basket.menuItems.length > 0 && value.startTime != locationState?.selectedTimeSlot.startTime && value.startTime != pickATimeObj.startTime){
            //console.log(e, locationState?.selectedTimeSlot);
            //console.log(value, locationState?.selectedTimeSlot, pickATimeObj);
            setShowAlert(true);
        }
        if(!isChanging && isHeader){
            handleHeaderRadioChange(value);
        } 
        else{
            setIsChanging(true); 
            boundSelectTimeslot(value, () => setIsChanging(false) )
        }
    }

    useEffect(() => {
        //console.log("Running");
        fetch(baseUrl() + `/stores/GetStoreTimeSlots${pfIdentifier ? "?pfIdentifier=" + pfIdentifier + "&" : "?"}storeNumber=` + locationState?.selectedStore?.storeNumber)
            .then((res) => res.json())
            .then((data: any) => {
                if (loginHasExpired(data)) {
                    dispatch(resetMessage("showExpireModal"))
                } else {
                    let test = false;
                    let res: TimeSlot[] = test ? getTestTimeslots() : data.mopTimeSlots;

                    //console.log(res);
                    if(res.length > 0){
                        let _asapTimeSlot: TimeSlot = {...asapTimeSlot, takingOrders: res[0].takingOrders, startTime: res[0].startTime, endTime: res[0].endTime, startDateTime: res[0].startDateTime, endDateTime: res[0].endDateTime};
                        res.splice(0, 1);
                        if(res.length > 0){ //More timeslots are available other than ASAP
                            setPickATimeObj({ ...pickATimeObj, takingOrders: true});
                            setTimeSlots(res);
                        }

                        let firstAvailableTimeslot = [_asapTimeSlot, ...res].find(timeslot => { return timeslot.takingOrders == true});
                        setAsapTimeSlot(_asapTimeSlot);
                        setTimeRange(data.timeRange);

                        let timeslotFromState = undefined;
                        if(locationState && locationState?.selectedTimeSlot.startTime != "ASAP"){
                            timeslotFromState = [_asapTimeSlot, ...res].find((ts) => ts.startTime == locationState.selectedTimeSlot.startTime) as TimeSlot; // Need to find value from array that is mapped out to create timeslots instead of just assigning the current timeslot even if they are the exact same.
                            //console.log("Current slot", locationState.selectedTimeSlot, timeslotFromState);
                        }

                        //When coming to the timeslot page, we check if the customer has already selected a timeslot and if that timeslot is still available, else we just select the first available one.
                        if(timeslotFromState != undefined && timeslotFromState.takingOrders == true){
                            handleTimeslotChange(timeslotFromState, false);
                        }
                        else if(firstAvailableTimeslot != undefined){
                            boundSelectTimeslot(firstAvailableTimeslot);
                        }
                        else{   //If for some reason the user was able to access this page when there are 0 available timeslots, go back
                            history.goBack();
                        }

                    }else{
                        setAsapTimeSlot({...asapTimeSlot, startTime: moment().format("HH:mm"), endTime: moment().add(15, "m").format("HH:mm")});
                        //history.goBack();
                    }
                }
            });
        //let res = getTimeSlotIntervals('08:00', '20:00', 15);

    }, [pfIdentifier, locationState?.selectedStore]);

    return (
        <>
        {locationState?.selectedStore != undefined &&
            <>
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => { setShowAlert(false); }}
                    header={"Change order time"}
                    buttons={[
                        {
                            text: "No",
                            role: "cancel",
                            handler: () => {
                                boundSelectTimeslot(prevTimeSlot);
                                //history.push("/categories"); // - Instead, store old choice and switch it back upon changing.
                            }
                        },
                        {
                            text: "Yes, clear basket",
                            handler: () => {
                                clearBasket();
                            }
                        }
                    ]}
                    message={"Changing your order time will clear your basket. Are you sure you want to do this?"}
                />
                <IonGrid className={`time-slot-chooser-container ${className}`}>
                    <IonRow>
                        <IonCol size="12" className="ion-no-padding">
                            <IonRadioGroup                           
                                value={locationState?.selectedTimeSlot.label == "ASAP" ? asapTimeSlot : pickATimeObj} onIonChange={(e) => { handleTimeslotChange(e.detail.value, true) }}>
                                <IonItem lines="none">
                                    {!asapTimeSlot.takingOrders ? <IonLabel>Immediate collection is not available</IonLabel> :
                                        <IonLabel>As soon as possible</IonLabel>}
                                    <IonRadio slot="start" value={asapTimeSlot} disabled={!asapTimeSlot.takingOrders}/>
                                </IonItem>
                                <IonItem lines="none">
                                    <IonLabel>Choose a time <span className="minutes">{timeRange}</span></IonLabel>
                                    <IonRadio slot="start" value={pickATimeObj} disabled={!pickATimeObj.takingOrders} />
                                </IonItem>
                            </IonRadioGroup>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="10" offset="2" className="ion-no-padding">
                            <form ref={formRef}>
                            {
                                (timeSlots != undefined && timeSlots.length > 0) &&
                                    <IonRadioGroup value={locationState?.selectedTimeSlot} onIonChange={(e) => { handleTimeslotChange(e.detail.value, false) }}>
                                        {
                                            timeSlots.map((timeslot, i) => {
                                                return  <IonItem key={"timeslot_" + i} lines="none">
                                                            <IonRadio slot="start" value={timeslot} disabled={!timeslot.takingOrders}/>
                                                            <IonLabel>{timeslot.label}</IonLabel>
                                                        </IonItem>
                                            })
                                        }
                                    </IonRadioGroup>        
                            }
                            </form>
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </>
        }
        </>
    )
}

export default TimeSlotChooser;

/*

                <IonList>
                    <IonRadioGroup value={locationState?.selectedTimeSlot.label == "ASAP" ? asapTimeSlot : pickATimeObj} onIonChange={(e) => { handleTimeslotChange(e.detail.value, true) }}>
                        <IonItem className="TimeSlotChoice" lines="none">
                            <IonIcon slot="start" color="primary" icon={ellipseSharp}/>
                            {!asapTimeSlot.takingOrders ? <IonLabel className="TimeSlotChoiceLabel">Immediate collection is not available</IonLabel> :
                                <IonLabel className="TimeSlotChoiceLabel">As soon as possible <br /><div className="minutes">(within 10-15 minutes)</div></IonLabel>}
                            <IonRadio slot="end" value={asapTimeSlot} disabled={asapTimeSlot.takingOrders == false}/>
                        </IonItem>
                        <IonItem className="TimeSlotChoice" lines="none">
                            <IonIcon slot="start" color="primary"  icon={stopwatchSharp}/>
                            <IonLabel className="TimeSlotChoiceLabel">Pick a time <br /><div className="minutes">{timeRange}</div></IonLabel>
                            <IonRadio slot="end" value={pickATimeObj} disabled={pickATimeObj.takingOrders == false} />
                        </IonItem>
                    </IonRadioGroup>
                    <form ref={formRef}>
                    {
                        (timeSlots != undefined && timeSlots.length > 0) &&
                            <IonRadioGroup className={`PickTime ${locationState.selectedTimeSlot.label == "ASAP" ? "" : "PickTimeSelected"}`} value={locationState?.selectedTimeSlot} onIonChange={(e) => { handleTimeslotChange(e.detail.value, false) }}>
                                {
                                    timeSlots.map((timeslot, i) => {
                                        return  <IonItem key={"timeslot_" + i} className="TimeSlotItem" lines="none">
                                                    <IonRadio slot="start" className="TimeSlotRadio"  value={timeslot} disabled={timeslot.takingOrders == false}/>
                                                    <IonLabel className="TimeSlotRadioLabel">{timeslot.label}</IonLabel>
                                                </IonItem>
                                    })
                                }
                            </IonRadioGroup>        
                    }
                    </form>
                </IonList>

*/
