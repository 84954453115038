import { IonContent, IonGrid, IonItem, IonPage, IonRow,IonCol } from '@ionic/react';
import React from 'react';
import TimeSlotChooser from '../components/TimeSlotChooser';
import './TimeSlotPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import { simpleChannelType } from '../store/LocationStore';

const TimeSlotPage: React.FC = () => {

    const LocationState = useSelector((state: ApplicationState) => state.location);

    return (
        <IonPage >
            <IonContent>
                <IonGrid className="TimeSlotPage">
                    <IonRow>
                        <IonCol size="12" className="Timeslot_storename">{LocationState?.selectedStore?.storeName}</IonCol>
                    </IonRow>
                    <IonRow>
                        {LocationState?.selectedChannel?.channelType === simpleChannelType.TableOrder ? (
                            <IonCol size="12" className="Timeslot_caption">
                                When would you like to order for?
                            </IonCol>
                        ) : (
                            <IonCol size="12" className="Timeslot_caption">
                                When would you like to collect?
                            </IonCol>
                        )}
                    </IonRow>
                </IonGrid>
                <TimeSlotChooser className="time-slot-chooser"/>
            </IonContent>
        </IonPage>
    )
};

export default TimeSlotPage;
