import React, { useState, useEffect } from 'react';
import "./ApplePay.scss"
import { baseUrl, getConfig } from '../helpers/configHelper';
import { add } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';

declare global {
    interface Window {
        // @ts-ignore
        ApplePaySession: ApplePaySession;
    }
}

interface IProps {
    Debug?: boolean,
    TotalPrice: number,
    SetShowButton: React.Dispatch<React.SetStateAction<boolean>>,
    ApplePayButtonClickHandler: (paymentToken: ApplePayJS.ApplePayPaymentToken, session: ApplePaySession) => void,
    ApplePayButton?: HTMLDivElement,
    ShouldPaymentStart: (callback: (res: boolean) => void) => void
}

interface MerchantDetails {
    MerchantIdentifier: string;
    StoreName: string;
}

const ApplePay: React.FC<IProps> = (props) => {
    
    const [status, setStatus] = useState<string[]>([]);
    const [merchantDetails, setMerchantDetails] = useState<MerchantDetails>({ MerchantIdentifier: "", StoreName: ""});
    const basketStore = useSelector((state: ApplicationState) => state.basket);
    
    var request: ApplePayJS.ApplePayPaymentRequest = {
        countryCode: "GB", 
        currencyCode: "GBP",
        supportedNetworks: ["visa", "masterCard", "amex", "discover"],
        merchantCapabilities: ["supports3DS"],
        total: {
            type: "final",
            label: getConfig().isMarket ? "Market Kitchen Order" : "Morrisons Cafe Order",
            amount: basketStore?.basket.totalPrice.toString() || "1.23"
        }
    }

    const beginPayment = () => {
        
        var session = new ApplePaySession(3, request);
        //console.log("Created a new apple pay session", request);

        addToStatus("Created a new ApplePaySession");
        
        session.onvalidatemerchant = (e: ApplePayJS.ApplePayValidateMerchantEvent) => {
            addToStatus("Merchant has been validated");
            //console.log(e);
            //var validationUrl = `https://${e.validationURL}/paymentSession`;
            fetch(baseUrl() + "/api/checkout/RequestApplePayPaymentSession", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    EndpointURL: e.validationURL
                })
            })
            .then(res => res.json() as Promise<any>)
            .then(res => {
                addToStatus("Successfully requested an applepaypayment session from the server");
                //console.log(res);
                session.completeMerchantValidation(res);                

                /*
                    Is the Domain verified properly?
                        https://stackoverflow.com/questions/55072781/apple-pay-completemerchantvalidation-cancels-session
            

                */
            })
            .catch(err => {
                addToStatus("Could not request an applepaysession");
                //console.log(err);
                
            }) 
        }                    

        session.onpaymentmethodselected = (paymentMethodSelected) => {
            //console.log("Payment method has been selected, now completing it", paymentMethodSelected);
            addToStatus("Payment method selected: "+ paymentMethodSelected.paymentMethod.displayName);
            session.completePaymentMethodSelection({
                newTotal: {
                    type: "final",
                    label: getConfig().isMarket ? "Market Kitchen Order" : "Morrisons Cafe Order",
                    amount: basketStore?.basket.totalPrice.toString() || "1.23"
                }
            });

        }


        session.onshippingcontactselected = (shippingContactSelected) => {
            //console.log("Shipping contact selected, now completing ", shippingContactSelected);
            addToStatus("Shipping contact selected");
            session.completeShippingContactSelection({
                newTotal: {
                    type: "final",
                    label: getConfig().isMarket ? "Market Kitchen Order" : "Morrisons Cafe Order",
                    amount: basketStore?.basket.totalPrice.toString() || "1.23"
                }
            });
        }

        session.onshippingmethodselected = (shippingSelected) => {
            //console.log("Shipping method contact selected", shippingSelected);
            addToStatus("Shipping method selected");
            session.completeShippingMethodSelection({
                newTotal: {
                    type: "final",
                    label: getConfig().isMarket ? "Market Kitchen Order" : "Morrisons Cafe Order",
                    amount: basketStore?.basket.totalPrice.toString() || "1.23"
                }
            })
        }

        session.onpaymentauthorized = (paymentAuthorizedEvent) => {
            addToStatus("Payment has been authorized, now sending to Cybersource\n" + JSON.stringify(paymentAuthorizedEvent));
            //console.log("Payment has been authorized", paymentAuthorizedEvent);
            var paymentToken = paymentAuthorizedEvent.payment.token;
            var paymentData = paymentToken.paymentData;
            //console.log(paymentToken.paymentMethod); // displayName, network, type = debit, credit, prepaid, store
            //console.log(paymentData);   // JSON that's sent to Cybersource, paymentData.data, https://developer.apple.com/library/archive/documentation/PassKit/Reference/PaymentTokenJSON/PaymentTokenJSON.html#//apple_ref/doc/uid/TP40014929-CH8
            props.ApplePayButtonClickHandler(paymentToken, session);
        }

        session.oncancel = (cancelEvent) => {
            addToStatus(`Payment has been cancelled.`);
        }

        session.begin();
    }

    const showApplePayButton = () => {
        addToStatus("We can make payments using the active card on this device, showing apple pay button");
        if(props.ApplePayButton != undefined){
            addToStatus("ApplePayButton is defined");
            props.SetShowButton(true); // Display Apple Pay button here.
            //console.log("Showing apple pay button with price of ", totalPrice);                   
        }               
        else{
            addToStatus("Cannot find ApplePayButton");
        }
    }

    const checkForApplePay = () => {
        if(window.ApplePaySession && merchantDetails){
            addToStatus("Apple Pay Session found, now checking if we can make payments with active card");
            var merchantIdentifier = merchantDetails.MerchantIdentifier;
            var promise = ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);
            promise.then((canMakePayments) => {

                if (canMakePayments && props.TotalPrice > 0){
                    addToStatus("We can make a payment with the active card in Apple Pay wallet.");
                    showApplePayButton();
                }    
                else if (ApplePaySession.canMakePayments() && props.TotalPrice > 0){
                    addToStatus("canMakePaymentsWithActive card returned false. canMakePayments returned true but card is not legible for web payments")
                    showApplePayButton();
                }                
                else{
                    addToStatus("We cannot make any payments on this device, both canMakePaymentsWithActiveCard and canMakePayments returned false.");
                }
                
            }).catch((reason) => {
                addToStatus(`Error in canMakePaymentsWithActiveCard(). ${JSON.stringify(reason)}`);
            });
        } else {
            addToStatus(`Unable to check for apple pay, window.ApplePaySession undefined? ${window.ApplePaySession == undefined}, merchantDetails undefined? ${merchantDetails == undefined}`);
        }
    }

    useEffect(() => {
        const onClickApplePayButton = () =>{
            //console.log("Clicked Apple Pay button with total price of", props.TotalPrice);
            props.ShouldPaymentStart((res) => {
                if(res){
                    beginPayment();        
                }
            })  
        }

        if(props.ApplePayButton){
            props.ApplePayButton.addEventListener('click', onClickApplePayButton);        

            return () => {
                props.ApplePayButton?.removeEventListener("click", onClickApplePayButton);
            }
        }        
    }, [props.TotalPrice, props.ApplePayButton]);

    useEffect(() => {
        if(merchantDetails){
            console.log("Updated merchant details", merchantDetails);
            checkForApplePay();
        }        
    }, [merchantDetails])

    useEffect(() => {
        //console.log("Total Price in Apple Pay now: from props", props.TotalPrice, "from useState", totalPrice);
        addToStatus(`Getting StoreName and MID. Total Price: ${props.TotalPrice}`);
        fetch(baseUrl() + "/api/checkout/GetMerchantDetails")
            .then((res => res.json()))
            .then((res: MerchantDetails) => {
                setMerchantDetails({
                    StoreName: res.StoreName,
                    MerchantIdentifier: res.MerchantIdentifier
                });
                
            })  
            .catch((err) => {
                addToStatus("Unable to find Merchant details...");
                console.log(err);
            })
        addToStatus("Checking if this device has ApplePaySession...");
    }, [])   

    const addToStatus = (s: string) => {
        console.log(s);
        if(props.Debug)
        {
            //setStatus(status => [...status, s]); //use this for debugging on mobile.
        }        
    }

    return(
        <div style={{zIndex: 200}}>
            {status.map((s, i) => {
                return  <div key={i}>
                            <span>APPLE:</span> {s}
                        </div>
            })}
        </div>
    )
}

export default ApplePay;