import { AppThunkAction } from ".";
import { Dispatch } from "react";
import { baseUrl } from "../helpers/configHelper";
import { Action, Reducer } from "redux";

export const VALIDATE_PIN_REQUEST = 'VALIDATE_PIN_REQUEST';
export const VALIDATE_PIN_SUCCESS = 'VALIDATE_PIN_SUCCESS';
export const VALIDATE_PIN_FAILURE = 'VALIDATE_PIN_FAILURE';
export const LOGGED_IN = 'LOGGED_IN';
export const RESET_MESSAGE = 'RESET_MESSAGE';

export interface ValidatePinRequestAction {
    type: typeof VALIDATE_PIN_REQUEST;
    payload: string;
}

export interface ValidatePinSuccessAction {
    type: typeof VALIDATE_PIN_SUCCESS;
    payload: { pfIdentifier: string; validatedOk: boolean };
}

export interface ValidatePinFailureAction {
    type: typeof VALIDATE_PIN_FAILURE;
    payload: string;
}

export interface LoggedInAction {
    type: typeof LOGGED_IN;
    isLoggedIn: boolean;
}

export interface ResetMessageAction {
    type: typeof RESET_MESSAGE;
    payload: string | null;
}

export const validatePinRequest = (
    pin: string
): ValidatePinRequestAction => ({
    type: VALIDATE_PIN_REQUEST,
    payload: pin,
});

export const validatePinSuccess = (
    data: { pfIdentifier: string; validatedOk: boolean }
): ValidatePinSuccessAction => ({
    type: VALIDATE_PIN_SUCCESS,
    payload: data,
});

export const validatePinFailure = (
    error: string
): ValidatePinFailureAction => ({
    type: VALIDATE_PIN_FAILURE,
    payload: error,
});

export const loggedIn = (
    isLoggedIn: boolean
): LoggedInAction => {
    localStorage.setItem('isLoggedIn', String(isLoggedIn));

    return {
        type: LOGGED_IN,
        isLoggedIn: isLoggedIn,
    };
};

export const resetMessage = (
    message: string
): ResetMessageAction => ({
    type: RESET_MESSAGE,
    payload: message,
});

export interface PinState {
    loading: boolean;
    error: string | null;
    message: string | null;
    expired: string | null;
    isLoggedIn: boolean;
}

type KnownAction =
    | ValidatePinRequestAction
    | ValidatePinSuccessAction
    | ValidatePinFailureAction
    | LoggedInAction
    | ResetMessageAction;

// What do all of these do?
export const actionCreators = {
    validatePin: (pin: string): AppThunkAction<KnownAction> => {
        return (dispatch: Dispatch<any>) => {
            const currentPin = pin;
            dispatch(validatePinRequest(currentPin));

            fetch(`${baseUrl()}/colleague/validatecolleaguecode?code=${currentPin}`)
                .then((response) => response.json())
                .then((data) => {
                    if (!data.validatedOk) {
                        dispatch(validatePinFailure('Invalid code. Try again'));
                        return;
                    }

                    dispatch(validatePinSuccess(data));
                    localStorage.setItem('pfIdentifier', data?.pfIdentifier);
                    dispatch(loggedIn(true));
                })
                .catch((error) => {
                    dispatch(validatePinFailure(error.message));
                });
        };
    },
    logout: (): AppThunkAction<KnownAction> => {
        return (dispatch: Dispatch<any>, getState) => {
            const appState = getState();

            fetch(`${baseUrl()}/colleague/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(appState.colleague?.message),
            })
                .then((response) => response.json())
                .then((_) => {
                    localStorage.removeItem('pfIdentifier');
                    dispatch(loggedIn(false));
                });
        };
    }
};

const unloadedState: PinState = {
    loading: false,
    error: null,
    message: null,
    expired: null,
    isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
};

export const reducer: Reducer<PinState> = (
    state: PinState = JSON.parse(JSON.stringify(unloadedState)),
    incomingAction: Action,
): PinState => {
    const action = incomingAction as KnownAction;

    switch (action.type) {
        case VALIDATE_PIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case VALIDATE_PIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                message: action.payload.pfIdentifier,
            };

        case VALIDATE_PIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case LOGGED_IN:
            return {
                ...state,
                isLoggedIn: action.isLoggedIn,
            };

        case RESET_MESSAGE:
            return {
                ...state,
                expired: action.payload,
            };

        default:
            return state;
    }
}
