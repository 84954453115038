import React, { useState } from 'react';
import { IonRow, IonCol, IonText,  IonImg } from '@ionic/react';
import './CategoryRow.scss';
import { baseUrl } from '../helpers/configHelper';
import { getConfig } from '../helpers/configHelper';


interface CategoryRowProps {
    categoryId: number;
    categoryImage: string;
    categoryName: string;
    onClick?: (categoryId: number) => void;
}

const CategoryRow: React.FC<CategoryRowProps> = ({ categoryId, categoryImage, categoryName, onClick }) => {

    return (
            <IonRow key={"KY" + categoryId} class="categoryRow" onClick={() => onClick && onClick(categoryId)}>
            <IonCol size="2" className="catImageCol" style={{ backgroundImage: getConfig().isMarket ? "url(" + `${baseUrl()}/images/assets/Logo_MK_Default.png` + ")" : "url(" + `${baseUrl()}/images/assets/Logo_Cafe_Default.png` + ")" }}>
                    <img className="catThumb" src={!categoryImage || categoryImage == 'null' ? `${baseUrl()}/images/CategoryShots/null.jpg` : `${baseUrl()}/images/CategoryShots/` + categoryImage + '.jpg'} />
                </IonCol>
                <IonCol size="10" className="catTitleCol">
                    <IonText className="CategoryName">{categoryName}</IonText>
                </IonCol>
            </IonRow>
    );
};

export default CategoryRow;
