import React, { useState, useEffect } from 'react';
import { IonGrid, IonFooter, IonRow, IonModal, IonCol, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon, IonText, IonImg } from '@ionic/react';
import './MopFooterTablePage.scss';

import { ApplicationState } from '../store';
import * as currencyHelpers from '../helpers/currencyHelpers';
import { useHistory, useLocation } from "react-router-dom";
import QuantityInput from './QuantityInput';

import { useSelector, useDispatch } from 'react-redux';
import * as basketStore from '../store/BasketStore';
import * as MenuItemStore from '../store/MenuItemStore';
import { bindActionCreators } from 'redux';
import * as BasketStore from '../store/BasketStore';

import { simpleChannelType } from '../store/LocationStore';



const MopFooterTablePage: React.FC = ({ }) => {

	const locationState = useSelector((state: ApplicationState) => state.location);
	const BasketTableNumber = useSelector((state: ApplicationState) => state.basket?.tableNumber);
    const BasketNickname = useSelector((state: ApplicationState) => state.basket?.nickname);
    const tableFormErrors = useSelector((state: ApplicationState) => state.basket?.tableFormErrors);
    const nicknameFormErrors = useSelector((state: ApplicationState) => state.basket?.nicknameFormErrors);

    const boundSetContinueClicked = bindActionCreators(BasketStore.actionCreators.setContinueClicked, useDispatch());
    const boundSetTableFormError = bindActionCreators(BasketStore.actionCreators.setTableFormErrors, useDispatch());
    const boundSetNicknameFormError = bindActionCreators(BasketStore.actionCreators.setNicknameFormErrors, useDispatch());
	const boundSetTableNumber = bindActionCreators(BasketStore.actionCreators.setTableNumber, useDispatch());
    const boundSetNickname = bindActionCreators(BasketStore.actionCreators.setNickname, useDispatch());
	const history = useHistory();

    useEffect(() => {
        if (localStorage.getItem('isLoggedIn') === "true") {
            if (!BasketNickname) {
                boundSetNicknameFormError("Please enter a valid name or nickname");
            } else {
                boundSetNicknameFormError("");
            }
        } else {
            if (!BasketTableNumber) {
                boundSetTableFormError("Please enter a valid table number");
            } else {
                if (BasketTableNumber == 0) {
                    boundSetTableFormError("Table number must be greater than 0");
                } else {
                    boundSetTableFormError("");
                }
            }
        }
    }, [BasketNickname, BasketTableNumber])

	const submitTableNumber = () => {
        boundSetContinueClicked(true)

        if (!tableFormErrors && !nicknameFormErrors) {
            boundSetTableNumber(BasketTableNumber as number);
            boundSetNickname(BasketNickname as string);
            history.push("/enteremail");
        }
	}

	return (
		<div className="MopFooterInner">
			<IonRow className="ion-align-items-center">
				<IonCol size="6" className="FooterStoreWrapper">
					<span className="CollectFrom">{locationState?.selectedChannel === undefined ? "Store" : locationState.selectedChannel.channelType === simpleChannelType.ClickAndCollect ? "Take away" : "Eat in"}</span><br />
					<span className="Location">{locationState?.selectedStore === undefined ? "Select a store" : locationState?.selectedStore.storeName}</span>
					{locationState?.selectedStore?.takingMopOrders === false ? <span style={{ color: "#ff0" }}> - Order ahead not available</span> : null}
				</IonCol>
				<IonCol size="6">
					<IonButton expand="block" size="small" color="light" onClick={() => { submitTableNumber() }}>
						<span>Continue</span>
					</IonButton>
				</IonCol>
			</IonRow>
		</div>
	);
};

export default MopFooterTablePage;
