import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonGrid, IonRow, IonCol, IonContent, IonHeader, IonFooter, IonRouterLink, IonPage, IonTitle, IonToolbar, IonInput, IonItem, IonLabel, IonList, IonItemDivider, IonThumbnail, IonImg, IonRadioGroup, IonRadio, IonCheckbox } from '@ionic/react';
import AppHeader from '../components/AppHeader';
import { ApplicationState } from '../store';
import * as HeaderFooterStore from '../store/HeaderFooterStore';
import * as MenuDataStore from '../store/MenuStore';
import * as MenuItemDataStore from '../store/MenuItemStore';
import { RouteComponentProps } from 'react-router';
import './MenuItem.scss';
import ProductExtra from '../components/ProductExtra';
import ProductSingleSelect from '../components/ProductSingleSelect';
import ProductMultiSelect from '../components/ProductMultiSelect';
import ModifierGroup from '../components/ModifierGroup';
import AllergenInfoModal from '../modals/AllergenInfoModal';
import { baseUrl } from '../helpers/configHelper';
import { getConfig } from '../helpers/configHelper';
import HeaderDescription from '../components/HeaderDescription';
import AuxJsonModifierFilters from '../components/AuxJsonFilterModifierGroups';

interface MenuItemOwnProps {
    menuItemData: MenuItemDataStore.MenuItemDataState
    isBasket: boolean
    isColleague: boolean
}

interface MenuItemState {
    allergenInfoOpen: boolean;
    dropdowns: DropdownConfig[];
    showModifierGroupItemCalories: boolean;
}

interface DropdownConfig {
    id: string,
    open: boolean;
}

// At runtime, Redux will merge together...
type MenuItemProps =
    typeof MenuItemDataStore.actionCreators // ... plus action creators we've requested
    & typeof HeaderFooterStore.actionCreators
    & RouteComponentProps<{ categoryIdentifier: string }>
    & MenuItemOwnProps;


class MenuItem extends React.Component<MenuItemProps, MenuItemState> {


    constructor(props: any) {
        super(props);
        this.state = { 
            allergenInfoOpen: false, 
            dropdowns: [],
            showModifierGroupItemCalories: false
        };
    }

    componentDidMount() {
        if (this.props.menuItemData.menuItem) {
            this.props.setTitle(this.props.menuItemData.menuItem.menuItemName, true);

            let menuItem = this.props.menuItemData.menuItem;

            /*
                This keeps track of all dropdown menus from both sources (menuItem.modifierGroup and modifierGroup.auxMenuItemData groups)
                    into a standard array to help control which dropdowns are open. 
            */
            let dropdownCofigs: DropdownConfig[] = [];
            for(let i = 0; i < menuItem.modifierGroups.length; i++){
                let group: IMopModifierGroup = menuItem.modifierGroups[i];
                if(group.auxMenuItemData?.auxJsonFilters?.groups){  
                    //This menuItem has auxJson Modifier Groups, so we show the calorie information of the seleceted modifier group item.                  
                    this.setState({ showModifierGroupItemCalories: true });
                    for(let j = 0; j < group.auxMenuItemData.auxJsonFilters.groups.length; j++){
                        dropdownCofigs.push({
                            id: i + "_" + j,
                            open: dropdownCofigs.length == 0 ? true : false
                        });
                    }
                }
                else{
                    dropdownCofigs.push({
                        id: i.toString(),
                        open: dropdownCofigs.length == 0 ? true : false
                    });
                }
            }

            this.setState({ dropdowns: dropdownCofigs});
        }
    }

    componentWillUnmount() {
        //Check if it's basket update, if so then dispatch SHOW_BASKET_MODAL
        if (this.props.isBasket) {
            this.props.setBasketShowModal(true);
        }
    }

    goBack() {
        this.props.history.goBack();
    }

    openAllergenInfo() {
        this.setState({ allergenInfoOpen: true });
    }
    closeAllergenInfo() {
        this.setState({ allergenInfoOpen: false });
    }

    onToggleDropdown(value: boolean, id: string) {

        let prevOpen = this.state.dropdowns.find((d) => d.open);
        this.setState({
            dropdowns: this.state.dropdowns.map((d) => {
                if(d.id == id){
                    return { ...d, open: value}
                }
                else if(prevOpen && d.id == prevOpen.id){
                    return {...d, open: false}
                }
                else{
                    return d;
                }
            })
        })
    }

    onSelectedSingle(id: string) {
        /*
            When selected, call this method with the ID of the group,
            find the index of the Id in the group and then if there is a NEXT group, call onToggleDropdown
        */

        let thisDropdown = this.state.dropdowns.findIndex((d) => d.id == id);

        if(thisDropdown > -1){
            if(thisDropdown + 1 < this.state.dropdowns.length){
                console.log("Changing dropdown...", thisDropdown, this.state.dropdowns);
                this.onToggleDropdown(true, this.state.dropdowns[thisDropdown + 1].id);
            }
            else{
                console.log("End of array", thisDropdown, this.state.dropdowns);
            }
        }
        else{
            console.log("Unable to find dropdown ID", id);
        }
    }

    render() {

        const { menuItemName, menuItemImage, description, modifierGroups, allergenInfo, sku, itemTags, kcal,menuItemBasePrice, pdpRoundels } = this.props.menuItemData.menuItem || {};

        return (

            <IonPage className="menuItemWrapper">
                <IonContent fullscreen>
                    <HeaderDescription 
                        headerImage={`${baseUrl()}/images/ProductShots/${sku}_PDP.jpg`}
                        headerTitle={menuItemName}
                        itemTags={itemTags}
                        allergenInfo={allergenInfo}
                        description={description}
                        minHeight="298px"
                        hideText={false}
                        identifier={"SKU: " + sku}
                        displayType={""}
                        kcal={kcal}
                        price={menuItemBasePrice}
						pdpRoundels={pdpRoundels}
                    />
                    {
                        (() => {
                            /*
                            If this menu item contains modifier groups with AuxJson modifier groups, then we show the calorie information above the modifier groups.                            
                            */

                            if(this.state.showModifierGroupItemCalories && modifierGroups && modifierGroups.length > 0){
                                let targetModifierGroup = modifierGroups.find((m: IMopModifierGroup) => m.auxMenuItemData?.auxJsonFilters?.outcomes && m.auxMenuItemData?.auxJsonFilters?.groups);
                                if(targetModifierGroup){
                                    let selectedModifierGroupItem: IMopModifierGroupItem = targetModifierGroup.modifierGroupItems.find((item: IMopModifierGroupItem) => item.quantitySelected > 0);
                                    if(selectedModifierGroupItem){
                                        return(
                                            <div className='MenuItem__ModifierGroupItemKcal'>
                                                <div className='ModifierGroupItem__Label'>Currently selected:</div>
                                                <div className='ModifierGroupItem__Name'>{selectedModifierGroupItem.menuItemName}</div>                                                
                                                {
                                                    selectedModifierGroupItem.kcal &&
                                                    <div className='ModifierGroupItem__Kcal'>({selectedModifierGroupItem.kcal}kcal)</div>     
                                                }
                                            </div>
                                        )
                                    }   
                                    else{
                                        console.log("No modifier group item selected.")
                                    }
                                }
                                else{
                                    console.log("Can't find auxJsonFilters for any modifier group in this menu item");
                                }
                            }
                        })()                        
                    }

                    {modifierGroups?.map((mg: IMopModifierGroup, index: number) => {
                        if(mg.auxMenuItemData?.auxJsonFilters?.groups && mg.auxMenuItemData.auxJsonFilters.outcomes){
                            return <>
                            {
                                mg.auxMenuItemData.auxJsonFilters.groups.map((group, j) => {
                                    let localId = index + "_" + j;
                                    let dropdownConfig = this.state.dropdowns.find((d) => d.id == localId);
                                    return <AuxJsonModifierFilters key={"auxJson_" + index + "_" + j} localId={localId} onToggleDropdown={this.onToggleDropdown.bind(this)} onSelectedSingle={this.onSelectedSingle.bind(this)} isDropdownOpen={dropdownConfig ? dropdownConfig.open : false} auxFilterGroup={group} modifierIndex={index} auxFilterIndex={j} detailsPageSettings={this.props.menuItemData.menuItem?.auxData?.detailsPageSettings}/>
                                })
                            }
                            </>                            
                        }else{
                            let dropdownConfig = this.state.dropdowns.find((d) => d.id == index.toString());
                            return <ModifierGroup localId={index.toString()} onToggleDropdown={this.onToggleDropdown.bind(this)} onSelectedSingle={this.onSelectedSingle.bind(this)} isDropdownOpen={dropdownConfig ? dropdownConfig.open : false} key={'mg' + index} menuItem={this.props.menuItemData.menuItem} modifierGroup={mg} isColleague={this.props.isColleague}></ModifierGroup>
                        }
                    })}
                </IonContent>
            </IonPage>
        );
    };
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => ({
    menuItemData: state.menuItemData,
    isBasket: state.menuItemData?.isBasket,
    totalPrice: state.basket?.basket.totalPrice,
    isColleague: state.colleague?.isLoggedIn
});

const mapDispatchToProps = (
    dispatch: any) => {
    return {
        requestMenuItem: (menuItemId: number, pfIdentifier: string, referer: string | null, callback: any) => dispatch(MenuItemDataStore.actionCreators.requestMenuItem(menuItemId, pfIdentifier, "", callback)),
        setBasketShowModal: (value: boolean) => dispatch(HeaderFooterStore.actionCreators.setBasketShowModal(value)),
        setTitle: (value: string, showBack: boolean) => dispatch(HeaderFooterStore.actionCreators.setTitle(value, showBack))
    };
};

export default connect(
    mapStateToProps, // Selects which state properties are merged into the component's props
    mapDispatchToProps    // Selects which action creators are merged into the component's props,
)(MenuItem as any);
