import React, { createRef, useState } from 'react';
import { IonPage } from '@ionic/react';
import MopCoupons from '../components/MopCoupons';
import './CouponsTestPage.scss';
import { withRouter, RouteComponentProps } from 'react-router';

// MTR: Delete if you wish with its .scss

interface MopCouponsProps {
}

type CouponsMergeProps =
    RouteComponentProps & MopCouponsProps;

class CouponsTestPage extends React.Component<CouponsMergeProps> {

    constructor(props: CouponsMergeProps) {
        super(props);
    }
    render() {
        return (
            <IonPage className="MopCoupons__container">
                <MopCoupons />
            </IonPage>
        )
    }
}

export default withRouter(CouponsTestPage as any);