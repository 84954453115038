import React, { useEffect, useState } from 'react';
import { refreshOutline } from 'ionicons/icons';
import './CategoryMenuItemLister.scss';
import * as MenuDataStore from '../store/MenuStore';
import { IonGrid, IonContent, IonIcon, IonButton, IonList, IonRow, IonImg } from '@ionic/react';
import CategoryRow from '../components/CategoryRow';
import CategoryGrid from '../components/CategoryGrid';
import MenuItemRow from '../components/MenuItemRow';
import styled from 'styled-components';
import { baseUrl, cacheBust, getConfig } from '../helpers/configHelper';
import AppHeaderImage from './AppHeaderImage';
import HeaderDescription from './HeaderDescription';
import MenuItemTile from './MenuItemTile';
import { formatCurrency } from '../helpers/currencyHelpers';
import CategoryFilters from './CategoryFilters';
import { useHistory, useLocation } from "react-router-dom";
import { CategoryList, MenuDisplayType, MopCategory, MopMenuItem, ButtonLinkTypeEnum } from '../store/MenuStoreTypes';

interface CategoryMenuItemListerProps {
    categoryList: CategoryList | null,
    isLoading: boolean;
    onNavigateToCategory?: (categoryId: number) => void;
    onNavigateToMenuItem?: (menuItemId: number) => void;
    isColleague: boolean | null;
}

const CategoryMenuItemLister: React.FC<CategoryMenuItemListerProps> = ({
    categoryList,
    isLoading,
    onNavigateToCategory,
    onNavigateToMenuItem,
    isColleague,
}) => {

    const [priceTitle, setPriceTitle] = useState<string>();
    const [selectedFilter, setSelectedFilter] = useState<string | null>();
    const location = useLocation()

    useEffect(() => {
        console.log("Category Info:", categoryList);

        if (categoryList?.menuItems && categoryList.menuItems.length > 0) {
            let basePrice = categoryList.menuItems[0].menuItemBasePrice;
            setPriceTitle(categoryList.menuItems.every((m) => m.menuItemBasePrice == basePrice) ? formatCurrency(basePrice) : undefined);
        }

    }, [categoryList]);

    const applyFilter = (incoming: MopMenuItem[]) => {
        return incoming.filter(a => a.categoryLevelFilter == selectedFilter || !selectedFilter);
    }

    const RenderMenuTemplate = () => {

        if (categoryList) {
            let displayType = categoryList.auxCategoryData?.displayType;
            console.log("Display type", displayType);
            console.log("Store value", MenuDisplayType.BasicList);

            if (displayType === undefined || displayType === MenuDisplayType.BasicList) {
                return <>
                    {
                        categoryList.categories.map((a: MopCategory) => (
                            <CategoryRow key={"ee" + a.categoryId} categoryId={a.categoryId} categoryImage={a.categoryImage} categoryName={a.categoryName} onClick={() => onClickCategory(a)} />
                        ))
                    }
                    {
                        categoryList.menuItems.map((a: MopMenuItem) => (
                            <MenuItemRow menuItemFromPrice={a.menuItemFromPrice} key={"ef" + a.menuItemId} menuItemId={a.menuItemId} menuItemImage={a.menuItemImage} inStock={a.inStock} menuItemBasePrice={a.menuItemBasePrice} menuItemName={a.menuItemName} onClick={() => onNavigateToMenuItem && onNavigateToMenuItem(a.menuItemId)} sku={a.sku} itemTags={a.itemTags} />
                        ))
                    }
                </>
            }
            else if (displayType === MenuDisplayType.BasicGrid) {
                return <div className="BasicGrid__wrapper">
                    <IonRow className="categoryGrid">
                        {/*category needs to be in the same ion row so that if the category list contains an odd amount of items there isn't a large gap between the categories and items*/}
                        {
                            categoryList.categories.map((a: MopCategory) => (
                                <CategoryGrid key={"ee" + a.categoryId} categoryId={a.categoryId} categoryImage={a.categoryImage} categoryName={a.categoryName} fullWidth={a.auxData != null ? a.auxData.fullWidth : false} onClick={() => onClickCategory(a)} />
                            ))
                        }
                        {
                            applyFilter(categoryList.menuItems).map((a: MopMenuItem) => (
                                <MenuItemTile fullWidth={categoryList.auxCategoryData?.fullWidthSkus && categoryList.auxCategoryData.fullWidthSkus.includes(a.sku)} kcal={a.kcal} name={a.menuItemName} priceLocation={categoryList.auxCategoryData?.priceLocation} key={"ef" + a.menuItemId} menuItemId={a.menuItemId} inStock={a.inStock} fromPrice={a.menuItemFromPrice != null} price={(categoryList.auxCategoryData?.priceLocation?.hiddenPriceSkus && categoryList.auxCategoryData.priceLocation.hiddenPriceSkus.includes(a.sku)) ? undefined : a.menuItemFromPrice ? formatCurrency(a.menuItemFromPrice) : formatCurrency(a.menuItemBasePrice)} onClick={() => onNavigateToMenuItem && onNavigateToMenuItem(a.menuItemId)} sku={a.sku} itemTags={a.itemTags} plpRoundels={a.plpRoundels} />
                            ))
                        }
                    </IonRow>
                </div>
            }
            else if (displayType === MenuDisplayType.ProductGrid) {
                return <div>Not Available</div>
            }
            else {
                return <div>Unknown Menu Display Type</div>
            }
        }
        else {
            return <div>categoryList null</div>
        }
    }

    const onClickCategory = (category: MopCategory) => {
        if (location.pathname !== "/categories" && category.menuItemIds && category.menuItemIds.length === 1 && (!category.subCategoryIds || category.subCategoryIds.length === 0)) {
            if (onNavigateToMenuItem) {
                onNavigateToMenuItem(category.menuItemIds[0]);
            }
            else {
                console.log("ERROR: onNavigateToMenuItem undefined");
            }
        }
        else {
            if (onNavigateToCategory) {
                onNavigateToCategory(category.categoryId);
            }
            else {
                console.log("ERROR: onNavigateToCategory undefined");
            }
        }
    }

    const onFilterSelected = (newSelectedFilter: string) => {

        if (newSelectedFilter == selectedFilter) {
            setSelectedFilter(null);
            return;
        }
        setSelectedFilter(newSelectedFilter);
        return;
    }

    return (
        <IonContent fullscreen class='CategoryListContent'>
            {categoryList && categoryList.categories.length > 1 && categoryList?.auxCategoryData?.categoryImageName == null && //categoryList?.categoryTitle != undefined // <-- for market kitchen headers
                <AppHeaderImage headerTitle={categoryList.categoryTitle} />
            }

            {
                categoryList?.isFavourites &&
                <div className={`isFavouritesOnMenuPage`}>
                    <div className="MenuItem-Tile__image_container">
                        {/* <img src={(getConfig().isMarket ? fullWidth ? `${baseUrl()}/images/CategoryShots/null_fw.jpg` : `${baseUrl()}/images/CategoryShots/null.jpg` : `${baseUrl()}/images/assets/Logo_Cafe_Default_PDP.jpg`)} />*/}
                        <img className="MenuItemImage" alt={`category_image_favourites`} src={`${baseUrl()}/images/CategoryShots/favourites_fw.jpg?v=${cacheBust()}`} />
                    </div>
                    <div className="MenuItemCaptionContainer">
                        <div className="MenuItemCaption">
                            More Card
                            <span>Quick access to Market Kitchen, favourites you've ordered before</span>
                        </div>
                    </div>
                </div>
            }

            <IonGrid style={{ "background": categoryList?.auxCategoryData?.categoryBackgroundColour ?? "unset" }} className="padding-none">
                {categoryList && getConfig().isMarket &&
                    <>
                        <HeaderDescription
                            headerImage={categoryList.auxCategoryData?.categoryImageName ? `${baseUrl()}/images/CategoryHeaders/${categoryList.auxCategoryData.categoryImageName}` : null}
                            headerTitle={categoryList.categoryTitle}
                            minHeight=""
                            fontColour={categoryList.auxCategoryData?.categoryFontColour}
                            identifier={"CID: " + String(categoryList.categoryId)}
                            displayType={"DT: " + String(categoryList.auxCategoryData?.displayType)}
                            //hideText={categoryList.auxCategoryData?.hideCategoryHeader ?? false}
                            hideText={true}
                            description={categoryList.auxCategoryData?.description}
                            altTitle={priceTitle}
                        />
                        {
                            categoryList?.auxCategoryData?.promotionalButtons &&
                            <div className="CategoryPromo__container">
                                <IonRow>
                                    {
                                        categoryList.auxCategoryData.promotionalButtons.map((promo) => {
                                            return <img src={(baseUrl() + "/images/CategoryPromos/" + promo.buttonImage) + `?v=${cacheBust()}`}
                                                onClick={() => {
                                                    promo.buttonLinkType == ButtonLinkTypeEnum.Category ? onNavigateToCategory && onNavigateToCategory(Number.parseInt(promo.buttonLinkId)) : onNavigateToMenuItem && onNavigateToMenuItem(Number.parseInt(promo.buttonLinkId))
                                                }} />
                                        })
                                    }
                                </IonRow>
                            </div>
                        }
                    </>
                }
                {categoryList && categoryList.categories && (!categoryList.menuItems || !getConfig().isMarket) &&
                    <>
                        {
                            categoryList.menuDisplayType === 1 &&
                            <>
                                <IonRow className="categoryGrid">
                                    {
                                        categoryList.categories.map((a: MopCategory) => (
                                            <CategoryGrid key={"ee" + a.categoryId} categoryId={a.categoryId} categoryImage={a.categoryImage} categoryName={a.categoryName} isFavourites={a.isFavourites} fullWidth={a.isFavourites ? true : a.auxData != null ? a.auxData.fullWidth : false} onClick={() => onClickCategory(a)} />
                                        ))
                                    }
                                </IonRow>
                            </>
                        }
                        {
                            categoryList.menuDisplayType === 0 &&
                            <>
                                {
                                    categoryList.categories.map((a: MopCategory) => (
                                        <CategoryRow key={"ee" + a.categoryId} categoryId={a.categoryId} categoryImage={a.categoryImage} categoryName={a.categoryName} onClick={() => onClickCategory(a)} />
                                    ))
                                }
                            </>
                        }
                    </>
                }

                {categoryList && categoryList.menuItems &&

                    <>
                        {

                        <>
                            <CategoryFilters onFilterSelected={onFilterSelected} selectedFilter={selectedFilter} menuItems={categoryList.menuItems} ></CategoryFilters>

                                <RenderMenuTemplate />
                            </>

                        }
                    </>
                }
                {
                    (categoryList?.auxCategoryData?.categoryFooter?.imageName && categoryList.auxCategoryData.categoryFooter.imageName.length > 0)
                        ?
                        <IonRow className="CategoryFooterImage__row">
                            <IonImg src={(`${baseUrl()}/images/CategoryFooters/${categoryList.auxCategoryData.categoryFooter.imageName}`) + `?v=${cacheBust()}`} />
                        </IonRow>
                        :
                        <></>
                }
            </IonGrid>
        </IonContent>
    );
};

export default CategoryMenuItemLister;
