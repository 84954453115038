import * as Yup from "yup";

export const enterEmailSchema = Yup.object().shape({
    email: Yup.string().email("Email must be valid")
        .test("valid-domain", "Must enter a Morrisons email address", (value) => {
            if (value && localStorage.getItem('isLoggedIn') === "true") {
                const [, domain] = value.split("@");
                return domain === "morrisonsplc.co.uk";
            }
            return true;
        })
        .required("Email is required."),

    staffCardNumber: Yup.string()
        .nullable()
        .test('verify-card-number', "Please enter a valid More Card card number", function(value) {
            const { path } = this;
            const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
            const genericCardErrorMessage = "Please enter a valid More Card card number";

            if (!isLoggedIn) {
                return true; // Skip validation if not logged in.
            }

            if (value === null || value === "") {
                return this.createError({
                    path: path,
                    message: genericCardErrorMessage
                });
            }

            if (typeof value !== 'string' || !/^[0-9]+$/.test(value)) {
                return this.createError({
                    path: path,
                    message: genericCardErrorMessage
                });
            }

            const cardPrefix = "98261";
            if (value.substring(0, cardPrefix.length) !== cardPrefix || value.length !== 19) {
                return this.createError({
                    path: path,
                    message: genericCardErrorMessage
                });
            }

            return true;
        })
});

export const cardCheckoutSchema = Yup.object().shape({
    Name: Yup.string().required("Please enter your name as it appears on the card"),
    Expiry: Yup.string().required("Please enter the card expiry date in MM/YY format")
});

export const tableNumberSchema = Yup.object().shape({
    TableNumber: Yup.number().max(999, "Maximum 3 numbers")
});

export const nicknameSchema = Yup.object().shape({
    Nickname: Yup.string().max(20, "Maximum 20 characters")
});
